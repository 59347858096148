export default {
    BILLING_FREQUENCY: {
        DAILY: "DAILY",
        WEEKLY: "WEEKLY",
        MONTHLY: "MONTHLY",
        YEARLY: "YEARLY"
    },
    PAYMENT_TYPE: {
        ONE_TIME: "ot",
        RECURRING: "recurring"
    },
    LS_DEVICE_ID: 'od_device_id',

    ORDERS_API: '/api/orders',
    CUSTOMERS_API: '/api/customers',
    STAFFS_API: '/api/staffs',
    ROLES_API: '/api/roles',
    VENDORS_API: '/api/vendors',
    VENDORSETTING_API: '/api/vendorSetting',
    BRANDS_API: '/api/brands',
    PRODUCTS_API: '/api/products',
    CATEGORIES_API: '/api/categories',
    INVENTORY_API: '/api/inventory',
    VENDORS_REGISTRATION_API: '/api/vendors/registration',
    ORDER_ALLOWED_ACTIONS: '/api/orders/$id/allowedActions',
    ORDER_ACTION_CANCEL: '/api/orders/cancelOrder',
    IMPORT_FILE: "/api/importFile",
    IS_CANCEL_ALLOWED: "/api/orders/cancelAction/$id",
    GENERATE_INVOICE: "/api/pdf/generateInvoice",
    UPDATE_PRODUCT_CATEGORY: "/api/products/$id/category",
    CATEGORYWISE_PRODUCTS: "/api/products/category",
    COUPONS_API: "/api/coupon",
    STOCK_REPORT: "/api/reports/stockreport",
    DAYWISE_SALE_REPORT: "/api/reports/daywiseSale",
    PRODUCT_SOLD_REPORT: "/api/reports/productSoldReport",
    UPDATE_PAYMENT_DETAILS: "/api/orders/updatePaymentDetails",
    PAYMENT_TRANSACTION_API: "/api/paytransactions",
    SALES_REPORT: "/api/reports/sales-report",
    EXPENSE_MANAGER_API: "/api/expenseManager",
    PLANS_API: "/api/plans",
    BILLING_ACCOUNT_API: "/api/billing",
    EXPENSE_INCOME_REPORT: "/api/reports/income-expense-report",
    PAGE_SIZE: 10,
    REWARD_API: "/api/rewardSystem",
    FIREBASE_MESASGING_VAPID_KEY: "BFE5M3UuuF5FlZbFr25yHa9DUENLQaKkpfFxx48AvFqGs8nZ7RR3wShp4UEsduvUWyd2C3EmAsvWHrHcpb_dAEM",
    NEW_CUSTOMER_REPORT: "/api/reports/new-customer-report",
    NEW_INVOICE_REPORT: "/api/reports/new-invoice-report",
    TOTAL_SALES_REPORT: "/api/reports/total-sales-report",
    VENDOR_ACCOUNTS_REPORT: "/api/reports/vendor-billing-report",
    ALL_VENDOR_BUSINESS_STATS: "/api/reports/all-vendors-business-stats",
    OLD_STORE_UI: 'Webstore 1.0',
    NEW_STORE_UI: 'Webstore 2.0',
    BEST_DEALS: 'Best Deals',
    SUBSCRIPTION_API: "/api/subscription",
    SUPPLIED_ITEM_API: "/api/suppliedItem",
    SUPPLY_WORKFLOW: "/api/workflow/supplyWorkflow",
    SUPPLY_ALLOWED_ACTIONS: '/api/suppliedItem/$id/allowedActions',
    PACKAGES_API: '/api/packages',
    BILLINGS_API: "/api/razorpay",
    MODULES_API: "/api/modules",
    //----------------------------------------------------------------
    //  WEBSTORE ENDPOINTS
    //----------------------------------------------------------------
    WEBSTORE_API: "/api/webstore",
    WEBSTORE_PRODUCTS: "/api/webstore/products",
    WEBSTORE_COUPONS: "/api/webstore/coupons",
    WEBSTORE_CATEGORYWISE_PRODUCTS: '/api/webstore/products/category',
    WEBSTORE_CATEGORIES: "/api/webstore/categories",
    WEBSTORE_PACKAGES: "/api/webstore/packages",
    WEBSTORE_PLANS: "/api/webstore/plans",
    WEBSTORE_NOTIFICATION: "/api/webstore/pushNotification",
    WEBSTORE_VENDOR_API: "/api/webstore/vendors",
    GENERATE_WEBSTORE_INVOICE: "/api/webstore/pdf/generateInvoice",
    WEBSTORE_MYORDERS_API: '/api/webstore/myorders',
    GENERATE_OTP: '/api/webstore/authenticate/generateOTP',
    VERIFY_OTP: '/api/webstore/authenticate/verifyOTP',
    RESET_PASSWORD: '/api/webstore/authenticate/resetPassword',
    WEBSTORE_PRODUCTS_STOCK_CHECK: "/api/webstore/products/stock",
    WORKFLOW_API: "/api/webstore/workflow",
    WEBSTORE_CUSTOMER_ADDRESSES: "/api/webstore/customers/$id/addresses",
    CUSTOMERS_REGISTRATION_API: '/api/webstore/customers/registration',
    AUTHENTICATE_API: '/api/webstore/authenticate',
    WEBSTORE_REGISTER_DEVICE: '/api/webstore/register-device',
    WEBSTORE_IS_DEVICE_REGISTERED: '/api/webstore/check-device',
    WEBSTORE_UPDATE_DEVICE: '/api/webstore/update-device',
}