<template>
<div :style="theme" class="footerPosition">
    <v-container>
        <v-layout wrap>
            <v-flex xs12 sm6>
                <v-flex>
                    <label class="headline" :style="theme">
                        {{$store.getters.vendor.businessName}}
                    </label>
                </v-flex>
                <v-flex v-if="$store.getters.vendor.address!=' '">
                    <v-icon class="footer-icon small" :style="theme">mdi-map-marker</v-icon>
                    <label class="small" :style="theme">{{$store.getters.vendor.address}}</label>
                </v-flex>
                <v-flex v-if="$store.getters.vendor.mobileNo!=' '">
                    <v-icon class="footer-icon small" :style="theme">mdi-cellphone</v-icon>
                    <label class="small" :style="theme">{{$store.getters.vendor.mobileNo}}</label>
                </v-flex>
                <v-flex>
                    <v-icon class="footer-icon small" :style="theme">mdi-email</v-icon>
                    <label class="small" :style="theme">{{$store.getters.vendor.email}}</label>
                </v-flex>
            </v-flex>
            <v-flex xs12 sm6 text-right>
                <v-icon @click="openSocialLink($store.getters.vendor.facebook)" v-if="$store.getters.vendor.facebook" class="social-media-icon" :style="theme">mdi-facebook</v-icon>
                <v-icon @click="openSocialLink($store.getters.vendor.twitter)" v-if="$store.getters.vendor.twitter" class="social-media-icon" :style="theme">mdi-twitter</v-icon>
                <v-icon @click="openSocialLink($store.getters.vendor.instagram)" v-if="$store.getters.vendor.instagram" class="social-media-icon" :style="theme">mdi-instagram</v-icon>
            </v-flex>
        </v-layout>
    </v-container>
    <v-flex class="secondary-footer text-center">
        <v-layout>
            <v-flex text-right class="secondary-footer-font" style="padding-right:0.4rem">Powered by <a class="primary-color" href="http://onlinedukanhub.in" target="_blank">onlinedukanhub.in</a></v-flex>
            |<v-flex text-left class="secondary-footer-font" style="padding-left:0.4rem">
                <label class="small">v {{appVersion}}</label>
            </v-flex>
        </v-layout>
    </v-flex>
</div>
</template>

<script>
export default {
    methods: {
        openSocialLink(link) {
            window.open(link)
        }
    },
}
</script>

<style scoped>
.primary-footer {
    background-color: rgb(46, 45, 45)
}

.secondary-footer {
    font-weight: 300;
    background-color: black;
    padding: 1rem;
}

.footerPosition {
    margin-top: 5rem;
    position: relative;
    bottom: 0;
    color: white;
}

.social-media-icon{
    padding: 0.5rem;
    color: white;
    font-size: 2rem;
}
.small {
    font-size: .85rem;
    font-weight: 300;
}

.footer-icon {
    font-size: .85rem;
    color: white;
    padding-right: 0.5rem
}

@media screen and (max-width:600px) {
    .social-media-icon{
        font-size: 1.5rem;
    }
    .small {
        font-size: .75rem;
    }
    .secondary-footer-font{
        font-size: .90rem !important;
    }
}
</style>
