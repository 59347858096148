<template>
<div>
    <v-list-item>
        <v-list-item-icon>
            <v-img class="align-end" height="64px" width="64px" :src="cart.image ? this.host + cart.image.path : defaultProductImage"></v-img>
        </v-list-item-icon>
        <v-list-item-content>
            <v-layout wrap>
                <v-flex md8>
                    <v-list-item-subtitle v-if="cart.brand" v-text="cart.brand.displayName"></v-list-item-subtitle>
                    <v-list-item-title @click="openDetail=true" class="body-1" v-text="cart.title"></v-list-item-title>
                    <cart-product-details v-if="cart.bType" :showDialog="openDetail" @close="openDetail=false" :product="cart"/>
                    <label class="body-2">{{cart.size}}</label>
                    <v-list-item-subtitle class="body-2">Rs. {{cart.price}}/-</v-list-item-subtitle>
                    <cart-quantity style="width:100px" v-if="$vuetify.breakpoint.smAndDown" v-model="cart.quantity" @change="addToCart(cart)"></cart-quantity>
                </v-flex>
                <v-flex md4 v-if="$vuetify.breakpoint.mdAndUp">
                    <cart-quantity style="width:100px" v-model="cart.quantity" :min="1" @change="addToCart(cart)"></cart-quantity>
                </v-flex>
            </v-layout>
        </v-list-item-content>
        <v-list-item-icon>
            <v-layout column>
                <v-flex xs6 text-right>
                    <v-icon small @click="deleteItem(cart)">mdi-delete</v-icon>
                </v-flex>
                <v-flex class="mt-5">
                    <v-layout column>
                        <v-list-item-subtitle style="text-align:right">Amount</v-list-item-subtitle>
                        <label class="price-style">Rs. {{amount}}/-</label>
                    </v-layout>
                    <v-flex mt-5 text-right v-if="customizeConditionCheck(cart)">
                        <label @click="showDialog(cart)" class="link body-2 text-decoration-underline">Customize</label>
                    </v-flex>
                    <cart-package-pop-up @close="showPackageDialog=false" :showPackageDialog="showPackageDialog" :packageItem="cart"></cart-package-pop-up>
                    <cart-swap-pop-up v-if="cart.swapOptions?.length>0" @update="updateProduct($event)" @close="show=false" :showDialog="show" :products="cart.swapOptions"></cart-swap-pop-up>
                </v-flex>
            </v-layout>
        </v-list-item-icon>
    </v-list-item>
    <v-divider></v-divider>
</div>
</template>

<script>
import cartQuantity from '@/components/CartQuantity.vue';
import CartSwapPopUp from '@/components/CartSwapPopUp.vue';
import CartPackagePopUp from '@/components/CartPackagePopUp.vue';
import CartProductDetails from '@/components/CartProductDetails.vue';
export default {
    props: {
        item: {
            type: Object,
        }
    },
    components: {
        cartQuantity,
        CartSwapPopUp,
        CartPackagePopUp,
        CartProductDetails
    },
    computed: {
        cart: {
            get() {
                return this.item
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        },
        amount() {
            this.cart.amount = this.cart.quantity * this.cart.price
            return this.cart.amount
        }
    },
    data() {
        return {
            show: false,
            showPackageDialog:false,
            openDetail:false
        }
    },
    methods: {
        updateProduct(index){
            this.cart.swapOptions=this.updateSwap(index,this.cart.swapOptions)
            this.show=false
        },
        deleteItem(product) {
            var cartItem = this.$store.getters.cart
            var index = cartItem.findIndex(rec => rec._id == product._id)
            cartItem.splice(index, 1)
            this.$store.dispatch("actionUpdateCart", cartItem)
            this.$emit('delete', product)
        },
        showDialog(cart){
            if(cart.products?.length>0||cart.addOns?.length>0)
                this.showPackageDialog=true
            else
                this.show=true
        }
    },
}
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
    white-space: normal !important;
}

.price-style {
    font-weight: 400;
    font-size: 1.25rem;
}

.link:hover {
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .price-style {
        font-size: 0.95rem;
    }

    .body-1 {
        font-size: 0.95rem !important;
        line-height: 1rem !important;
    }
    .body-2{
        padding-top:2px;
    }
}
</style>
