<template>
<v-layout class="ma-4">
    <v-flex xs12 offset-sm4>Allow Push Notifications?</v-flex>
    <v-flex xs12 text-left>
        <v-btn small color="primary" class="mx-3" @click="generateToken()">Allow</v-btn>
        <v-btn color="warning" @click="denyNotification()" small>Deny</v-btn>
    </v-flex>
</v-layout>
</template>

<script>
import firebase from "firebase/compat/app"
import "firebase/compat/messaging"
import appConstants from '@/utils/appConstants'
export default {
    data: () => ({
        user:{
            _id:'',
            displayName:'',
            token:''
        }
    }),
    methods: {
        async generateToken() {
            this.user._id=this.$store.getters.user?this.$store.getters.user._id:Math.random().toString(36).slice(2)
            this.user.displayName=this.$store.getters.user?this.$store.getters.user.displayName:'Anonymous'
            let token=await firebase.messaging().getToken({vapidKey: appConstants.FIREBASE_MESASGING_VAPID_KEY})
            this.user.token=token
            let data={'slug':this.$store.getters.vendor.slug,'allowNotification':true,'userId':this.user._id}
            this.$store.dispatch("actionUpdateNotification", data)
            let savedUsers=await this.getItem(appConstants.WEBSTORE_NOTIFICATION+"/"+this.user._id)
            if(savedUsers.length>0){
                if (!savedUsers[0].token.includes(token)&&token)
                    savedUsers[0].token.push(token)
                await this.putItem(appConstants.WEBSTORE_NOTIFICATION+"/"+savedUsers[0]._id,savedUsers[0])
            }
            else
                await this.postItem(appConstants.WEBSTORE_NOTIFICATION,this.user)
        },
        denyNotification(){
            let data={'slug':this.$store.getters.vendor.slug,'allowNotification':false}
            this.$store.dispatch("actionUpdateNotification", data)
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
