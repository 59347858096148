<template>
<div :style="theme" class="footerPosition">
        <v-layout mx-6>
            <v-flex sm7 px-10 mr-12>
                <v-flex mt-6>
                    <router-link class="d-flex align-center" to="/store">
                        <img v-if="$store.getters.vendor.logo" class="logo" width="35" height="35" :src="host + $store.getters.vendor.logo.path" />
                        <v-icon v-else size="35" class="mr-2" :style="theme">mdi-handshake</v-icon>
                        <label :style="theme" class="title-font">{{$store.getters.vendor.businessName}}</label>
                    </router-link>
                </v-flex>
                <v-flex mt-4>
                    <label class="body-2" v-if="$store.getters.vendor.description">{{ $store.getters.vendor.description }}</label>
                </v-flex>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex sm3 mt-6>
                <label class="contact-font" :style="theme">
                    Contact Us
                </label>
                <v-flex mt-4 v-if="$store.getters.vendor.address!=' '">
                    <v-icon class="footer-icon small" :style="theme">mdi-map-marker</v-icon>
                    <label class="small" :style="theme">{{$store.getters.vendor.address}}</label>
                </v-flex>
                <v-flex v-if="$store.getters.vendor.mobileNo!=' '">
                    <v-icon class="footer-icon small" :style="theme">mdi-cellphone</v-icon>
                    <label class="small" :style="theme">{{$store.getters.vendor.mobileNo}}</label>
                </v-flex>
                <v-flex>
                    <v-icon class="footer-icon small" :style="theme">mdi-email</v-icon>
                    <label class="small" :style="theme">{{$store.getters.vendor.email}}</label>
                </v-flex>
                <v-icon @click="openSocialLink($store.getters.vendor.facebook)" v-if="$store.getters.vendor.facebook" class="social-media-icon" :style="theme">mdi-facebook</v-icon>
                <v-icon @click="openSocialLink($store.getters.vendor.twitter)" v-if="$store.getters.vendor.twitter" class="social-media-icon" :style="theme">mdi-twitter</v-icon>
                <v-icon @click="openSocialLink($store.getters.vendor.instagram)" v-if="$store.getters.vendor.instagram" class="social-media-icon" :style="theme">mdi-instagram</v-icon>
            </v-flex>
        </v-layout>
    <v-flex class="secondary-footer text-center">
        <v-layout>
            <v-flex text-right class="secondary-footer-font" style="padding-right:0.4rem">Powered by <a class="primary-color" href="http://onlinedukanhub.in" target="_blank">onlinedukanhub.in</a></v-flex>
            <span class="secondary-footer-font">|</span><v-flex text-left class="secondary-footer-font" style="padding-left:0.4rem">
                <label class="small">v {{appVersion}}</label>
            </v-flex>
        </v-layout>
    </v-flex>

</div>
</template>

<script>
export default {
    methods: {
        openSocialLink(link) {
            window.open(link);
        },
    },
};
</script>

<style scoped>
.title-font {
    font-size: 1.5rem !important;
}

.contact-font {
    font-size: 1.3rem !important;
    margin-bottom: 50% !important;
}

.contact-content {
    font-size: 0.8rem !important;

}

.secondary-footer {
    font-weight: 300;
    background-color: white;
    padding: 1rem;
}
.secondary-footer-font{
    color: black;
}
.footerPosition {
    margin-top: 5rem;
    position: relative;
    bottom: 0;
    color: white;
}

.small {
    font-size: 0.85rem;
    font-weight: 300;
}

.footer-icon {
    font-size: 0.8rem;
    color: white;
    padding-right: 0.5rem;
}

.social-media-icon {
    padding: 0.5rem;
    color: white;
    font-size: 2rem;
}
</style>
