<template>
<v-list-item @click="$router.push('/store/customerOrder/'+order._id)" two-line class="bottom-border">
    <v-list-item-content class="my-4">
        <v-layout>
            <v-flex xs9>
                <v-list-item-subtitle class="subtitle-2"><label class="caption">Order No: </label>{{order.orderNo}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-2"><label class="caption" style="white-space: nowrap">Items: </label><br>
                    <span style="white-space: normal" v-for="(item,index) in order.items" :key="index">
                        <span v-if="index<3">
                            {{item.quantity}} x {{item.title}},
                        </span>
                    </span>
                    <span v-if="order.items.length>3">...</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-2 my-1"><label class="caption">Order Date: </label>{{order.date|dateFormat}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-2 my-1"><label class="caption">Amount: </label>₹{{order.total}}</v-list-item-subtitle>
            </v-flex>
            <v-flex xs3>
                <v-list-item-title>
                    <order-status :status="order.currentStatus"></order-status>
                </v-list-item-title>
                <!-- <v-list-item-icon class="pl-8 mt-6">
                    <v-btn small text @click="$router.push('/store/customerOrder/'+order._id)">
                        <v-icon size=30 :style="themeInverted">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-list-item-icon> -->
            </v-flex>
        </v-layout>
    </v-list-item-content>
</v-list-item>
</template>

<script>
export default {
    props: ["order"],
    data() {
        return {}
    },
};
</script>

<style scoped>
.bottom-border {
    border-bottom: 1px solid #d4d4d4;
}
</style>
