<template>
<v-layout>
    <v-flex>
    <v-icon :style="themeInverted" @click="decrement()">mdi-minus</v-icon>
    <label class="mx-1">{{qty}}</label>
    <v-icon class="mx-1" :style="themeInverted" @click="increment()">mdi-plus</v-icon>
    </v-flex>
</v-layout>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: () => 1
        },
        step: {
            type: Number,
            default: () => 1
        },
        min: {
            type: Number,
            default: () => 0
        },
        showDeleteIcon: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            quantity: 1
        }
    },
    computed: {
        qty: {
            get() {
                this.quantity = this.value >= 1 ? this.value : 1
                return this.quantity
            },
            set(newValue) {
                this.quantity = newValue
                this.updateValue()
            }
        }
    },
    methods: {
        increment() {
            this.qty += this.step
            this.updateValue()
        },
        decrement() {
            if (this.qty > this.min) {
                this.qty -= this.step
                this.updateValue()
            } else if (this.qty == this.min)
                this.$emit(this.appEvents.DELETE)
        },
        updateValue() {
            this.$emit(this.appEvents.INPUT, this.quantity)
            this.$emit(this.appEvents.CHANGE, this.quantity)
        }
    },
}
</script>

<style scoped>
.quantity-control {
    color: orange !important;
    font-size: 30px !important;
}

.centered-input>>>input {
    text-align: center;
    font-size: 1.5rem;
}
</style>
