// firebaseMessagingPlugin.js

import { initializeApp } from 'firebase/app';
import 'firebase/messaging';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const FirebaseMessagingPlugin = {
    install(Vue, firebaseConfig) {
        if (!firebaseConfig) {
            console.error('Please provide a Firebase configuration object.');
            return;
        }

        // Initialize Firebase
        initializeApp(firebaseConfig);

        // Initialize Firebase Cloud Messaging
        const messaging = getMessaging();

        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
                icon: '/favicon.ico'
            };
            if (Notification.permission === 'granted') {
                new Notification(notificationTitle, notificationOptions);
            }
        });


        async function getDeviceId() {
            try {
                let currentToken = await getToken(messaging, { vapidKey: process.env.VUE_APP_VAPID_KEY })
                if (currentToken) {
                    //console.log(`Current tone: ${currentToken}`)
                    return currentToken
                }
            } catch (error) {
                console.log('An error occurred while retrieving token. ', error);
                throw error
                /* let permission = await Notification.requestPermission()
                if (permission === 'granted') {
                    new Notification("Congratulations!!", {
                        body: "Now you will be updated with latest updates",
                        icon: '/favicon.ico'
                    });
                } else {
                    throw err
                } */
            }
        }

        Vue.prototype.$getDeviceId = getDeviceId
        // Add Firebase Messaging to the Vue prototype
        Vue.prototype.$messaging = messaging;
    },
};

export default FirebaseMessagingPlugin;
