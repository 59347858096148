<template>
<v-flex>
    <v-layout wrap ml-8>
        <v-flex sm8 xs6>
            <h2>{{title}}</h2>
        </v-flex>
        <v-flex sm3 xs4 ml-4>
            <v-select @change="fetchReport()" v-model="span" :items="items" title="label" itemText="label" value="value" label="Sales report for"></v-select>
        </v-flex>
    </v-layout>
    <GChart class="px-2" type="ColumnChart" :data="chartData" :options="chartOptions" />
</v-flex>
</template>

<script>
import { GChart } from 'vue-google-charts'
import appConstants from '@/utils/appConstants'
export default {
    components: {
        GChart,
    },
    data() {
        return {
            span: 'monthly',
            items: [
                { label: 'Week', value: 'weekly' },
                { label: 'Month', value: 'monthly' },
                { label: 'Quarter', value: 'quarterly' }
            ],
            reportData: [],
            chartData: {},
            title: '',
            chartOptions: { height: 200, colors: ['orange'] }
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        initComponent() {
            this.fetchReport()
        },
        async fetchReport() {
            this.chartData = [
                ['Label', 'Sales']
            ]
            this.reportData = await this.getItem(appConstants.SALES_REPORT + '?span=' + this.span)
            if(this.$store.getters.vendor.themeUpdated)
                this.chartOptions.colors=[this.$store.getters.vendor.themeColor]
            this.reportData.data.forEach(rec => {
                this.chartData.push([rec.label, rec.value])
            });
            this.title = this.span.charAt(0).toUpperCase() + this.span.slice(1) + ' Sales Report Summary'
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
