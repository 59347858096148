<template>
<v-flex>
    <p class="title">Select Delivery Type</p>
    <v-radio-group mandatory v-model="deliveryType">
        <v-radio v-for="type in deliveryType" :key="type.code" :label="type.label ? type.label : type" :value="type">
        </v-radio>
    </v-radio-group>
</v-flex>
</template>

<script>
export default {
    props: ["type"],
    computed: {
        deliveryType: {
            get(){
                return this.type
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        }
    },
}
</script>
