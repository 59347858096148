<template>
<app-dialog :show="showDialog" width='80%' @close="showDialog=!showDialog">
    <v-layout wrap>
        <v-flex :class="mobileView" sm4 md3 v-for="item in subCategories" :key="item._id">
            <category-card :item="item"></category-card>
        </v-flex>
    </v-layout>
</app-dialog>
</template>

<script>
export default {
    props: {
        subCategories: {
            type: Array,
            default: () => []
        },
        show: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.show
            },
            set(newValue) {
                this.$emit('close')
            }
        }
    },
    data() {
        return {
            mobileView: '',
        }
    },
    mounted() {
        if (this.$store.getters.vendor.imageInRow > 1)
            this.mobileView = "xs6"
        else
            this.mobileView = "xs12"
    },
}
</script>

<style lang="scss" scoped>

</style>
