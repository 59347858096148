<template>
<div>
    <page-header v-model="search" @input="find()" title="Vendors" permission="createVendor" @click="navigateTo('/app/vendors/0')"></page-header>
    <div>
        <v-data-table :headers="headers" :items="items" class="elevation-1" :items-per-page="items.length" :search="search" hide-default-footer>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deleteVendor(item._id):$router.push(icon.path+''+item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <vendor-card :id="item._id" :slug="item.slug" :name="item.businessName" :mobileNo="item.mobileNo" :email="item.email" :icons="icons" @delete="removeItem"></vendor-card>
            </template>
        </v-data-table>
        <pagination :api="apiCall" v-model="items" sort="+createdAt"></pagination>
    </div>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import VendorCard from '@/components/UserResponsiveCard'
export default {
    components: {
        ActionButton,
        VendorCard
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Business Name",
                    value: "businessName"
                },
                {
                    text: "Mobile Number",
                    value: "mobileNo"
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "City",
                    value: "city"
                },
                {
                    text: "Access Point",
                    value: "slug"
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/vendors/view/",
                    permission: "viewVendor"
                }, {
                    name: "mdi-pencil",
                    path: "/app/vendors/",
                    permission: "editVendor"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteVendor"
                }
            ],
            apiCall:appConstants.VENDORS_API
        };
    },
    methods: {
        async deleteVendor(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Vendor?", appConstants.VENDORS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
            }
        },
        removeItem(id) {
            this.deleteVendor(id)
        }
    }
};
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>
