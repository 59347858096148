<template>
<div>
    <v-layout column>
        <v-flex offset-sm11 offset-xs10>
            <label small @click="deleteItem(cart)"><u>Remove</u></label>
        </v-flex>
        <v-list-item>
            <v-list-item-icon>
                <v-img class="align-end" :height="$vuetify.breakpoint.smAndUp?'80px':'50px'" :width="$vuetify.breakpoint.smAndUp?'80px':'50px'" :src="cart.image ? this.host + cart.image.path : defaultProductImage"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
                <v-layout wrap>
                    <v-flex md4>
                        <v-list-item-title @click="openDetail=true" :class="$vuetify.breakpoint.xsOnly?'body-2':'title'" v-text="cart.title"></v-list-item-title>
                        <cart-product-details v-if="cart.bType" :showDialog="openDetail" @close="openDetail=false" :product="cart"/>
                        <v-layout column v-if="$vuetify.breakpoint.xsOnly">
                            <label class="price-style mt-3">₹{{amount}}/-</label>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-list-item-content>
            <v-list-item-icon>
                <v-layout column>
                    <v-flex class="mt-5">
                        <quantity v-model="cart.quantity" @change="addToCart(cart)"></quantity>
                    </v-flex>
                    <v-flex mt-5 text-right v-if="customizeConditionCheck(cart)&&$vuetify.breakpoint.xsOnly">
                        <label @click="showDialog(cart)" class="link body-2 text-decoration-underline">Customize</label>
                    </v-flex>
                    <cart-package-pop-up @close="showPackageDialog=false" :showPackageDialog="showPackageDialog" :packageItem="cart"></cart-package-pop-up>
                    <cart-swap-pop-up v-if="cart.swapOptions?.length>0" @update="updateProduct($event)" @close="show=false" :showDialog="show" :products="cart.swapOptions"></cart-swap-pop-up>
                </v-layout>
            </v-list-item-icon>
            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                <v-layout column>
                    <v-flex ml-12 mt-5 text-right>
                        <label class="price-style">₹{{amount}}/-</label>
                    </v-flex>
                    <v-flex mt-5 text-right v-if="customizeConditionCheck(cart)">
                        <label @click="showDialog(cart)" class="link body-2 text-decoration-underline">Customize</label>
                    </v-flex>
                    <cart-package-pop-up @close="showPackageDialog=false" :showPackageDialog="showPackageDialog" :packageItem="cart"></cart-package-pop-up>
                    <cart-swap-pop-up v-if="cart.swapOptions?.length>0" @update="updateProduct($event)" @close="show=false" :showDialog="show" :products="cart.swapOptions"></cart-swap-pop-up>
                </v-layout>
            </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
    </v-layout>
</div>
</template>

<script>
import cartQuantity from '@/components/CartQuantity.vue';
import AddToCartBtn from '@/webstoreComponents/AddToCartBtn'
import Quantity from '@/webstoreComponents/Quantity'
import CartSwapPopUp from '@/components/CartSwapPopUp.vue'
import CartPackagePopUp from '@/components/CartPackagePopUp.vue';
import CartProductDetails from '@/components/CartProductDetails.vue';
export default {
    props: {
        item: {
            type: Object,
        }
    },
    components: {
        cartQuantity,
        AddToCartBtn,
        Quantity,
        CartSwapPopUp,
        CartPackagePopUp,
        CartProductDetails
    },
    data() {
        return {
            show: false,
            showPackageDialog:false,
            openDetail:false
        }
    },
    computed: {
        cart: {
            get() {
                return this.item
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        },
        amount() {
            this.cart.amount = this.cart.quantity * this.cart.price
            return this.cart.amount
        }
    },
    methods: {
        updateProduct(index){
            this.cart.swapOptions=this.updateSwap(index,this.cart.swapOptions)
            this.show=false
        },
        deleteItem(product) {
            var cartItem = this.$store.getters.cart
            var index = cartItem.findIndex(rec => rec._id == product._id)
            cartItem.splice(index, 1)
            this.$store.dispatch("actionUpdateCart", cartItem)
            this.$emit('delete', product)
        },
        showDialog(cart){
            if(cart.products?.length>0||cart.addOns?.length>0)
                this.showPackageDialog=true
            else
                this.show=true
        },
    },
}
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
    white-space: normal !important;
}

.price-style {
    font-weight: 500;
    font-size: 1.2rem;
}

.link:hover {
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .price-style {
        font-size: 0.95rem;
    }

    .body-2 {
        padding-top: 2px;
    }
}
</style>
