<template>
<v-flex text-center>
    <!-- <v-btn text fab class="mx-2">
        <v-icon style="font-size:3rem" color="primary">mdi-facebook</v-icon>
    </v-btn> -->
    <v-btn class="google-btn" @click="signIn">
        <img class="google-icon" :src="require('@/assets/google-icon.png')">
        <label class="btn-text">Sign in with Google</label>
    </v-btn>
    <app-dialog :show="show" @close="show=!show">
        <v-form ref="form" lazy-validation>
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-row>
                <v-text-field v-model="user.firstName" label="First Name*" class="mx-3" :rules="firstNameRules" required></v-text-field>
                <v-text-field v-model="user.lastName" label="Last Name*" class="mx-3" :rules="lastNameRules"></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="user.email" label="Email*" class="mx-3" disabled></v-text-field>
                <v-text-field v-model="user.mobileNo" label="Enter Mobile Number*" class="mx-3" :rules="mobileNoRules" required></v-text-field>
            </v-row>
            <v-text-field v-model="user.referral" v-if="$store.getters.vendor.enableRewards" label="Referral Code (if any)" />
        </v-form>
        <v-flex text-right>
            <v-btn @click="saveUserDetails()">Submit</v-btn>
        </v-flex>
    </app-dialog>
</v-flex>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
export default {
    data() {
        return {
            show: false,
            user: {
                firstName: '',
                lastName: '',
                email: '',
                mobileNo: ''
            },
            authenticateUser: false
        }
    },
    methods: {
        async signIn() {
            this.user = await this.getGoogleUserInfo()
            this.user.vendorId = this.$store.getters.vendor._id
            var registeredUser = await this.postItem(appConstants.AUTHENTICATE_API + "/userdetails", this.user)
            if (registeredUser.length == 0) {
                this.show = true
                this.setUserDetails()
            } else {
                this.user.mobileNo = registeredUser[0].mobileNo
                this.authenticateUser = true
                this.setUserDetails()
                this.saveUserDetails()
            }
        },
        async getGoogleUserInfo() {
            var userInfo = {}
            return new Promise(async resolve => {
                this.$googleAuth().directAccess()
                await this.$googleAuth().signIn(function (googleUser) {
                        resolve(userInfo = {
                            email: googleUser.getBasicProfile().getEmail(),
                            fullName: googleUser.getBasicProfile().getName(),
                            login: 'GOOGLE'
                        })
                    },
                    function (error) {
                        console.log(error);
                    })
            })
        },
        async saveUserDetails() {
            if (this.authenticateUser || this.$refs.form.validate()) {
                try {
                    var userToken = await this.postItem(appConstants.AUTHENTICATE_API + "/googleLogin", this.user)
                    this.show = false
                    await this.setDataInLocalStorage(userToken.token)
                    this.navigateAfterLogin()
                } catch (error) {
                    this.show = true
                    this.handleError(error)
                    this.msg = error.response.data
                }
            }
        },
        setUserDetails() {
            var name = this.user.fullName.split(" ");
            this.user.firstName = name[0]
            this.user.lastName = name[1]
        }
    },
}
</script>

<style scoped>
.google-btn {
    padding: 1% !important;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background: rgb(65, 133, 245) !important;
}

.google-icon {
    padding:2%;
    width: 34px;
    height: 30px;
    background: white;
}

.btn-text{
    padding-left:5%;
    font-family: roboto;
    text-transform: none;
    color: white;
}
</style>
