<template>
    <v-flex>
        <app-titlebar></app-titlebar>
        <router-view></router-view>
    </v-flex>
</template>

<script>
import AppTitlebar from '@/components/AppTitlebar.vue'
import socketio from 'socket.io-client'
var SocketInstance
export default {
    components: {
        AppTitlebar,
    },
    data() {
        return {
            drawer: false,
        }
    },
    created() {
        //this.initSocketConnection()
    },
    mounted() {
        //this.listenForEvent();
    },
    destroyed() {
        SocketInstance.disconnect();
    },
    methods: {
        initSocketConnection() {
            SocketInstance = socketio(process.env.VUE_APP_API_ENDPOINT, { rejectUnauthorized: false })
        },
        listenForEvent() {
            SocketInstance.on('message', text => {
                this.playSound()
                this.$store.dispatch("actionUpdateOrderCount", 1);
            });
        }
    },
}
</script>

<style scoped></style>
