<template>
<v-flex>
    <v-hover v-slot="{ hover }">
        <v-btn v-if="!alreadyInCart" outlined rounded small :style="hover?theme:themeInverted" @click="updateCart(1)">
            <v-icon class="mr-1" small :style="hover?theme:themeInverted">mdi-cart</v-icon>Add
        </v-btn>
    </v-hover>
    <quantity v-if="alreadyInCart" v-model="product.quantity" :min="1" :showDeleteIcon="true" @change="updateCart($event)" @delete="removeFromCart(product)"></quantity>
</v-flex>
</template>

<script>
import Quantity from '@/webstoreComponents/Quantity.vue';
export default {
    props: {
        value: {
            type: Object,
            required: true,
            default: () => {
                _id: 0
            }
        },
    },
    components: {
        Quantity,
    },
    data() {
        return {
            snackbar: false,
            text: "Added to cart successfully",
            timeout: 2000
        }
    },
    computed: {
        product:{
            get(){
                return this.value
            }
            ,set(newValue){
                return newValue
            }
        },
        alreadyInCart() {
            if (this.product) {
                return this.checkInCart(this.product._id) ? true : false
            }
            return false
        },
    },
    mounted() {
        let prod = this.checkInCart(this.value._id);
        this.product = prod ? prod : this.value
    },
    methods: {
        updateCart(qty) {
            this.addToCart(this.product)
            this.product.quantity = qty
            this.snackbar = true
        },
        removeFromCart(product) {
            this.$store.getters.cart.splice(this.$store.getters.cart.findIndex(rec => rec._id == product._id), 1)
            var cart = this.$store.getters.cart
            this.$store.dispatch('actionUpdateCart', cart)
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
