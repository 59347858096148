<template>
    <span>
        <v-btn class="primary-inverted" :loading="collectingPayment" @click="toggleShowUpgradePlanPopup()"
            :disabled="disabled">Upgrade</v-btn>
        <app-dialog :show="showUpgradePlanPopup" @close="toggleShowUpgradePlanPopup()" title="Upgrade Subscription">
            <v-form ref="upgradeSubscriptionForm">
                <v-row align="center">
                    <v-col cols="8" offset="2">
                        <p>New plan details:</p>
                        <div class="border pa-3 elevation-4">
                            <label class="text-h6">{{ planDetails?.title }}</label>
                            <p>Rs.<label class="d-inline-flex align-center text-h6">{{ planDetails?.amount }}/-
                                </label>
                                every
                                {{ 12 / planDetails?.interval }} Month
                            </p>
                        </div>
                        <v-radio-group label="Update" v-model="upgradePlan.scheduleChangeAt">
                            <v-radio label="Immediate" value="now"
                                @click="calculateProrataAmount($store.getters.vendor.licensingDetails.subscriptionId, planDetails.planId)"></v-radio>
                            <v-radio label="End of Billing Cycle" value="cycle_end"></v-radio>
                        </v-radio-group>
                        <!-- <row-item label="Remaining count" :value="upgradePlan.remainingCount"></row-item> -->
                        <v-alert color="red" dark v-if="errorMsg">{{ errorMsg }}</v-alert>
                        <div class="text-right">
                            <v-btn :loading="upgradingSubscription" @click="upgrade(upgradePlan, false)"
                                class="ma-1">Upgrade</v-btn>
                            <!-- <razorpay-payment-btn :paymentDetails="orderDetails" :vendorDetails="$store.getters.vendor"
                                v-else-if="false" :label="`Pay Rs.${prorataAmount}/-`"
                                @success="upgrade(upgradePlan, true)"></razorpay-payment-btn> -->
                            <v-btn @click="toggleShowUpgradePlanPopup()">Cancel</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </app-dialog>
    </span>
</template>

<script>
import appConstants from '../utils/appConstants';
import RazorpayPaymentBtn from './RazorpayPaymentBtn.vue';
import RowItem from './RowItem.vue';

export default {
    components: { RazorpayPaymentBtn, RowItem },
    props: {
        planDetails: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showUpgradePlanPopup: false,
            collectingPayment: false,
            calculatingProrataAmount: false,
            upgradingSubscription: false,
            errorMsg: "",
            prorataAmount: 0,
            upgradePlan: {
                planId: "",
                quantity: 1,
                scheduleChangeAt: 'cycle_end',
                customerNotify: true,
                remainingCount: this.currentSubscription?.remaining_count,
            }
        }
    },
    computed: {
        orderDetails() {
            let order = { ...this.paymentDetails }
            order.amount = this.prorataAmount
            order.notes = {
                vendorId: this.vendorDetails._id.toString(),
                vendorName: this.vendorDetails.businessName,
                earlyUpgrade: this.$store.getters.vendor.licensingDetails.subscriptionId
            }
            return order
        }
    },
    methods: {
        async toggleShowUpgradePlanPopup(close = false) {
            if (!close && this.planDetails) {
                this.currentSubscription = await this.getItem(`${appConstants.BILLINGS_API}/subscriptions/${this.$store.state.vendor.licensingDetails.subscriptionId}`)
                this.upgradePlan.planId = this.planDetails.planId
                this.upgradePlan.remainingCount = this.currentSubscription.remainingCount
            }
            this.showUpgradePlanPopup = !this.showUpgradePlanPopup
        },
        async calculateProrataAmount(currentSubscriptionId, targetPlanId) {
            try {
                this.calculatingProrataAmount = true
                this.prorataAmount = (await this.getItem(`${appConstants.BILLINGS_API}/subscriptions/${currentSubscriptionId}/upgrade/${targetPlanId}`)).amount
            } catch (error) {
                this.handleError(error)
                this.errorMsg = error.message
            } finally {
                this.calculatingProrataAmount = false
            }
        },
        async upgrade(upgradePlan, earlyUpgrade = false) {
            try {
                this.upgradingSubscription = true
                if (earlyUpgrade) {
                    upgradePlan.scheduleChangeAt = 'cycle_end'
                    await this.putItem(`${appConstants.BILLINGS_API}/subscriptions/${this.$store.state.vendor.licensingDetails.subscriptionId}/earlyUpgrade`, upgradePlan)
                } else {
                    await this.putItem(`${appConstants.BILLINGS_API}/subscriptions/${this.$store.state.vendor.licensingDetails.subscriptionId}`, upgradePlan)
                }
                this.refreshSubscription()
                this.toggleShowUpgradePlanPopup(true)
            } catch (error) {
                this.errorMsg = error?.response?.data?.error?.description
                console.error(error.response.data);
            } finally {
                this.upgradingSubscription = false;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.border {
    border: 1px solid #cecece !important;
    border-radius: 8px;
}
</style>