import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '@/views/store/Home.vue'
import Products from '@/views/store/Products.vue'
import ProductDetail from '@/views/store/ProductDetail.vue'
import Cart from '@/views/store/Cart.vue'
import StoreLogin from '@/views/store/Login.vue'
import Checkout from '@/views/store/Checkout.vue'
import StoreLayout from '@/layouts/StoreLayout.vue'
import ApplicationLayout from '@/layouts/ApplicationLayout.vue'
import Dashboard from '@/views/app/Dashboard.vue'
import AppLogin from '@/views/app/Login.vue'
import AddBrand from '@/views/app/brand/AddBrand.vue'
import ViewBrand from '@/views/app/brand/ViewBrand.vue'
import ResetPassword from '@/views/store/ResetPassword.vue'
import Vendors from '@/views/app/vendor/Vendors.vue'
import CustomerOrder from '@/views/store/CustomerOrder.vue'
import CustomerOrderDetail from '@/views/store/CustomerOrderDetail.vue'
import EditAccount from '@/views/store/EditAccount.vue'
import axios from 'axios'
import VendorRegistration from '@/views/store/Registration'

Vue.use(VueRouter)

const routes = [
  /* {
    path: '/store',
    name: 'Vendors',
    component: VendorList
  }, */
  {
    path: '/:slug/store',
    name: 'StoreLayout',
    component: StoreLayout,
    children: [{
      path: '',
      name: 'Home',
      component: Home
    },
    {
      path: 'products',
      name: 'Products',
      component: Products
    }, {
      path: 'categoryAndProducts/:id',
      name: 'CategoryAndProducts',
      component: () => import("@/views/store/CategoryAndProducts.vue")
    },
    {
      path: 'products/:id',
      name: 'ProductDetail',
      component: ProductDetail
    }, {
      path: 'cart',
      name: 'Cart',
      component: Cart,
      meta: {
        layout: 'webstore'
      }
    },
    {
      path: 'login',
      name: 'StoreLogin',
      component: StoreLogin
    },
    {
      path: 'checkout',
      name: 'Checkout',
      component: Checkout
    },
    {
      path: 'resetPassword',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: 'editAccount/:id',
      name: 'EditAccount',
      component: EditAccount,
      meta: {
        layout: 'webstore'
      }
    },
    {
      path: 'customerOrder',
      name: 'CustomerOrder',
      component: CustomerOrder,
      meta: {
        layout: 'webstore'
      }
    }, {
      path: 'customerOrder/:id',
      name: 'CustomerOrderDetail',
      component: CustomerOrderDetail,
      meta: {
        layout: 'webstore'
      }
    },
    {
      path: 'subscription',
      name: 'StoreSubscription',
      component: () => import("@/views/Subscription.vue")
    },
    {
      path: '*',
      redirect: {
        name: "Error"
      },
    }
    ]
  },
  {
    path: '/:slug/app',
    name: 'ApplicationLayout',
    component: ApplicationLayout,
    beforeEnter: (to, from, next) => {
      to.matched.some(rec => {

        if (rec.path.startsWith("/:slug/app") && store.state.token !== "") {
          next()
        } else {
          next("/store/login")
        }
      })
    },
    children: [{
      path: 'login',
      name: 'StoreLogin',
      component: StoreLogin
    },
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: 'orders',
      name: 'Orders',
      component: () => import("@/views/app/order/Orders.vue")
    },
    {
      path: 'orders/:id',
      name: 'AddOrder',
      component: () => import("@/views/app/order/AddOrder.vue")
    },
    {
      path: 'orders/view/:id',
      name: 'ViewOrder',
      component: () => import("@/views/app/order/ViewOrder.vue")
    },
    {
      path: 'suppliedItems',
      name: 'SuppliedItem',
      component: () => import("@/views/app/suppliedItem/SuppliedItems.vue")
    },
    {
      path: 'suppliedItems/view/:id',
      name: 'ViewSuppliedItem',
      component: () => import("@/views/app/suppliedItem/ViewSuppliedItem.vue")
    },
    {
      path: 'inventory',
      name: 'Inventory',
      component: () => import("@/views/app/inventory/Inventory.vue")
    },
    {
      path: 'inventory/:id',
      name: 'AddInventory',
      component: () => import("@/views/app/inventory/AddInventory.vue")
    },
    {
      path: 'inventory/view/:id',
      name: 'ViewInventory',
      component: () => import("@/views/app/inventory/ViewInventory.vue")
    },
    {
      path: 'coupons',
      name: 'Coupons',
      component: () => import("@/views/app/coupon/Coupons.vue")
    },
    {
      path: 'coupons/:id',
      name: 'AddCoupon',
      component: () => import("@/views/app/coupon/AddCoupon.vue")
    },
    {
      path: 'coupons/view/:id',
      name: 'ViewCoupon',
      component: () => import("@/views/app/coupon/ViewCoupon.vue")
    },
    {
      path: 'customers',
      name: 'Customers',
      component: () => import("@/views/app/customer/Customers.vue")
    },
    {
      path: 'customers/:id',
      name: 'AddCustomer',
      component: () => import("@/views/app/customer/AddCustomer.vue")
    },
    {
      path: 'customers/view/:id',
      name: 'ViewCustomer',
      component: () => import("@/views/app/customer/ViewCustomer.vue")
    },
    {
      path: 'staffs',
      name: 'Staffs',
      component: () => import("@/views/app/staff/Staffs.vue")
    },
    {
      path: 'staffs/:id',
      name: 'AddStaff',
      component: () => import("@/views/app/staff/AddStaff.vue")
    },
    {
      path: 'staffs/view/:id',
      name: 'ViewStaff',
      component: () => import("@/views/app/staff/ViewStaff.vue")
    },
    {
      path: 'staffs/resetStaffPassword/:id',
      name: 'resetStaffPassword',
      component: () => import("@/views/app/staff/ResetPassword.vue")
    },
    {
      path: 'roles',
      name: 'Roles',
      component: () => import("@/views/app/roles/Roles.vue")
    },
    {
      path: 'roles/:id',
      name: 'AddRole',
      component: () => import("@/views/app/roles/AddRole.vue")
    },
    {
      path: 'roles/view/:id',
      name: 'ViewRole',
      component: () => import("@/views/app/roles/ViewRole.vue")
    },
    {
      path: 'products',
      name: 'AppProducts',
      component: () => import("@/views/app/product/Products.vue")
    },
    {
      path: 'products/:id',
      name: 'AddProduct',
      component: () => import("@/views/app/product/AddProduct.vue")
    },
    {
      path: 'products/view/:id',
      name: 'ViewProduct',
      component: () => import("@/views/app/product/ViewProduct.vue")
    },
    {
      path: 'products/productBundle/:id',
      name: 'ProductBundle',
      component: () => import("@/views/app/product/ProductBundle.vue")
    },
    {
      path: 'products/view/productBundle/:id',
      name: 'ViewProductBundle',
      component: () => import("@/views/app/product/ViewProductBundle.vue")
    },
    {
      path: 'importfile',
      name: 'ImportFile',
      component: () => import("@/views/app/ImportFiles.vue")
    },
    {
      path: 'categories',
      name: 'Categories',
      component: () => import("@/views/app/category/Categories.vue")
    },
    {
      path: 'brands',
      name: 'Brands',
      component: () => import("@/views/app/brand/Brands.vue")
    },
    {
      path: 'brands/:id',
      name: 'AddBrand',
      component: AddBrand
    },
    {
      path: 'brands/view/:id',
      name: 'ViewBrand',
      component: ViewBrand
    },
    {
      path: 'categories/:id',
      name: 'AddCategories',
      component: () => import("@/views/app/category/AddCategory")
    },
    {
      path: 'categories/view/:id',
      name: 'ViewCategory',
      component: () => import("@/views/app/category/ViewCategory")
    },
    {
      path: 'packages',
      name: 'Packages',
      component: () => import("@/views/app/package/Packages.vue")
    },
    {
      path: 'packages/:id',
      name: 'AddPackages',
      component: () => import("@/views/app/package/AddPackage.vue")
    },
    {
      path: 'packages/view/:id',
      name: 'ViewPackage',
      component: () => import("@/views/app/package/ViewPackage.vue")
    },
    {
      path: 'vendors',
      name: 'Vendors',
      component: Vendors
    },
    {
      path: 'vendors/:id',
      name: 'AddVendor',
      component: () => import("@/views/app/vendor/AddVendor.vue")
    },
    {
      path: 'vendors/view/:id',
      name: 'ViewVendor',
      component: () => import("@/views/app/vendor/ViewVendor.vue")
    },
    {
      path: 'settings',
      name: 'VendorSettings',
      component: () => import("@/views/app/setting/Settings.vue")
    },
    {
      path: 'settings/:id',
      name: 'ModifyVendorSettings',
      component: () => import("@/views/app/setting/ModifySetting.vue")
    },
    {
      path: 'reports',
      name: 'Reports',
      component: () => import("@/views/app/report/ReportHome.vue")
    },
    {
      path: 'reports/stock',
      name: 'StockReport',
      component: () => import("@/views/app/report/StockReport.vue")
    },
    {
      path: 'reports/daywiseSale',
      name: 'DaywiseSaleReport',
      component: () => import("@/views/app/report/DaywiseSaleReport.vue")
    },
    {
      path: 'reports/productSold',
      name: 'ProductSoldReport',
      component: () => import("@/views/app/report/ProductSoldReport.vue")
    },
    {
      path: 'reports/productsToOrder',
      name: 'ProductsToOrder',
      component: () => import("@/views/app/report/ProductsToOrder.vue")
    },
    {
      path: 'reports/salesReport',
      name: 'SalesReport',
      component: () => import("@/views/app/report/SalesReport.vue")
    },
    {
      path: 'reports/expenseAndIncomeReport',
      name: 'ExpenseAndIncomeReport',
      component: () => import("@/views/app/report/ExpenseAndIncomeReport.vue")
    },
    {
      path: 'reports/vendorStatsReport',
      name: 'VendorStatsReport',
      component: () => import("@/views/app/report/VendorStatsReport.vue")
    },
    {
      path: 'expenses',
      name: 'Expenses',
      component: () => import("@/views/app/expenseManager/Expense.vue")
    },
    {
      path: 'expenses/:id',
      name: 'AddExpenses',
      component: () => import("@/views/app/expenseManager/AddExpense.vue")
    },
    {
      path: 'expenses/view/:id',
      name: 'ViewExpenses',
      component: () => import("@/views/app/expenseManager/ViewExpense.vue")
    },
    {
      path: 'plans',
      name: 'Plans',
      component: () => import("@/views/app/plan/Plans.vue")
    },
    {
      path: 'plans/:id',
      name: 'AddPlans',
      component: () => import("@/views/app/plan/AddPlan.vue")
    },
    {
      path: 'plans/view/:id',
      name: 'ViewPlans',
      component: () => import("@/views/app/plan/ViewPlan.vue")
    },
    {
      path: 'subscription',
      name: 'AppSubscription',
      component: () => import("@/views/Subscription.vue")
    },
    {
      path: '*',
      redirect: {
        name: "Error"
      },
    }
    ]
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import("@/views/store/Error.vue")
  },
  {
    path: '/webstoreSuspended',
    name: 'WebStoreSuspended',
    component: () => import("@/views/store/WebStoreSuspended.vue")
  },
  {
    path: '/registration',
    name: 'VendorRegistration',
    component: VendorRegistration
  },
]

const LICENSING_ERROR = 451
const VENDOR_NOT_FOUND = 500
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path.endsWith('/login')) {
      return { x: 0, y: 10 }
    } else {
      return savedPosition
    }
  }
})


router.beforeEach(async (to, from, next) => {
  var params = to.query
  var nextPath = to.path
  var slug = to.params.slug
  var localSlug = store.getters.vendor ? store.getters.vendor.slug.substr(1) : undefined
  let superAdmin = false
  JSON.parse(localStorage.getItem('userProfile'))?.roles?.forEach(rec => {
    if (rec.value === 'sysadmin') {
      superAdmin = true
    }
  })
  //console.log("NextPath: " + nextPath + "\tSlug: " + slug + "\tlocal: " + localSlug);
  if (store.state.vendor?.accountStatus !== 'ACTIVE' && !superAdmin) {
    let allowedPaths = ['/store/login', '/store', '/registration', `${slug}/app/subscription`, '/error']
    if (allowedPaths.some(path => to.path.endsWith(path))) {
      next()
    } else if (!to.path.endsWith('/app/subscription')) {
      next(`/app/subscription`)
    }
  } else
    if (nextPath == "/")
      return next("/error")
  if (['/webstoreSuspended', '/error', '/registration'].includes(nextPath)) {
    return next()
  }
  try {
    if (!slug && localSlug) { // navigating between pages in same vendor
      nextPath = appendSlugTo(nextPath, localSlug)
      return next(nextPath)
    } else if (slug && !localSlug || (slug != localSlug)) { //switching vendor
      await loadVendorDetails(slug)
      nextPath = nextPath.replace(localSlug, slug)
      let length = Object.entries(params).length
      if (length > 0) {
        nextPath += "?"
        Object.entries(params).forEach(rec => {
          nextPath += (rec[0] + '=' + rec[1]);
          length--
          if (length > 0)
            nextPath += '&'
        })
      }
      return next(nextPath)
    } if (slug == localSlug) { // successfull navigation
      return next()
    } else {
      return next("/error")
    }
  } catch (error) {
    if (error.response.status == LICENSING_ERROR) {
      return next("/webstoreSuspended")
    } else if (error.response.status == VENDOR_NOT_FOUND) {
      return next("/error")
    }
  }
})

function appendSlugTo(nextPath, localSlug) {
  localSlug = (localSlug && !localSlug.startsWith("/")) ? "/" + localSlug : localSlug
  //console.log("Path to append: " + nextPath + "\tSlug to append: " + localSlug + "\tAppended: " + (!nextPath.startsWith(localSlug)))
  if (!nextPath.startsWith(localSlug)) {
    //console.log(`Appending ${localSlug} to ${nextPath}`)
    return localSlug + nextPath
  }
  //console.log("Appending slug not required")
  return nextPath
}

async function loadVendorDetails(slug) {
  try {
    console.log("Reading vendor details");
    let path = process.env.VUE_APP_API_ENDPOINT + '/api/webstore/vendors/slug/' + slug
    console.log("Path: " + path);
    let vendor = await axios.get(path)
    store.dispatch("actionUpdateVendor", vendor.data);
    buildManifestFile(vendor.data)
  } catch (error) {
    //console.log(error.response.status);
    throw error
  }
}

/**
 * Dynamically build the manifest.json and set in index.html
 * @param {*} vendor 
 */
function buildManifestFile(vendor) {
  console.log("Building manifest");
  var logoImage = (vendor.logo ? process.env.VUE_APP_API_ENDPOINT + vendor.logo.path : require('@/assets/logo.png'))
  var dimensions = vendor.logo ? vendor.logo.height + "x" + vendor.logo.width : '280x280'
  var type = vendor.logo ? vendor.logo.mimetype : 'image/png'
  var myDynamicManifest = {
    "name": vendor.businessName,
    "start_url": window.location.protocol + "//" + window.location.host + vendor.slug + "/store",
    "background_color": "#000000",
    "theme_color": "#0f4a73",
    "display": "standalone",
    "icons": [{
      "src": logoImage,
      "sizes": dimensions,
      "type": type
    }]
  }
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  const googleFontURL = new URL(window.location.protocol + "//fonts.googleapis.com/css?family=" + vendor.fontFamily)
  document.querySelector('#dynamic-manifest-placeholder').setAttribute('href', manifestURL);
  document.querySelector('#dynamic-font-placeholder').setAttribute('href', googleFontURL);
}

export default router