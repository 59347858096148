<<template>
    <old-store-layout v-if="!$store.getters.vendor.storeUI||$store.getters.vendor.storeUI==oldStoreUI"></old-store-layout>
    <new-store-layout v-else-if="$store.getters.vendor.storeUI==newStoreUI"></new-store-layout>
</template>

<script>
import OldStoreLayout from '@/components/OldStoreLayout'
import NewStoreLayout from '@/webstoreComponents/NewStoreLayout'
    export default {
        components: {
            OldStoreLayout,
            NewStoreLayout
        },
        
    }
</script>

<style lang="scss" scoped>

</style>