<template>
<v-flex>
    <v-layout>
        <v-text-field class="input-center-align centered-input" type="number" v-model.number="qty" label="Qty" @blur="updateValue()"
        :append-outer-icon="showDeleteIcon ? 'mdi-delete' : ''" @click:append-outer="$emit(appEvents.DELETE)">
        <template v-slot:prepend-inner>
            <v-icon :style="themeInverted" @click="decrement()">mdi-minus</v-icon>
        </template>
        <template v-slot:append>
            <v-icon :style="themeInverted" @click="increment()">mdi-plus</v-icon>
        </template>
        </v-text-field>
    </v-layout>
</v-flex>
</template>

<script>
export default {
    props: {
        value:{
            type: Number,
            default: () => 1
        },
        step: {
            type: Number,
            default: () => 1 
        },
        min:{
            type: Number,
            default:() => 0
        },
        showDeleteIcon:{
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            quantity: 1
        }
    },
    computed: {
        qty: {
            get(){
                this.quantity = this.value >= 1 ? this.value : 1
                return this.quantity
            },
            set(newValue){
                this.quantity = newValue
                this.updateValue()
            }
        }
    },
    methods: {
        increment() {
            this.qty +=this.step
            this.updateValue()
        },
        decrement() {
            if(this.qty > this.min){
                this.qty -=this.step
                this.updateValue()
            }
        },
        updateValue(){
            this.$emit(this.appEvents.INPUT, this.quantity)
            this.$emit(this.appEvents.CHANGE, this.quantity)
        }
    },
}
</script>

<style scoped>
.quantity-control{
    color: orange !important;
    font-size: 30px !important;
}
.centered-input >>> input {
    text-align: center;
    font-size: 1.5rem;
}
</style>
