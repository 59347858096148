<template>
<v-container>
    <v-layout column>
        <label class="headline">Select Delivery Address</label>
        <v-flex class="ma-2" v-if="user[0].address.length>0&&!disabled">
            <label>Current Address</label>
            <v-radio-group v-model="address">
                <v-radio v-for="address in user[0].address" :key="address" :label="address" :value="address"></v-radio>
            </v-radio-group>
        </v-flex>
        <label v-if="!disabled" class="ma-2">Enter new Address</label>
        <v-layout v-if="!disabled" class="ma-2" wrap>
            <v-text-field v-model="houseNo" sm3 class="ma-2" label="Wing-Flat No/ House No" placeholder="e.g. A-102"></v-text-field>
            <v-text-field v-model="building" sm3 class="ma-2" label="Building / House"></v-text-field>
            <v-text-field v-model="landmark" sm3 class="ma-2" label="Landmark"></v-text-field>
            <v-text-field v-model="subarea" sm3 class="ma-2" label="Subarea"></v-text-field>
            <v-text-field v-model="city" sm3 class="ma-2" label="City"></v-text-field>
            <v-text-field v-model="pincode" sm3 class="ma-2 shrink" label="Pincode"></v-text-field>
        </v-layout>
        <v-flex text-right sm3>
            <v-btn :style="theme" @click="submit()">Place Order</v-btn>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import appConstants from '@/utils/appConstants'
import axios from 'axios'
export default {
    data() {
        return {
            address: '',
            houseNo: '',
            building: '',
            landmark: '',
            subarea: '',
            city: '',
            pincode: '',
            user: [{
                address: '',
            }],
            order: {
                user: {
                }
            },
            disabled: false,
            saveUserAddress: false
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.order = this.$route.params.entity
            this.user = await this.getItem(appConstants.WEBSTORE_USER + "/" + this.getUserProfile()._id)
            if (this.order.delivery.type != 'Home Delivery')
                this.disabled = true
        },
        submit() {
            if (!this.address && !this.disabled) {
                this.address = this.houseNo + ", " + this.building + ", " + this.landmark + ", " + this.subarea + ", " + this.city + ", " + this.pincode
                this.saveUserAddress = true
            }
            this.order.delivery.address = this.address
            if (this.order.customer.displayName == "") {
                alert("Log in to place order")
                this.$router.push("/store/login")
            } else {
                this.placeOrder()
            }
        },
        async placeOrder() {
            try {
                if (this.saveUserAddress) {
                    this.saveAddress()
                }
                await this.postItem(appConstants.ORDERS_API, this.order)
                alert("Congratulations!! Your Order Placed successfully")
                this.$store.dispatch("actionUpdateCart", null)
                this.$router.push("/store")
            } catch (error) {
                this.handleError(error)
            }
        },
        saveAddress() {
            var user = {
                address: [],
                mobileNo: '',
                email: ''
            }
            user._id = this.user[0]._id
            user.mobileNo = this.user[0].mobileNo
            user.email = this.user[0].email
            this.user[0].address.forEach(add => {
                user.address.push(add)
            })
            user.address.push(this.address)
            this.order.user = user
        }
    }
}
</script>

<style scoped>

</style>
