<template>
<v-flex style="padding:2%">
    <v-card @click="getSubCategories(category)" class="mx-auto">
        <v-flex text-center class="ma-3">
            <v-img :src="category.image? imagePath(category.image.path):defaultCategoryImage" :height="isMobile ? '150px' : '200px'" width="100%" />
        </v-flex>
        <v-flex text-center>{{category.title}}</v-flex>
    </v-card>
    <composite-category v-if="subCategories.length>0" :subCategories="subCategories" :show='show' @close="toggleDialog"></composite-category>
</v-flex>
</template>

<script>
import AppDialog from '@/components/AppDialog.vue';
import appConstants from '@/utils/appConstants'
import CompositeCategory from '@/components/CompositeCategory'
export default {
    components: {
        AppDialog,
        CompositeCategory
    },
    props: ['item'],
    computed: {
        category() {
            if (this.item == undefined) {
                return this.defaultCategory
            }
            return this.item;
        }
    },
    data() {
        return {
            defaultCategory: { id: "a", title: "Category Name", image: this.defaultCategoryImage },
            subCategories: [],
            show:false
        }
    },
    methods: {
        showProduct(category) {
            this.$router.push({ name: 'Products', query: { category: category._id } })
        },
        async getSubCategories(category) {
            this.subCategories = await this.getItem(appConstants.WEBSTORE_CATEGORIES + "/subCategory/" + category._id)
            if(this.subCategories.length==0)
                this.showProduct(category)
            else
                this.show=true
        },
        toggleDialog(){
            this.show=!this.show
        }
    },

}
</script>

<style scoped>

</style>
