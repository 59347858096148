<template>
<v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
    </template>
    <span>{{title}}</span>
</v-tooltip>
</template>

    
<script>
export default {
    props: {
        title: {
            type: String,
            default: () => "tooltip"
        },
    },
}
</script>

    
<style lang="scss" scoped>

        </style>
