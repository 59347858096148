<template>
<v-card>
    <v-flex text-right v-if="showClose">
        <v-btn small @click="$emit(appEvents.CLOSE)" icon text>
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-flex>
    <v-card-text>
        <v-flex class="text-center">
            <v-img :src="imagePath(src)" :width="width || '100%'" :height="height || '150'"></v-img>
        </v-flex>
    </v-card-text>
</v-card>
</template>

<script>
export default {
    props: ['src', 'showClose', 'width', 'height']
}
</script>

<style scoped>

</style>
