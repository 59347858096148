<template>
    <v-btn class="whatsapp-btn" title="Share on WhatsApp" :href="urlEncodedMsg" target="_blank"><v-icon class="mr-2">mdi-whatsapp</v-icon> SHARE</v-btn>
</template>

<script>
    export default {
        props:['whatasppMsg'],
        computed: {
            urlEncodedMsg() {
                return encodeURI("https://wa.me/?text="+this.whatasppMsg)
            }
        },
    }
</script>