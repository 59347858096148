<template>
  <v-flex>
    <v-btn small block class="mt-1" :style="theme" @click="placeOrder(1)">Order Now</v-btn>
  </v-flex>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        _id: 0;
      },
    },
  },
  mounted() {
    let prod = this.checkInCart(this.value._id);
    this.product = prod ? prod : this.value;
  },
  methods: {
    placeOrder(qty) {
      if (this.product.quantity == undefined){
        this.product.quantity = qty;
        this.addToCart(this.product);
      }
      this.$router.push("/store/cart");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>