<template>
<div>
    <v-carousel cycle class="my-2 carousel-width" hide-delimiter-background show-arrows-on-hover :height="$vuetify.breakpoint.mdAndUp?'500':'250'" v-if="$store.getters.vendor.bannerImages && $store.getters.vendor.bannerImages.length>0">
        <v-carousel-item v-for="(image,index) in images" :key="index">
            <v-row class="fill-height" align="center" justify="center">
                <v-img contain :height="$vuetify.breakpoint.mdAndUp?500:250" :src="host+image.path"></v-img>
            </v-row>
        </v-carousel-item>
    </v-carousel>
    <v-carousel class="my-2 carousel-width" :height="$vuetify.breakpoint.mdAndUp?500:'250'" cycle hide-delimiter-background show-arrows-on-hover v-else>
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet :color="colors[i]" height="100%">
                <v-row class="fill-height" align="center" justify="center">
                    <div class="display-3">{{ slide }} Slide</div>
                </v-row>
            </v-sheet>
        </v-carousel-item>
    </v-carousel>
</div>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
        },
    },
    data() {
        return {
            colors: [
                'indigo',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
            ],
        };
    },
};
</script>

<style scoped>

.carousel-width {
    width: 95%;
}
</style>
