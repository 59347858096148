<template>
<div class="mx-4 my-4">
    <h4 :style="themeInverted">Rewards</h4>
    <row-item class="px-10" label="Total Reward Points" :value="totalRewardPoint"></row-item>
    <h4 :style="themeInverted">Reward Logs</h4>
    <v-card elevation="2">
        <v-simple-table v-if="rewardLogs.length>0">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Message</th>
                    <th class="text-right">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in rewardLogs" :key="index">
                    <td>{{item.date|dateTimeFormat}}</td>
                    <td>{{item.message}}</td>
                    <td v-if="item.rewardTxType=='CR'" class="text-right" style="color:green">{{item.point}}</td>
                    <td v-if="item.rewardTxType=='DR'" class="text-right" style="color:red">-{{item.point}}</td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-flex v-else text-center>
            <v-alert outlined color="grey lighten-1" icon="mdi-receipt" prominent>
                <h1>No reward logs found</h1>
            </v-alert>
        </v-flex>
    </v-card>
</div>
</template>

<script>
export default {
    props: {
        rewardLogs: {
            type: Array,
            default: () => []
        },
        totalRewardPoint:{
            type:Number,
            default: () => 0
        }
    },
    mounted() {
        this.rewardLogs.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        })
    },
}
</script>
