<template>
<div>
    <v-btn :style="modeType=='CASH'?theme:themeInverted" @click="modeType='CASH'" :block="showAsBlock" outlined class="my-4 border-light">
        <v-icon class="pr-2">mdi-cash</v-icon><label>Cash</label>
    </v-btn>
    <v-btn :style="modeType=='GPAY'?theme:themeInverted" @click="modeType='GPAY'" :block="showAsBlock" outlined class="mx-3 border-light">
        <v-icon class="pr-2">mdi-google</v-icon><label>Google Pay</label>
    </v-btn>
</div>
</template>

<script>
export default {
    props: {
        showAsBlock: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'CASH'
        },
        alignCenter:{
            type:Boolean,
            default:false
        }
    },
    computed: {
        modeType: {
            get() {
                return this.mode
            },
            set(newValue) {
                this.$emit(this.appEvents.UPDATE, newValue)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.border-light {
    border-color: lightgray !important;
    text-transform: capitalize;
}
</style>
