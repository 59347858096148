<template>
<v-chip class="font" label block :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.smAndDown" dark :color="statusColor" v-if="status">{{status}}</v-chip>
</template>

<script>
export default {
    props: ['status'],
    computed: {
        statusColor: {
             get(){
                 return this.checkStatus()
             }
        }
    },
    methods: {
        checkStatus() {
            switch (this.status) {
                case 'DELIVERED':
                    return 'green'
                    break;
                case 'INPROCESS':
                    return 'primary'
                    break;
                case 'READY':
                    return 'success'
                    break;
                case 'CANCELED':
                    return 'red'
                    break;
                case 'DEFAULTED':
                    return 'red'
                    break;
                case 'REJECTED':
                    return 'red'
                    break;
                default:
                    return 'orange'
            }
        }
    },
}
</script>

<style scoped>
.font{
    font-size: 0.7rem !important;
}
@media only screen and (max-width: 400px) {
    .font{
        font-size: 0.6rem !important;
    }
}
</style>
