<template>
<v-flex>
    <v-slide-group show-arrows>
        <v-slide-item :class="$vuetify.breakpoint.smAndUp?'mx-2':'mx-4'" v-for="(item, i) in couponArray" :key="i">
            <store-coupon-card :coupon="item"></store-coupon-card>
        </v-slide-item>
    </v-slide-group>
</v-flex>
</template>

<script>
import StoreCouponCard from '@/components/StoreCouponCard'
export default {
    props: {
        couponArray: {
            type: Array
        },
    },
    components: {
        StoreCouponCard,
    },
}
</script>

<style scoped>
.code-font {
    font-size: 1.5rem;
}
</style>
