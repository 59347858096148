<template>
    <div>
        <slot>
            <a class="pl-4 text-decoration-underline" href="#" @click="toggleDialog()" text
                :disabled="modulesToShow.length <= 1">{{
                    modulesToShow.length }}
                Modules</a>
        </slot>
        <app-dialog title="Modules" :show="showDialog" @close="toggleDialog()">
            <v-list three-line>
                <template v-for="(item, index) in modulesList">
                    <v-list-item :key="item.code" class="pb-2" v-if="showModule(item.code)">
                        <v-list-item-content>
                            <v-list-item-title class="primary-color">{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.shortDescription }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="text-subtitle">{{ item.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="py-2" :key="index" v-if="showModule(item.code)"></v-divider>
                </template>
            </v-list>
        </app-dialog>
    </div>
</template>

<script>
import AppDialog from './AppDialog.vue';
import appConstants from '../utils/appConstants'
export default {
    props: {
        allowedModules: {
            type: [String, Array],
            default: ""
        }
    },
    components: { AppDialog },
    data() {
        return {
            modulesList: [],
            showDialog: false,
            modulesToShow: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (Array.isArray(this.allowedModules)) {
                this.modulesToShow = [...this.allowedModules]
            } else {
                this.modulesToShow = this.allowedModules.split(" ,").map(rec => rec.trim())
            }
            this.modulesList = await this.getItem(appConstants.MODULES_API)
        },
        toggleDialog() {
            this.showDialog = !this.showDialog
        },
        showModule(moduleName) {
            return this.modulesToShow.includes(moduleName)
        }
    },
}
</script>

<style lang="scss" scoped></style>