<template>
<v-layout>
    <v-col>
        <v-row>
            <label class="coupon-error">{{msg}}</label>
        </v-row>
        <v-row>
            <v-flex>
                <v-text-field @input="$emit('input',coupon)" v-model="coupon" label="Enter coupon code" clearable @click:clear="$emit('clear')"></v-text-field>
            </v-flex>
            <v-flex style="margin:auto 0 !important">
                <v-btn @click="applyCoupon()" :style="theme">Apply</v-btn>
            </v-flex>
        </v-row>
    </v-col>
</v-layout>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    props: ['order'],
    data() {
        return {
            coupon: '',
            discountAmount: 0
        }
    },
    methods: {
        async applyCoupon() {
            this.order.couponCode = this.coupon
            this.order.vendorId = this.$store.getters.vendor._id
            var user=this.getUserProfile()
            this.order.customer=this.convertToUserRefModel(user)
            try {
                this.msg=''
                this.discountAmount = await this.postItem(appConstants.COUPONS_API + "/applyCoupon", this.order)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
            }
            this.$emit('setAmount', this.discountAmount)
        }
    },
}
</script>

<style scoped>
.coupon-error{
    color:red;
    font-weight: 400;
}
</style>
