<template>
<v-list-item @click="$router.push('/store/customerOrder/'+order._id)" two-line class="bottom-border">
    <v-list-item-content>
        <v-layout>
            <v-flex xs4>
                <v-list-item-avatar :style="theme" size="50" tile>
                    {{date}}
                </v-list-item-avatar>
            </v-flex>
            <v-flex xs6 mt-2>
                <v-list-item-subtitle class="card-subtitle-font align-card-subtitle" style="text-decoration:underline">{{order.orderNo}}</v-list-item-subtitle>
                <v-list-item-subtitle class="card-subtitle-font">Rs {{order.total}}</v-list-item-subtitle>
            </v-flex>
            <v-flex xs3 mt-2>
                <v-list-item-title class="align-card-chip">
                    <order-status :status="order.currentStatus"></order-status>
                </v-list-item-title>
            </v-flex>
        </v-layout>
    </v-list-item-content>
</v-list-item>
</template>

<script>
import moment from 'moment'
import DownloadButton from '@/components/DownloadButton'
import appConstants from "@/utils/appConstants";
export default {
    props: ["order"],
    components: {
        DownloadButton,
    },
    data() {
        return {
            date: '',
            year: '',
            url:appConstants.GENERATE_WEBSTORE_INVOICE
        }
    },
    mounted() {
        this.initcomponent();
    },
    methods: {
        initcomponent() {
            this.date = moment(this.order.date).format('DD-MMM-YY')
            this.year = moment(this.order.date).format('YYYY')
        },
    },
};
</script>

<style scoped>
.align-card-chip {
    margin-top: 12px;
}
.align-card-subtitle {
    margin-top: 14px;
}
.bottom-border{
    border-bottom: 1px solid gray;
}
</style>
