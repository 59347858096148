<template>
<v-flex>
    <new-store-titlebar v-if="$vuetify.breakpoint.smAndUp&&$store.getters.vendor.storeUI==newStoreUI"></new-store-titlebar>
    <old-store-titlebar v-else-if="!$store.getters.vendor.storeUI||$store.getters.vendor.storeUI==oldStoreUI" dense></old-store-titlebar>
    <router-view></router-view>
    <new-footer v-if="$vuetify.breakpoint.smAndUp&&$store.getters.vendor.storeUI==newStoreUI"></new-footer>
    <app-footer v-else-if="!$store.getters.vendor.storeUI||$store.getters.vendor.storeUI==oldStoreUI"></app-footer>
    <bottom-navbar v-if="this.$vuetify.breakpoint.xsOnly&&this.$store.getters.vendor.storeUI==this.newStoreUI&&showBottomNav"></bottom-navbar>
</v-flex>
</template>

<script>
import OldStoreTitlebar from '@/components/OldStoreTitlebar.vue';
import AppFooter from '@/components/Footer.vue'
import NewStoreTitlebar from '@/webstoreComponents/NewStoreTitlebar'
import BottomNavbar from '@/webstoreComponents/BottomNavbar'
import NewFooter from '@/webstoreComponents/NewStoreFooter'
export default {
    components: {
        OldStoreTitlebar,
        AppFooter,
        NewStoreTitlebar,
        BottomNavbar,
        NewFooter
    },
    data() {
        return {
            showBottomNav: true
        }
    },
    beforeRouteUpdate(to, from, next) {
       /*  if(to.path.includes(('/store/CategoryAndProducts/')))
            this.showBottomNav=false
        else */
            this.showBottomNav=true
        next()
    },
}
</script>
<style scoped>
</style>
