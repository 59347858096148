<template>
    <span>
        <slot :createOrder="createOrder">
            <v-btn :v-bind="$attrs" :class="cssClass" class="ma-1" @click="createOrder()">{{ label }}</v-btn>
        </slot>
    </span>
</template>

<script>
import appConstants from '../utils/appConstants'
export default {
    props: {
        label: {
            type: String,
            default: "Pay Now"
        },
        cssClass: {
            type: String,
            default: 'primary-inverted'
        },
        vendorDetails: {
            type: Object,
            required: true
        },
        paymentDetails: {
            type: Object
        }
    },
    data() {
        return {}
    },
    methods: {
        async createOrder() {
            try {
                let payload = {
                    amount: this.paymentDetails.amount * 100,
                    currency: "INR",
                    receipt: this.paymentDetails.receiptNo,
                    notes: this.paymentDetails.notes
                }
                let order = await this.postItem(`${appConstants.BILLINGS_API}/orders`, payload)
                console.log(`Got ${order.id}`);
                this.collectPayment(order.id)
                this.collectPayment = false
            } catch (error) {
                this.handleError(error)
            }
        },
        collectPayment(subscriptionId) {
            console.log(`Collection ${subscriptionId}`);
            let options = {
                key: process.env.VUE_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
                amount: this.paymentDetails.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "Online Dukan", //your business name
                order_id: subscriptionId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                handler: (response) => {
                    this.handlePaymentSuccess(response)
                },
                prefill: { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
                    name: this.vendorDetails.businessName,
                    email: this.vendorDetails.email,
                    contact: this.vendorDetails.mobileNo //Provide the customer's phone number for better conversion rates 
                },
                notes: this.paymentDetails.notes
            }
            var razorPay = new window.Razorpay(options);
            razorPay.open();
            razorPay.on('payment.failed', async function (response) {
                alert(response.error.description);
            })
        },
        async handlePaymentSuccess(response) {
            this.refreshSubscription()
            this.$emit('success')
        }
    },
}
</script>

<style lang="scss" scoped></style>