<template>
<v-flex>
    <v-container>
        <alert-message v-if="msg" :error="msg"></alert-message>
        <v-alert icon="mdi-account-plus" v-if="$store.getters.vendor.rewardSetting.registrationPoint>0&&$store.getters.vendor.enableRewards" border="left" colored-border :style="themeInverted" elevation="2" dismissible>
            Register and get {{$store.getters.vendor.rewardSetting.registrationPoint}} Reward points
        </v-alert>
        <v-form ref="signupForm" lazy-validation>
            <v-row>
                <v-text-field class="mx-3" v-model="user.firstName" :rules="firstNameRules" label="FirstName*" />
                <v-text-field class="mx-3" v-model="user.middleName" label="MiddleName" />
                <v-text-field class="mx-3" v-model="user.lastName" :rules="lastNameRules" label="LastName*" />
            </v-row>
            <v-row>
                <v-text-field class="mx-3" prepend-icon="mdi-cellphone" v-model="user.mobileNo" :rules="mobileNoRules" label="Mobile Number*" />
                <v-text-field class="mx-3" prepend-icon="mdi-lock-question" v-model="user.password" :rules="passwordRules" label="Password*" type="password" />
            </v-row>
            <v-text-field prepend-icon="mdi-email" v-model="user.email" :rules="emailRules" label="Email" type="Email address" />
            <v-text-field v-model="user.referral" v-if="$store.getters.vendor.enableRewards" label="Referral Code (if any)" />
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-btn block :style="theme" @click="submit()">SignUp</v-btn>
        </v-form>
    </v-container>
</v-flex>
</template>

<script>
import appConstants from "@/utils/appConstants";
import axios from "axios";
import AlertMessage from './AlertMessage.vue';
export default {
    components: { AlertMessage },
    data() {
        return {
            user: {
                firstName: "",
                middleName: "",
                lastName: "",
                mobileNo: "",
                email: "",
                password: "",
                roles: [],
                vendorId: "",
                referral:""
            },
            business: {
                businessName: "",
                address: "",
                mobileNo: "",
                email: "",
                city: "",
                area: "",
                admin: {}
            },
            roleArr: []
        };
    },
    methods: {
        async submit() {
            if (this.$refs.signupForm.validate()) {
                try {
                    this.msg = "";
                    this.user.vendorId = this.$store.getters.vendor._id;
                    this.user = await this.postItem(
                        appConstants.CUSTOMERS_REGISTRATION_API,
                        this.user
                    );
                    alert("Registration Successfull");
                    this.$router.go("login");
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data;
                }
            }
        },
    }
};
</script>

<style scoped>
.centered-input>>>input {
    text-align: center;
    font-size: 1.5rem;
}
</style>
