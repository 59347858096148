/*
* @mixin
*/
import axios from 'axios'
import moment from 'moment'
import appConstants from "@/utils/appConstants.js";
import AWS from 'aws-sdk'
export default {
    data() {
        return {
            /**
             * All events constants
             */
            appEvents: {
                ADD: "add",
                EDIT: "edit",
                DELETE: "delete",
                CLOSE: "close",
                //used by dynamic-form component
                SUBMIT: "submit",
                SAVE: "save",
                UPDATE: "update",
                INPUT: "input",
                CHANGE: "change",
                CLEAR: "clear"
            },
            orderRewardType: {
                SLAB: "SLAB",
                PERCENT: "PERCENT",
                FLATAMOUNT: "FLATAMOUNT"
            },
            paymentStatus: {
                PAID: "PAID",
                PARTIAL: "PARTIAL",
                CREDIT: "CREDIT",
                COD: "COD"
            },
            orderStatus: {
                DRAFT_STATUS: "DRAFT",
                PENDING_STATUS: "PENDING"
            },
            formMode: {
                ADD: "ADD",
                EDIT: "EDIT"
            },
            packageModule: {
                bType: {
                    ONE_TIME: 'ONE_TIME',
                    FUTURE_BUY: 'FUTURE_BUY',
                    RECURRING: 'RECURRING'
                }
            },
            fieldRequired: [
                v => !!v || 'Field is required',
            ],
            nameRequired: [
                v => !!v || 'Name is required',
            ],
            costRequired: [
                v => !!v || 'Cost is required',
            ],
            commissionRequired: [
                v => !!v || 'Commssion is required',
            ],
            validityRequired: [
                v => !!v || 'Commssion is required',
            ],
            descRequired: [
                v => !!v || 'Description is required',
            ],
            firstNameRules: [
                v => !!v || 'FirstName is required',
            ],
            lastNameRules: [
                v => !!v || 'LastName is required',
            ],
            mobileNoRules: [
                v => !!v || "Mobile Number is required",
                v => /^[0-9]+$/.test(v) || "Mobile Number should be numeric",
                v => !!v && v.length == 10 || "Enter valid 10 digit mobile no."
            ],
            whatsappNoRules: [
                v => !v || v.length == 10 || v.length == 0 || "Enter valid 10 digit mobile no."
            ],
            emailRules: [
                v => !v || /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => v.length > 7 || 'Password should not be less than 8 digits'
            ],
            businessNameRules: [v => !!v || "Business Name required"],
            businessAddRules: [v => !!v || "Business Address is required"],
            oldPasswordRule: [
                v => v.length > 7 || "Password should be min 8 character"
            ],
            newPasswordRule: [
                v => v.length > 7 || "New Password should be min 8 character"
            ],
            titleRules: [
                v => !!v || 'Title is required',
            ],
            msg: "",
            msgType: "",
            host: process.env.VUE_APP_API_ENDPOINT,
            appVersion: process.env.VUE_APP_VERSION,
            currentPage: 1,
            audioTrack: { soundurl: require("@/assets/front-desk-bells-daniel_simon.mp3") },
            recordSize: 20,
            oldStoreUI: 'Webstore 1.0',
            newStoreUI: 'Webstore 2.0'
        }
    },
    computed: {
        theme() {
            return {
                'background-color': `${this.$store.getters.vendor != undefined && this.$store.getters.vendor.themeColor ? this.$store.getters.vendor.themeColor : 'orange'} !important`,
                'font-family': `${this.$store.getters.vendor != undefined && this.$store.getters.vendor.fontFamily ? this.$store.getters.vendor.fontFamily : ''} !important`,
                'color': `${this.$store.getters.vendor != undefined && this.$store.getters.vendor.fontColor ? this.$store.getters.vendor.fontColor : 'white'} !important`
            }
        },
        themeInverted() {
            return {
                'color': `${this.$store.getters.vendor != undefined && this.$store.getters.vendor.themeColor ? this.$store.getters.vendor.themeColor : 'orange'} !important`,
                'font-family': `${this.$store.getters.vendor != undefined && this.$store.getters.vendor.fontFamily ? this.$store.getters.vendor.fontFamily : ''} !important`,
                'background-color': 'white !important',
            }
        },
        themeSidebar() {
            return {
                'color': `${this.$store.getters.vendor != undefined && this.$store.getters.vendor.themeColor ? this.$store.getters.vendor.themeColor : 'orange'} !important`,
            }
        },
        passwordConfirmationRule() {
            if (this.newPassword != this.confirmPassword || this.confirmPassword.length != this.newPassword.length) {
                this.passwordError = true,
                    this.passwordErrorMsg = "Must match with new password"
            } else {
                this.passwordError = false,
                    this.passwordErrorMsg = ""
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.xs
        },
        isTablet() {
            return this.$vuetify.breakpoint.sm
        },
        isLaptop() {
            return this.$vuetify.breakpoint.md
        },
        isTabletAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        isLaptopAndUp() {
            return this.$vuetify.breakpoint.mdAndUp
        },
        isSuperAdmin() {
            return JSON.parse(localStorage.getItem('userProfile')).roles?.filter(rec => rec.value === 'sysadmin').length > 0
        },
        cart() {
            return this.$store.getters.cart
        },
        cartItem() {
            if (this.cart && this.cart.length > 0)
                return this.cart.length
            else
                return 0
        },
        orderCount() {
            return this.$store.getters.orderCount
        },
        orderCounter() {
            if (this.orderCount && this.orderCount != undefined)
                return this.orderCount
            else
                return 0
        },
        isLoggedIn() {
            return this.$store.state.token != ''
        },
        loggedInUserDisplayName() {
            try {
                if (this.isLoggedIn) {
                    return JSON.parse(localStorage.getItem("userProfile")).displayName
                }
            } catch (err) {
                this.$router.push("/store/login")
            }
        },
        storeName() {
            return this.$store.getters.vendor ? this.$store.getters.vendor.businessName : 'Online Dukan'
        },
        logoImage() {
            return this.$store.getters.vendor.logo ? this.host + this.$store.getters.vendor.logo.path : require('@/assets/logo.png')
        },
        defaultCategoryImage() {
            return require('@/assets/default-category.png')
        },
        defaultProductImage() {
            return require('@/assets/default-image.png')
        }
    },
    filters: {
        dateFormat: function (date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY');
            } else {
                return ""
            }
        },
        amountFormat: function (amount) {
            if (amount) {
                return amount.toFixed(2)
            }
            return 0
        },
        dateTimeFormat: function (dateTime) {
            if (dateTime)
                return moment(dateTime).format("DD/MM/YYYY hh:mm:ss A")
            else
                return ""
        },
        defaultJsonDateFormat: function (dateString) {
            if (dateString)
                return moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD")
            else
                return ""
        },
        jsonDateFormat: function (dateString) {
            if (dateString)
                return moment(dateString, "DD/MM/YYYY").format("YYYY/MM/DD")
            else
                return ""
        }
    },
    methods: {
        // Returns the value of property / nested property
        getNested(theObject, path, separator) {
            try {
                separator = separator || '.';

                return path.
                    replace('[', separator).replace(']', '').
                    split(separator).
                    reduce(
                        function (obj, property) {
                            return obj[property];
                        }, theObject
                    );

            } catch (err) {
                return undefined;
            }
        },
        commonHeader() {
            return {
                headers: {
                    'token': this.getToken(),
                    'vendorid': this.$store.getters.vendor?._id
                }
            }
        },
        async postItem(url, data) {
            var item = {}
            await axios.post(this.buildApiUrl(url), data, this.commonHeader()).then(response => {
                item = response.data
            }).catch(error => {
                this.handleSessionError(error)
            })
            return item
        },
        async getItem(url) {
            var item = []
            await axios.get(this.buildApiUrl(url), this.commonHeader()).then(response => {
                item = response.data
            }).catch(error => {
                this.handleSessionError(error)
            })
            return item
        },
        async putItem(url, data) {
            var item = {}
            await axios.put(this.buildApiUrl(url), data, this.commonHeader()).then(response => {
                item = response.data
            }).catch(error => {
                this.handleSessionError(error)
            })
            return item
        },
        async deleteItem(msg, url) {
            if (confirm(msg)) {
                try {
                    await axios.delete(this.buildApiUrl(url), this.commonHeader()).then(response => {
                        return true
                    })
                } catch (error) {
                    this.handleSessionError(error)
                }
            }
        },
        handleSessionError(error) {
            if (error.response && error.response.status == 401 && localStorage.getItem("userToken") != undefined) {
                alert("Session time out , please login again")
                this.logout()
            } else {
                throw error
            }
        },
        addVendorToLocalStorage(vendor) {
            localStorage.setItem("vendor", JSON.stringify(vendor))
        },
        handleError(error) {
            this.msg = error
            this.msgType = "error"
        },
        performAction(icon, id) {
            if (icon.name == "mdi-lock-reset") {
                this.$router.push({ name: "resetStaffPassword", params: { show: false, id: id } });
            }
            else if (icon.name == "mdi-update") {
                this.$router.push({ name: "resetStaffPassword", params: { show: true, id: id } });
            }
            else if (icon.name == "mdi-delete") {
                this.$emit('delete', id)
            }
            else
                this.$router.push(icon.path + "" + id);
        },

        /**
         * Accepts the items to add in formdata, files & filesTypeName
         * @param {*} item Item needs to add in the formData
         * @param {*} files List of files needs to add in formData
         * @param {*} fileLabel fileLabel to indicate label for files. e.g. productImages
         */
        convertToFormData(item, files, fileLabel) {
            if (!fileLabel) {
                throw new Error("Please provide fileLabel")
            }
            let formData = new FormData()
            formData.append("entity", JSON.stringify(item))
            for (let i = 0; i < files.length; i++) {
                formData.append(fileLabel, files[i])
            }
            return formData
        },
        isAllowed(permission) {
            if (this.$store.state.token != '') {
                let permissions = JSON.parse(localStorage.getItem("userProfile")).permissions
                return permissions.indexOf(permission) > -1
            }
        },
        hasAccess(permission, moduleName) {
            let hasAccessToModule = true
            if (this.$store.state.token != '') {
                if (this.isSuperAdmin) {
                    return true
                }
                if (moduleName) {
                    let modules = this.$store.getters.vendor?.licensingDetails?.allowedModules || []
                    hasAccessToModule = modules.indexOf(moduleName) > -1
                }
                let permissions = JSON.parse(localStorage.getItem("userProfile")).permissions
                let hasPermission = permissions.indexOf(permission) > -1
                return hasAccessToModule && hasPermission
            }
        },
        getToken() {
            return localStorage.getItem("userToken");
        },
        getUserProfile() {
            var profile = localStorage.getItem('userProfile')
            return profile !== null ? JSON.parse(profile) : ''
        },
        convertToRefModel(id, displayName, value) {
            return {
                _id: id,
                displayName: displayName,
                value: value
            }
        },
        convertToUserRefModel(user) {
            return {
                _id: user._id,
                displayName: user.firstName + (user.middleName ? " " + user.middleName : "") + " " + user.lastName,
                value: user.mobileNo
            }
        },
        // Check if product is present in localstorage and return if present otherwise undefined
        checkInCart(id) {
            let cartItems = this.$store.getters.cart
            if (cartItems) {
                let prod = cartItems.find(rec => rec._id == id)
                return prod
            }
            return undefined
        },
        // Add to localstorage
        addToCart(product) {
            var items = this.$store.getters.cart
            items = items ? items : []
            product.quantity = product.quantity ? product.quantity : 1;
            var index = items.findIndex(rec => rec._id == product._id);
            if (index > -1) {
                items.splice(index, 1, product);
            } else {
                items.push(product);
            }
            this.$store.dispatch("actionUpdateCart", items)
        },
        //To logout of from the app and remove localstorage values
        logout() {
            localStorage.removeItem("userProfile");
            localStorage.removeItem("userToken");
            localStorage.removeItem("vendorId");
            this.$store.state.token = "";
            this.$router.push("/store/login");
        },
        generateUrl(slug) {
            if (slug && slug.startsWith('/'))
                return window.location.origin + slug + "/store"
            else
                return window.location.origin + "/" + slug + "/store"
        },
        back() {
            this.$router.go(-1)
        },
        navigateTo(path) {
            this.$router.push(path)
        },
        convertProductToOrderItem(product, quantity) {
            var item = {}
            item._id = product._id
            item.title = product.title,
                item.price = Number(product.offerprice > 0 ? product.offerprice : product.price)
            item.brand = product.brand
            item.image = product.images ? product.images[0] : this.defaultProductImage
            item.size = product.size
            item.code = product.code
            item.quantity = quantity
            item.amount = item.price * quantity,
                item.type = product.type,
                item.bType = product.bType,
                item.products = product.products,
                item.addOns = product.addOns,
                item.swapOptions = product.swapOptions
            return item
        },
        getCurrentPage(key) {
            return Number(localStorage.getItem(key))
        },
        setCurrentPage(count, key) {
            this.currentPage = count
            localStorage.setItem(key, count)
        },
        exportToFile(data, fileName, exportType) {
            window.exportFromJSON({ data, fileName, exportType })
        },
        playSound() {
            var audio = new Audio(this.audioTrack.soundurl);
            audio.play();
        },
        async setDataInLocalStorage(res) {
            localStorage.setItem("userToken", res);
            var profile = await this.getItem(appConstants.AUTHENTICATE_API + "/profile")
            localStorage.setItem("userProfile", JSON.stringify(profile.data));
            this.$store.state.token = res;
        },
        navigateAfterLogin() {
            if (this.$store.getters.cart.length > 0) {
                this.$router.push("/store/cart");
            } else {
                this.$router.push("/store")
            }
        },
        checkDeliveryDate(date) {
            var dateArr = date.split("/")
            var deliveryDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
            if (new Date() < deliveryDate)
                return true
            else
                alert('Invalid Delivery Date');
        },
        setDeliveryDate() {
            var today = new Date()
            var tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + this.$store.getters.vendor.deliveryDays)
            return moment(new Date(tomorrow)).format('YYYY-MM-DD')
        },
        async updateVendorSetting(slug) {
            let vendor = await axios.get(this.buildApiUrl('/api/webstore/vendors/slug/' + slug))
            this.$store.dispatch("actionUpdateVendorSetting", vendor.data);
        },
        imagePath(path) {
            return path?.startsWith('http') ? path : process.env.VUE_APP_API_ENDPOINT + path
        },
        buildApiUrl(url) {
            return process.env.VUE_APP_API_ENDPOINT + url
        },
        async generateReportData(apiCall, span, startDate, endDate, startDateCleared, endDateCleared) {
            if (startDate && endDate && !startDateCleared && !endDateCleared)
                return await this.getItem(apiCall + '?span=' + span + "&startDate=" + this.$options.filters.defaultJsonDateFormat(startDate) + "&endDate=" + this.$options.filters.defaultJsonDateFormat(endDate))
            else if (!this.endDate && !this.startDateCleared)
                return await this.getItem(apiCall + '?span=' + span + "&startDate=" + this.$options.filters.defaultJsonDateFormat(startDate))
            else
                return await this.getItem(apiCall + '?span=' + span)
        },
        getDefaultWhatsappMsg() {
            return "Hi there,\nWelcome to *" + this.$store.getters.vendor.businessName + "*.\n" +
                "Now all our products are available on your mobile.\nAccess our latest product catalog"
        },
        updateApiCall(apiCall, search) {
            if (apiCall.includes('?'))
                apiCall = apiCall + '&searchText=' + search
            else
                apiCall = apiCall + '?searchText=' + search
            this.$refs.pagination.init(apiCall)
        },
        buildProductCatalogMsg() {
            let msg
            if (this.$store.getters.vendor.whatsappMsg)
                msg = this.$store.getters.vendor.whatsappMsg
            else
                msg = this.getDefaultWhatsappMsg()
            return msg + "\n" + window.location.href
        },
        showProductDetail(id) {
            this.$router.push("/store/products/" + id);
        },
        showAllProducts(categoryId, title) {
            if (title == appConstants.BEST_DEALS)
                this.$router.push('/store/categoryAndProducts/0')
            else
                this.$router.push('/store/categoryAndProducts/' + categoryId)
        },
        async updateSupplyItemStatus(item, status) {
            item.status = status
            await this.putItem("/api/suppliedItem/" + item._id, item)
        },
        convertToProductRefModel(products, type, bType) {
            for (let i = 0; i < products.length; i++) {
                products[i] = this.convertToRef(products[i], type, bType)
            }
            return products
        },
        convertToRef(item, type, bType) {
            return {
                _id: item._id,
                code: item.code,
                title: item.title,
                sellingPrice: (item.price || item.sellingPrice),
                type: type,
                bType: bType,
                swapOptions: item.swapOptions
            }
        },
        updateSwap(index, swapOptions) {
            let requiredProduct = swapOptions[0]
            swapOptions[0] = swapOptions[index + 1]
            swapOptions[index + 1] = requiredProduct
            return swapOptions
        },
        convertToProductRef(rec) {
            return {
                _id: rec._id,
                title: rec.name,
                price: rec.sellingPrice,
                images: [rec.image],
                bType: rec.bType,
                type: 'BUNDLE',
                products: rec.products,
                addOns: rec.addOns
            }
        },
        customizeConditionCheck(cart) {
            if (cart.type && cart.bType) {
                if (cart.type == 'BUNDLE' && cart.bType == 'CUSTOMIZABLE')
                    return true
                else if (cart.bType == this.packageModule.bType.ONE_TIME || cart.bType == this.packageModule.bType.FUTURE_BUY) {
                    if (cart.addOns.length > 0 || (cart.products.findIndex(rec => rec.swapOptions?.length > 0) > -1) || (cart.products.findIndex(rec => rec.addOn == true) > -1))
                        return true
                }
            }
        },
        async refreshSubscription() {
            try {
                if (this.$store.state?.vendor?.slug) {
                    let api = `/api/webstore/vendors/slug${this.$store.state.vendor.slug}`
                    let vendor = await this.getItem(api)
                    this.$store.dispatch('actionUpdateVendorSetting', vendor);
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}