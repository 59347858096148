<template>
<video :controls="controls" :muted="muted" :autoplay="autoplay" :width="width" :height="height">
    <source :src="host+src">
</video>
</template>

<script>
export default {
    props: {
        src: String,
        width: {
            type: String,
            default: () => '100%'
        },
        height: {
            type: String,
            default: () => '145'
        },
        autoplay: {
            type: Boolean,
            default:() => false
        },
        muted: {
            type: Boolean,
            default:() => true
        },
        controls: {
            type: Boolean,
            default:() => true
        }
    }
}
</script>
