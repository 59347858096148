<template>
    <v-btn @click="back()" class="ma-2" :style="theme">{{label || 'Back'}}</v-btn>
</template>

<script>
    export default {
        props:['label']
    }
</script>

<style lang="scss" scoped>

</style>