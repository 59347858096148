<template>
<div>
    <v-card class="display-block" flat>
        <h3>Modify Brand</h3>
        <v-card-text>
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-alert dense v-if="success" type="success">Brand Added Successfully</v-alert>
            <v-form ref="form" lazy-validation>
                <v-flex>
                    <v-text-field label="Title*" :rules="titleRules" v-model="brand.title" required></v-text-field>
                    <v-checkbox v-model='brand.active' label="Active"></v-checkbox>
                </v-flex>
                <alert-message v-if="msg" :error="msg"></alert-message>
                <v-flex text-right>
                    <v-btn class="mr-4" :style="theme" :loading="loading" :disabled="loading" @click="submit()">Save</v-btn>
                    <v-btn v-if="id==0" :style="theme" class="mr-4" :loading="loading" :disabled="loading" @click="saveAndAddMore()">Submit and Add More</v-btn>
                    <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                </v-flex>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import axios from "axios";
export default {
    data() {
        return {
            brand: this.defaultBrand(),
            items: [],
            id: 0,
            image: '',
            titleRules: [
                v => !!v || 'Title is required',
            ],
            loading: false,
            redirect: true,
            success: false
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.brand.vendorId = this.getUserProfile().vendorId
            if (this.id != 0) {
                this.brand = await this.getItem(appConstants.BRANDS_API + "/" + this.id)
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true
                if (this.id == 0) {
                    this.saveItem()
                } else {
                    this.updateItem()
                }
            }
        },
        async saveItem() {
            try {
                await this.postItem(appConstants.BRANDS_API, this.brand)
                this.loading = false
                if (this.redirect)
                    this.$router.push({ name: "Brands" })
                else {
                    this.success = true
                    this.$refs.form.reset()
                    this.brand = this.defaultBrand()
                    this.brand.vendorId = this.getUserProfile().vendorId
                    this.redirect = true
                    setTimeout(() => { this.success = false }, 2000)
                }
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            }
        },
        async updateItem() {
            try {
                await this.putItem(appConstants.BRANDS_API + "/" + this.brand._id, this.brand)
                this.$router.push({ name: "Brands" })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            }
        },
        saveAndAddMore() {
            this.redirect = false
            this.submit()
        },
        defaultBrand() {
            return {
                title: "",
                active: true,
            }
        }
    },
}
</script>

<style scoped>

</style>
