<template>
    <v-navigation-drawer v-model="drawer" :clipped="isLaptopAndUp" app @input="toggleDrawer($event)">
        <v-list nav dense>
            <v-list-item-group active-class="deep-purple--text text--accent-4">
                <template v-for="menu in menus">
                    <v-list-item v-if="hasAccess(menu.permission, menu.module)" :key="menu.label" :to="menu.path">
                        <v-list-item-icon>
                            <v-icon :style="themeSidebar">{{ menu.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ menu.label }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { version as appVersion } from '@/../package.json';
export default {
    props: ['value'],
    data() {
        return {
            openSidebar: false,
            menus: [
                { label: "Dashboard", icon: "mdi-view-dashboard", path: "/app/dashboard", permission: "viewDashboard", module: "basic" },
                { label: "Vendor", icon: "mdi-account-group", path: "/app/vendors", permission: "listVendor", module: "super-admin" },
                { label: "Orders", icon: "mdi-receipt", path: "/app/orders", permission: "listOrder", module: "order" },
                { label: "Inventory", icon: "mdi-barcode-scan", path: "/app/inventory", permission: "listInventory", module: "order" },
                { label: "Supplied Items", icon: "mdi-invoice-list-outline", path: "/app/suppliedItems", permission: "listSuppliedItem", module: "order" },
                { label: "Products", icon: "mdi-cube", path: "/app/products", permission: "listProduct", module: "basic" },
                { label: "Packages", icon: "mdi-package", path: "/app/packages", permission: "listPackage", module: "package" },
                { label: "Categories", icon: "mdi-group", path: "/app/categories", permission: "listCategory", module: "basic" },
                { label: "Brands", icon: "mdi-braille", path: "/app/brands", permission: "listBrand", module: "basic" },
                { label: "Coupons", icon: "mdi-sale", path: "/app/coupons", permission: "listCoupon", module: "coupon" },
                { label: "Customer", icon: "mdi-account-multiple", path: "/app/customers", permission: "listCustomer", module: "user" },
                { label: "Staff", icon: "mdi-account", path: "/app/staffs", permission: "listStaff", module: "basic" },
                { label: "Roles", icon: "mdi-smart-card-outline", path: "/app/roles", permission: "listRole", module: "user" },
                { label: "Expenses", icon: "mdi-receipt", path: "/app/expenses", permission: "listExpenses", module: "expense-manager" },
                { label: "Settings", icon: "mdi-cog-outline", path: "/app/settings", permission: "viewVendorSetting", module: "basic" },
                { label: "Reports", icon: "mdi-file-chart", path: "/app/reports", permission: "viewVendorSetting", module: "basic" },
                { label: "Subscription", icon: "mdi-playlist-check", path: "/app/subscription", permission: "viewVendorSetting", module: "basic" },
                { label: "Plans", icon: "mdi-playlist-check", path: "/app/plans", permission: "viewPlan", module: "super-admin" },
                { label: "Webstore", icon: "mdi-store", path: "/store/", permission: "viewStore", module: "basic" },
                { label: "v " + appVersion, permission: "viewDashboard", module: "" }
            ]
        }
    },
    computed: {
        drawer: {
            get() {
                this.openSidebar = this.value
                return this.openSidebar
            },
            set(newValue) {
                this.openSidebar = newValue
            }
        }
    },
    methods: {
        toggleDrawer(event) {
            this.drawer = event
            this.$emit('input', event)
        }
    }
}
</script>

<style scoped></style>
