<template>
<v-flex>
    <p class="title">Select Payment Option</p>
    <v-radio-group mandatory v-model="paymentType">
        <v-flex v-for="(paymentFlag,item) in paymentType" :key="item">
            <v-radio v-if="paymentFlag" :key="item" :label="item=='online'?'Pay Now':'Cash on Delivery'" :value="item"></v-radio>
        </v-flex>
    </v-radio-group>
</v-flex>
</template>

<script>
export default {
    props: ["type"],
    computed: {
        paymentType: {
            get() {
                return this.type
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        }
    },
}
</script>
