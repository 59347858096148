<template>
<v-timeline dense clipped>
    <span v-for="statusLog in orderStatusLog" :key="statusLog.date">
        <v-timeline-item v-if="statusLog.status==currentStatus" class="mb-4" :color="themeInverted.color">
            <v-col>
                <v-row :style="themeInverted" v-text="statusLog.status"></v-row>
                <v-row v-text="statusLog.comment"></v-row>
                <v-row>{{statusLog.time|dateTimeFormat}}</v-row>
            </v-col>
        </v-timeline-item>
        <v-timeline-item v-else class="mb-4" color="grey" small>
            <v-col>
                <v-row v-text="statusLog.status"></v-row>
                <v-row v-text="statusLog.comment"></v-row>
                <v-row>{{statusLog.time|dateTimeFormat}}</v-row>
            </v-col>
        </v-timeline-item>
    </span>
</v-timeline>
</template>

<script>
export default {
    props: ['orderStatusLog','currentStatus']
}
</script>
