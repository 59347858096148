<template>
<v-flex style="padding: 1%">
    <v-card flat @click="showProduct(category._id)" :class="$vuetify.breakpoint.smAndUp?'mx-auto mt-6 card-border':'mx-auto mt-2 card-border'" :width="$vuetify.breakpoint.smAndUp?'250':'90'" :height="$vuetify.breakpoint.smAndDown?'100':'190'">
        <v-flex text-center class="ma-2">
            <v-img :src="category.image? imagePath(category.image.path): defaultCategoryImage" :height="isMobile ? '80px' : '170px'" width="98%">
                <v-flex text-center text-bottom width="10" class='category-title'>{{ category.title }}</v-flex>
            </v-img>
        </v-flex>
    </v-card>
</v-flex>
</template>

<script>
export default {
    props: ["item"],
    computed: {
        category() {
            if (this.item == undefined) {
                return this.defaultCategory;
            }
            return this.item;
        },
    },
    data() {
        return {
            defaultCategory: {
                id: "a",
                title: "Category Name",
                image: this.defaultCategoryImage,
            },
        };
    },
    methods: {
        showProduct(category) {
            this.$router.push("/store/CategoryAndProducts/" + category);
            
        },
    },
};
</script>

<style scoped>
.category-title {
    background: white;
    margin-left: 15%;
    margin-top: 55%;
    padding: 2%;
    width: 70%;
    font-display: center;
    border-radius: 20px;
}

@media screen and (max-width: 600px) {
    .category-title {
        background: white;
        margin-left: 10%;
        margin-top: 70%;
        font-size: 0.6rem;
        width: 80%;
        font-display: center;
        border-radius: 20px;
    }
}

.category-image {
    border-radius: 40px;
}

.card-border {
    border: 1px solid #d4d4d4
}
</style>
