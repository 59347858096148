<template>
    <v-btn fab v-if="show" class="ma-1" :title="title" :style="theme" x-small @click="onClick()">
        <v-icon>{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: ["icon", "show", "title"],
    methods: {
        onClick() {
            this.$emit("click");
        },
    },
};
</script>
