<template>
    <v-snackbar :timeout="-1" :value="show && !restrictedPaths" elevation="24">
        <div>
            <v-icon>mdi-bell</v-icon> Would you like to receive Notification?
        </div>
        <template v-slot:action>
            <v-btn :x-small="isMobile" :small="!isMobile" class="mr-2 primary-inverted" :loading="loading"
                @click="enableNotification">Enable</v-btn>
            <v-btn outlined :x-small="isMobile" :small="!isMobile" @click="show = false">Not Now</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import appConstants from '../utils/appConstants';
export default {
    data() {
        return {
            show: true,
            loading: false
        }
    },
    async mounted() {
        let deviceId = localStorage.getItem(appConstants.LS_DEVICE_ID)
        let deviceRegistered = false
        if (deviceId) {
            deviceRegistered = (await this.postItem(appConstants.WEBSTORE_IS_DEVICE_REGISTERED, {
                userId: this.$store.getters?.user?._id,
                deviceId: deviceId,
                vendorId: this.$store.getters?.vendor?._id
            }))?.registered
        } else {
            deviceRegistered = false
        }
        this.show = !["granted", "denied"].includes(Notification.permission) && !deviceRegistered
    },

    computed: {
        restrictedPaths() {
            return ['/registration', '/error', '/', ''].includes(window.location.pathname)
        }
    },
    methods: {
        async enableNotification() {
            try {
                this.loading = true
                //$enableNotification is configured in fcm.plugin.js
                let currentToken = await this.$getDeviceId()
                //console.log(`Current tone: ${currentToken}`)
                await this.postItem(appConstants.WEBSTORE_REGISTER_DEVICE, {
                    userId: this.$store.getters?.user?._id,
                    deviceId: currentToken,
                    vendorId: this.$store.getters?.vendor?._id
                })
                localStorage.setItem(appConstants.LS_DEVICE_ID, currentToken)
                this.show = false
            } catch (error) {
                console.log('failed', error);
                this.show = false
            } finally {
                this.loading = false
            }
        }
    },
}
</script>

<style lang="scss" scoped></style>