<template>
<div>
    <v-checkbox :label="label" :value="code"></v-checkbox>
</div>
</template>

<script>
export default {
    props: ['label', 'code']
}
</script>

<style scoped>
.v-input__slot{
    margin-bottom: 0 !important;
}
.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
</style>
