<template>
    <v-btn :small="small" :block="block" :style="theme" class="float-right my-4" :href="urlEncodedMsg" target="_blank">{{block?'Share':''}}<v-icon>mdi-share-variant</v-icon></v-btn>
</template>

<script>
    export default {
        props: {
            whatasppProductMsg: {
                type: String,
                default: ''
            },
            small:{
                type:Boolean,
                default:false
            },
            block:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            urlEncodedMsg() {
                return encodeURI("https://wa.me/?text="+this.whatasppProductMsg)
            }
        },
    }
</script>