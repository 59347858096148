<template>
<v-card class="ma-2">
    <v-list-item three-line @click="$router.push(url)">
        <v-list-item-content class="justify-center">
            <v-card-title class="widget-title justify-center ">{{value}}</v-card-title>
            <v-icon class="widget-icon">{{icon}}</v-icon>
            <v-card-subtitle class="widget-subtitle">{{title}}</v-card-subtitle>
        </v-list-item-content>
    </v-list-item>
</v-card>
</template>

<script>
export default {
    props: {
        icon: '',
        title: '',
        value: '',
        url:''
    },
}
</script>

<style scoped>
.widget-subtitle {
    text-align: center;
    font-size: 1rem;
    padding-top: 0.3rem !important;
}
.widget-icon {
    font-size: 34px;
}
.widget-title {
    font-size: 3rem
}

@media screen and (max-width:600px) {
    .widget-subtitle{
        text-align: center;
        font-size: 0.8rem
    }
    .widget-icon {
        font-size: 25px;
    }
    .widget-title {
        font-size: 1.6rem
    }
}
</style>
