<template>
<v-alert v-if="errorType&&errorType.errorCode=='VALIDATION_ERROR'" type="error" dismissible>
    <template v-if="errorType.details.errors">
        <ul v-for="item in errorType.details.errors" :key="item.errors">
            <li v-for="rec in item" :key="rec">{{rec}}</li>
        </ul>
    </template>
    <template v-else-if="errorType.details">
        {{errorType.details}}
    </template>
</v-alert>
<v-alert v-else-if="errorType" type="error" dismissible>
    {{errorType.details}}
</v-alert>
</template>

<script>
export default {
    props: ["error"],
    computed: {
        errorType(){
            return this.error['data']?this.error['data']:this.error
        }
    },
}
</script>
