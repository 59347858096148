<template>
<v-flex v-if="category">
    <v-img class="category-image" :style="colorFilter" height="140" width="180" :src="category.image? imagePath(category.image.path): defaultCategoryImage">
        <v-flex class="blur" :style="colorFilter">
            <v-flex class='category-title' ><label>{{ category.title }}</label></v-flex>
            <v-flex class='veiw-all-title' @click="showAllProducts(category._id,category.title)"><label> VIEW ALL</label> <label class="veiw-all-symbol">-></label></v-flex>
        </v-flex>
    </v-img>
</v-flex>
</template>

<script>
export default {
    props: ["category"],
    computed: {
        colorFilter() {
            return {'filter': 'drop-shadow(5px 5px 5px '+ `${this.$store.getters.vendor!=undefined&&this.$store.getters.vendor.themeColor?this.$store.getters.vendor.themeColor:'orange'}) !important`}
        },
    },
};
</script>

<style scoped>
.category-image {
    border-radius: 10px;
    border: 2px solid #d4d4d4;
}

.blur {
    height: 100%;
    backdrop-filter: brightness(55%);
    
    
}

.category-title {
    font-size: 1.8rem;
    padding-top: 3%;
    margin-left: 5%;
    color: white;
}

.veiw-all-title {
    margin-top: 15%;
    margin-left: 7%;
    font-size: 1.0rem;
    color: white;
}

.veiw-all-symbol {
    margin-left: 30%;
    font-size: 2rem;
    font-weight: bold;
}
</style>
