<template>
    <v-text-field v-model="searchText" @keyup.enter="search()" @click:prepend-inner="$router.go(-1)" :prepend-inner-icon="goBack?'mdi-arrow-left':''" @click:append="search()" light placeholder=" Find Products by name" rounded solo append-icon="mdi-magnify" dense clearable></v-text-field>
</template>

<script>
    export default {
        props: {
            goBack: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                searchText: ''
            }
        },
        methods: {
            search() {
                let currentPath=window.location.href.replace(window.location.origin+this.$store.getters.vendor.slug,'')
                if(currentPath.includes('/store/categoryAndProducts/')&&this.$vuetify.breakpoint.xsOnly){
                    this.$emit("input",this.searchText)
                }
                else
                    this.$router.push('/store/categoryAndProducts/searchText='+this.searchText)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>