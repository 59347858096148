<template>
<div>
    <app-dialog :show="show" width='50%' @close="show=!show">
        <h2 class="align-center">Products / Bundle Items</h2>
        <v-card flat>
            <v-list three-line class="ma-2" sm12 xs12 v-if="packageItem.products?.length > 0">
                <template v-for="(product, index) in packageItem.products">
                    <v-list-item :key="product._id" @click="$emit('update',index)">
                        <v-list-item-avatar rounded="0">
                            <v-img :src="defaultProductImage" height="32px" width="32px"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title @click="openDetail=true">{{ product.title }}</v-list-item-title>
                            <cart-product-details v-if="product?.swapOptions?.length>0" :showDialog="openDetail" @close="openDetail=false" :product="product"/>
                            <v-list-item-subtitle><label class="card-subtitle-font">Rs. </label>
                                <label class="card-title-font">{{ product.price || product.sellingPrice}}</label>
                            </v-list-item-subtitle>
                            </v-list-item-content>
                        <v-list-item-icon v-if="product.swapOptions?.length>0 && product.swapOptions[0]?.bType!='FIXED'">
                            <label @click="selectSwap(product)" class="link h4 text-decoration-underline">Customize</label>
                        </v-list-item-icon>
                        <v-list-item-icon v-if="index>oldProductsLength-1">
                            <v-icon @click="removeProduct(index)">mdi-close</v-icon>
                        </v-list-item-icon>
                        <cart-swap-pop-up v-if="product.swapOptions?.length>0" @update="updateProduct($event)" @close="showSwap=false" :showDialog="showSwap" :products="selectedProducts"></cart-swap-pop-up>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                </template>
            </v-list>
        </v-card>
        <h2 v-if="packageItem.addOns?.length>0" class="align-center">Select Add Ons</h2>
        <v-card flat>
            <v-list three-line class="ma-2" sm12 xs12 v-if="packageItem.addOns?.length > 0">
                <template v-for="(product, index) in packageItem.addOns">
                    <v-list-item :key="product._id" @click="$emit('update',index)">
                        <v-list-item-avatar rounded="0">
                            <v-img :src="defaultProductImage" height="32px" width="32px"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ product.title }}</v-list-item-title>
                            <v-list-item-subtitle><label class="card-subtitle-font">Rs. </label>
                                <label class="card-title-font">{{ product.price || product.sellingPrice}}</label>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon @click="addProduct(index)">mdi-plus</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                </template>
            </v-list>
        </v-card>
        <v-col class="text-right">
            <v-btn :style="themeInverted" outlined class="mx-2" @click="$emit('close')">Cancel</v-btn>
            <v-btn :style="theme" @click="$emit('close')">Save</v-btn>
        </v-col>
    </app-dialog>
</div>
</template>

<script>
import CartSwapPopUp from '@/components/CartSwapPopUp.vue'
import CartProductDetails from '@/components/CartProductDetails.vue';
export default {
    props: {
        packageItem: {
            type: Object,
            default: () => {}
        },
        showPackageDialog: {
            type: Boolean,
            default: () => false
        }
    },
    components: {
        CartSwapPopUp,
        CartProductDetails
    },
    computed: {
        show: {
            get() {
                return this.showPackageDialog
            },
            set(newValue) {
                this.$emit('close')
            }
        }
    },
    mounted() {
        this.initComponent();
    },
    data() {
        return {
            oldProductsLength: 0,
            showSwap: false,
            selectedProducts:[],
            openDetail:false
        }
    },
    methods: {
        addProduct(index) {
            this.packageItem.addOns[index]['addOn']=true
            this.packageItem.products.push(this.packageItem.addOns[index])
            this.packageItem.price+=this.packageItem.addOns[index].sellingPrice
            this.packageItem.addOns.splice(index, 1)
        },
        removeProduct(index) {
            this.packageItem.addOns.push(this.packageItem.products[index])
            this.packageItem.price-=this.packageItem.products[index].sellingPrice
            this.packageItem.products.splice(index, 1)
        },
        initComponent() {
            this.oldProductsLength = this.packageItem.products ?.length
        },
        updateProduct(swapIndex){
            let index=this.packageItem.products.findIndex(rec=>rec.swapOptions==this.selectedProducts)
            this.packageItem.products[index].swapOptions=this.updateSwap(swapIndex,this.packageItem.products[index].swapOptions)
            this.showSwap=false
        },
        selectSwap(product){
            this.selectedProducts=product.swapOptions
            this.showSwap=true
        }
    },
}
</script>

<style lang="scss" scoped>

    </style>
