<template>
<v-layout>
    <v-flex xs3>
        <v-col>
            <v-avatar :style="theme" class="align-avatar">
                {{name[0]}}
            </v-avatar>
        </v-col>
    </v-flex>
    <v-flex xs6>
        <v-col class="align-card-title">
            <v-row>
                <label class="card-title-font" v-text="name"></label>
            </v-row>
            <v-row>
                <label class="card-subtitle-font" v-text="email"></label>
            </v-row>
            <v-row>
                <label class="card-subtitle-font" v-text="mobileNo"></label>
            </v-row>
            <v-row v-if="slug">
                <v-list-item-title class="card-subtitle-font" v-text="slug"></v-list-item-title>
            </v-row>
        </v-col>
    </v-flex>
    <v-flex>
        <v-col class="align-card-subtitle">
            <v-row v-if="roles">
                <label v-for="role in roles" :key="role.displayName">
                    <v-chip small pill>{{role.displayName}}</v-chip>
                </label>
            </v-row>
        </v-col>
    </v-flex>
    <v-menu left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, index) in icons" :key="index">
                <v-btn fab dark x-small class="ma-2" :style="theme">
                    <v-icon :show="isAllowed(item.permission)" @click="performAction(item,id)">{{ item.name }}</v-icon>
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</v-layout>
</template>

<script>
export default {
    props: ["id", "name", "email", "mobileNo", "icons", "slug", "roles"],
};
</script>

<style scoped>
.align-avatar {
    margin-left: 5px !important;
    margin-top: 10px !important;
}

.align-card-subtitle {
    margin-top: 20px;
}

.align-card-title{
    margin-top: 5px;
    margin-left: -10px
}
</style>
