<template>
<v-layout wrap>
    <v-flex xs12 md3 class="align-start mx-3">
        <v-select label="Discount type" v-model="type" :items="items" @change="resetValues()"></v-select>
    </v-flex>
    <v-flex xs12 md3 class="align-start mt-4 ml-3">
        <v-text-field dense v-model.number="discountAmount" type="number"/>
    </v-flex>
</v-layout>
</template>

<script>
export default {
    props: ['subTotal', 'value'],
    data() {
        return {
            items: ['%', 'Amount'],
            discount: {
                type: '%',
                percent: 0,
                amount: 0
            }
        }
    },
    computed: {
        discountAmount: {
            get() {
                if(this.value.percent>0)
                    return this.value.percent
                else
                    return this.value.amount
            },
            set(newValue) {
                if(this.type=='%'){
                    this.discount.amount=((this.subTotal*newValue)/100)
                    this.discount.percent=newValue
                }
                else
                    this.discount.amount=newValue
                this.$emit('input', this.discount)
            }
        },
        type: {
            get() {
                if(this.value.type)
                    return this.value.type
                else
                    return '%'
            },
            set(newValue){
                this.discount.type=newValue
                this.$emit('input', this.discount)
            }
        }
    },
    methods: {
        resetValues() {
            this.discount.percent = 0
            this.discount.amount = 0
        }
    },
}
</script>
