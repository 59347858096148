<template>
<v-dialog v-model="show" :width="width" :fullscreen="isMobile" scrollable max-height="600px" persistent>
    <v-card>
        <v-card-title>
            <!-- @slot This slot provide basic title and close functionality to dialog box -->
            <slot name="title">
                <v-layout>
                    <v-flex xs10>
                        <v-card-title>{{title}}</v-card-title>
                    </v-flex>
                    <v-flex xs2 class="mt-3" text-right>
                        <v-btn @click="$emit(appEvents.CLOSE)" icon text>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </slot>
        </v-card-title>
        <v-card-text>
            <!-- @slot Default slot where you can provide customized content -->
            <slot></slot>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
/** 
 * This is application dialog box. 
 * This is global component can be used with `<app-dialog></app-dialog>`
 **/
export default {
    props: {
        /**
         * This flag is use to control the visibility of the dialog box
         */
        show: {
            type: Boolean,
            required: true,
            default: () => false
        },
        /**
         * Provide title to dialog box
         */
        title: {
            type: String,
            required: false
        },
        width:{
            type: String,
            default: () => "600"
        }

    }
};
</script>