<template>
<v-flex>
    <v-flex class="ma-2" v-if="pickupPoints.length>0">
        <p class="title">Select Pickup Point</p>
        <v-radio-group @change="submitPickup()" v-model="selectedPickup">
            <v-radio v-for="item in pickupPoints" :key="item.name" :value="item.address">
                <template slot="label">
                    <v-layout wrap>
                        <v-flex sm12 xs12><label class="subtitle-1">{{item.name}}</label></v-flex>
                        <v-flex sm12 xs12>
                            <v-icon small>mdi-map-marker</v-icon>{{item.address}}
                        </v-flex>
                    </v-layout>
                </template>
            </v-radio>
        </v-radio-group>
    </v-flex>
</v-flex>
</template>

<script>
export default {
    data() {
        return {
            pickupPoints: [],
            selectedPickup: ''
        }
    },
    mounted() {
        this.pickupPoints = this.$store.getters.vendor.pickupPoints
    },
    methods: {
        submitPickup() {
            this.$emit(this.appEvents.UPDATE, this.selectedPickup)
        }
    },
}
</script>
