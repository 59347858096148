<template>
<v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y> <template v-slot:activator="{ on }">
        <v-text-field @click:clear="$emit(appEvents.CLEAR)" clearable v-model="computedDateFormatted" :label="label||'Delivery Date'" persistent-hint prepend-icon="mdi-calendar-range" v-on="on"></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
</v-menu>
</template>

<script>
export default {
    props: ["label","value"],
    data: vm => ({
        date: null,
        menu1: false,
    }),
    computed: {
        computedDateFormatted: {
            get() {
                var updatedDate = this.formatDate(this.date ? this.date : this.value);
                this.$emit(this.appEvents.INPUT, updatedDate);
                return updatedDate;
            },
            set(newValue) {
                this.date = this.parseDate(newValue);
            }
        }
    },
    methods: {
        formatDate(date) {
            if (!date) date = new Date().toISOString().substr(0, 10);
            if (date.split("-").length == 3) {
                const [year, month, day] = date.split("-");
                return `${day}/${month}/${year}`;
            } else {
                return date;
            }
        },
        parseDate(date) {
            if (!date) return null;
            if (date.split("/").length == 3) {
                const [day, month, year] = date.split("/");
                return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
            } else {
                return date
            }
        }
    }
};
</script>
