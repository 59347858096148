<template>
<div>
    <v-flex v-if="$store.getters.vendor.showBanner">
        <center>
            <web-banner :images="$store.getters.vendor.bannerImages"></web-banner>
        </center>
    </v-flex>
    <v-flex :class="$vuetify.breakpoint.xsOnly?'mx-6':'mx-10'">
        <label v-if="coupons.length>0" class="pl-6 title">Offers</label>
        <slider :couponArray="coupons"></slider>
    </v-flex>
    <whatsapp-chat></whatsapp-chat>
    <product-card-list v-if="dealItems.length>0" :category="dealInfo" :products="dealItems" title="Best Deals"></product-card-list>
    <v-flex xs12 text-right v-if="dealItems.length>0||category.length>0" :class="$vuetify.breakpoint.smAndUp?'mx-12':'mx-4'">
        <whatsapp-share :whatasppMsg="buildProductCatalogMsg()"></whatsapp-share>
    </v-flex>
    <label class="mx-12 title" v-if="$vuetify.breakpoint.xsOnly">Categories</label>
    <v-layout wrap justify-center>
        <v-flex :class="$vuetify.breakpoint.smAndUp?'mx-n8':'mx-n5'" sm3 xs4 v-for="item in category" :key="item._id">
            <category-card :item="item"></category-card>
        </v-flex>
    </v-layout>
    <v-flex v-for="(item,index) in category" :key="index">
        <v-flex>
            <product-card-list :products=[] :categoryId="item._id" :title="item.title" :category="item" :showAll="true"></product-card-list>
        </v-flex>
    </v-flex>
    <v-flex v-if="category.length==0" class="noContent" text-center>
        <h1>No Products found</h1>
    </v-flex>
    <v-flex>
        <new-cart-bar :bottomMargin="true" v-if="$vuetify.breakpoint.xsOnly"></new-cart-bar>
    </v-flex>
</div>
</template>

<script>
import ProductCardList from '@/webstoreComponents/ProductCardList'
import appConstants from '@/utils/appConstants'
import WebBanner from '@/webstoreComponents/WebBanner'
import CategoryCard from '@/webstoreComponents/CategoryCardWeb'
import NewCartBar from '@/webstoreComponents/NewCartBarMobile.vue';
import Slider from '@/components/Slider.vue'
import WhatsappChat from '@/components/WhatsappChat.vue'
export default {
    components: {
        ProductCardList,
        WebBanner,
        CategoryCard,
        NewCartBar,
        Slider,
        WhatsappChat
    },
    data() {
        return {
            dealItems: [],
            category: [],
            coupons: [],
            dealInfo:{
                categoryId:0,
                title:appConstants.BEST_DEALS,
                image:{
                    path:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIA0qvks3G2TsjzfsjuK9w3WjxFWCuvaf6TA&usqp=CAU"
                }
               
            }
        }
    },
    created() {
        if (performance.navigation.type == 1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        this.$store.subscribeAction((action, state) => {
            this.refresh = action.type == 'actionUpdateVendor'
        })
        if (!this.refresh)
            this.initComponent();
    },
    methods: {
        async initComponent() {
            this.coupons = await this.getItem(appConstants.WEBSTORE_COUPONS)
            this.dealItems = await this.getItem(appConstants.WEBSTORE_PRODUCTS + '/bestDeals')
            this.category = await this.getItem(appConstants.WEBSTORE_CATEGORIES + "/parentCategories")
            this.category.push({_id:'1',title:'Bundle and Packages'})
        }
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 24px;
}
</style>
