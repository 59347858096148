<template>
<v-card height="600">
    <v-flex text-center><h2>Vendor Login</h2></v-flex>
    <v-card-text>
        <v-tabs-items v-model="model">
            <v-tab-item value="login">
                <v-flex offset-sm-4 sm4>
                    <login-card userType='vendor' />
                </v-flex>
            </v-tab-item>
        </v-tabs-items>
    </v-card-text>
</v-card>
</template>

<script>
import LoginCard from '@/components/LoginCard.vue'
export default {
    components: {
        LoginCard,
    },
    data() {
        return {
            model: 'login',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        }
    },
}
</script>

<style scoped>

</style>
