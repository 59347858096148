<template>
<div class="my-12">
    <v-flex mx-12 sm3 v-if="categoryProducts.length>0||products.length>0" class="bottom-border">
        <label class="categoryTitle">{{title}}</label>
        <v-btn small text @click="showAllProducts(categoryId,title)">(View All)</v-btn>
    </v-flex>
    <v-flex :class="$vuetify.breakpoint.smAndUp?'mx-12':'mx-4'" v-if="$vuetify.breakpoint.smAndUp||categoryId">
        <v-layout wrap v-if="$vuetify.breakpoint.xsOnly">
            <v-layout wrap v-if="$store.getters.vendor.productCardMobile==1">
                <v-flex xs12 v-for="(item,index) in categoryId?categoryProducts:products" :key="index">
                    <product-card-mobile :product="item"></product-card-mobile><v-divider/>
                </v-flex>
            </v-layout>
            <v-layout wrap v-else-if="$store.getters.vendor.productCardMobile==2">
                <v-flex my-2 xs6 sm2 v-for="(item,index) in categoryId?categoryProducts:products" :key="index">
                    <product-card-web :item="item"></product-card-web>
                </v-flex>
            </v-layout>
        </v-layout>
        <horizontal-scroll v-else>
            <v-flex my-2 xs6 sm2 v-for="(item,index) in categoryId?categoryProducts:products" :key="index">
                <product-card-web :item="item"></product-card-web>
            </v-flex>
        </horizontal-scroll>
    </v-flex>
    <v-layout v-else wrap>
        <v-layout wrap v-if="$store.getters.vendor.productCardMobile==1">
            <v-flex offset-xs1 xs10 v-for="(item,index) in products" :key="index">
                <span v-if="index<5">
                    <product-card-mobile :product="item"></product-card-mobile>
                </span>
            </v-flex>
        </v-layout>
        <v-layout v-else-if="$store.getters.vendor.productCardMobile==2" wrap>
            <v-flex xs6 sm2 v-for="(item,index) in products" :key="index">
                <span v-if="index<5">
                    <product-card-web :item="item"></product-card-web>
                </span>
            </v-flex>
        </v-layout>
    </v-layout>
</div>
</template>

<script>
import ProductCardWeb from '@/webstoreComponents/ProductCardWeb'
import appConstants from '@/utils/appConstants'
import HorizontalScroll from '@/webstoreComponents/HorizontalScroll.vue'
import ProductCardMobile from '@/webstoreComponents/ProductCardMobile'
import CategoryCardWithBackground from '@/webstoreComponents/CategoryCardWithBackground'
export default {
    props: {
        products: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: ''
        },
        categoryId: {
            type: String,
            default: ''
        },
        category: {
            type: Object
        }
    },
    components: {
        HorizontalScroll,
        ProductCardMobile,
        ProductCardWeb,
        CategoryCardWithBackground
    },
    data() {
        return {
            categoryProducts: []
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            if (this.categoryId) {
                var productList = await this.getItem(appConstants.WEBSTORE_PRODUCTS + "/category/" + this.categoryId)
                this.categoryProducts = productList.data
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.categoryTitle {
    font-size: 22px;
    font-weight: 500;
}
@media only screen and (max-width: 500px) {
   .categoryTitle {
    font-size: 18px;
    font-weight: 600;
}
.bottom-border{
    border-bottom: 1px solid #d4d4d4;
}
}
</style>
