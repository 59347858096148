<template>
<v-list-item class="py-3">
    <v-list-item-content>
        <v-layout wrap>
            <v-flex>
                <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                <v-list-item-title class="body-1" v-text="item.title"></v-list-item-title>
                <label class="body-2">{{item.quantity}}</label>
                <v-list-item-subtitle class="body-2">Rs. {{item.price}}/-</v-list-item-subtitle>
            </v-flex>
        </v-layout>
    </v-list-item-content>    
    <v-list-item-icon>
        <v-layout column>
            <v-flex text-right v-if="edit">
                <v-icon small @click="$emit('deleteItem')">mdi-delete</v-icon>
            </v-flex>
            <v-flex>
                <v-layout column>
                    <v-list-item-subtitle style="text-align:right">Amount</v-list-item-subtitle>
                    <label class="price-style">Rs. {{item.price*item.quantity|amountFormat}}/-</label>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-list-item-icon>
</v-list-item>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
        },
        edit:{
            type:Boolean
        }
    },
}
</script>

<style scoped>
.price-style {
    font-size: 0.95rem;
}

.body-1 {
    font-size: 0.85rem !important;
    line-height: 1rem !important;
    white-space: normal
}

.body-2 {
    padding-top: 2px;
}
</style>
