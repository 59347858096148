<template>
    <v-btn :loading="loading" :disabled="loading" small :style="theme" class="mx-2" @click="downloadPdf()">
        <v-icon>mdi-download</v-icon>
        <label v-if="$vuetify.breakpoint.smAndUp">{{label}}</label>
    </v-btn>
</template>

<script>
import axios from "axios";
    export default {
        props: {
            url: {
                type: String,
                required: true
            },
            label:{
                type:String,
                default:()=>'Download'
            },
            downloadFilename:{
                type:String,
                default:()=>'Download.pdf'
            }
        },
        data() {
            return {
                loading: false
            }
        },
        methods: {
            async downloadPdf(id){
            this.loading=true
            axios.get(this.url,{responseType:'arraybuffer',headers:{'Accept':'application/octet-stream'}}).then(response=>{
                let blob=new Blob([response.data],{type:'application/pdf'})
                let link=document.createElement('a')
                this.loading=false
                link.href=window.URL.createObjectURL(blob)
                link.download=this.downloadFilename
                link.click()
            })
        }
        },
    }
</script>

<style lang="scss" scoped>

</style>