<template>
<v-card :elevation="showElevation?2:0" style="padding:1% 2%">
    <v-list-item two-line>
        <v-list-item-avatar tile size="65px">
            <v-img v-if="product.images[0]" :src="imagePath(product.images[0]?.path)"></v-img>
        </v-list-item-avatar>
        <v-list-item-content @click="showProductDetail(product._id)">
            <label class="body-1 product-title" v-text="product.title"></label>
            <label><span v-if="product.offerprice>0" class="body-2 font-weight-light"> ₹{{product.offerprice}}</span><span class="body-2 font-weight-light" :class="product.offerprice>0 ? 'strike-through ml-2':''">
                    ₹{{product.price}}</span></label>
        </v-list-item-content>
    </v-list-item>
    <v-card-actions>
        <v-layout v-if="product.stock>0||$store.getters.vendor.negativeStock||(product.bType==this.packageModule.bType.ONE_TIME||product.bType==packageModule.bType.FUTURE_BUY||product.type=='BUNDLE')" wrap>
            <v-flex text-right class="add-to-cart-button-align">
                <add-to-cart-btn v-model="product"></add-to-cart-btn>
            </v-flex>
        </v-layout>
        <v-layout column v-else>
            <v-flex text-right class="stockOut">
                Out Of Stock
            </v-flex>
        </v-layout>

    </v-card-actions>
</v-card>
</template>

<script>
import AddToCartBtn from '@/webstoreComponents/AddToCartBtn.vue';
export default {
    components: {
        AddToCartBtn,
    },
    props: ["showElevation", "product"],
};
</script>

<style scoped>
.product-title {
    white-space: break-spaces;
}

.strike-through {
    text-decoration: line-through;
    text-decoration-color: red;
}

.add-to-cart-button-align {
    margin-top: -1.5rem
}
</style>
