<template>
<v-container>
    <div class="display-block">
        <h2>
            <v-icon class="mr-2">mdi-receipt</v-icon>My Orders
        </h2>
    </div>
    <v-data-table @click:row="rowClick" :items-per-page="items.length" hide-default-footer :headers="headers" :items="items" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" width='50%' class="elevation-1" :search="search" v-if="items.length>0">
        <template v-slot:item.orderNo="{item}" v-if="$vuetify.breakpoint.smAndUp">
            <a>{{item.orderNo}}</a>
        </template>
        <template v-slot:item.date="{item}" v-if="$vuetify.breakpoint.smAndUp">
            <label>{{ item.date | dateTimeFormat }}</label>
        </template>
        <template v-slot:item.statusLog="{item}" v-if="$vuetify.breakpoint.smAndUp">
            <order-status :status="item.currentStatus"></order-status>
        </template>
        <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
            <order-card v-if="$store.getters.vendor.storeUI==newStoreUI" :order="item"></order-card>
            <customer-order-card v-else-if="$store.getters.vendor.storeUI==oldStoreUI" :order="item" @download="downloadPdf(item._id)" @updatedStatus='updateStatus($event, item._id)'></customer-order-card>
        </template>
    </v-data-table>
    <v-flex v-else text-center>
        <v-alert outlined color="grey lighten-1" icon="mdi-receipt" prominent>
            <h1>No previous order found</h1>
        </v-alert>
    </v-flex>
    <v-flex>
        <pagination :api="apiCall" v-model="items" sort="-createdAt" :apiParams="`customer=${getUserProfile()._id}`"></pagination>
    </v-flex>
</v-container>
</template>

<script>
import DownloadButton from '@/components/DownloadButton'
import ActionButton from '@/components/ActionButton'
import appConstants from "@/utils/appConstants";
import OrderCard from '@/webstoreComponents/StoreOrderCardMobile'
import axios from "axios";
export default {
    components: {
        ActionButton,
        DownloadButton,
        OrderCard
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Order Number",
                    align: "start",
                    value: "orderNo"
                },
                {
                    text: "Order Date",
                    align: "start",
                    value: "date"
                },
                {
                    text: "Customer",
                    value: "customer.displayName",
                    sortable: false
                },
                {
                    text: "Amount",
                    value: "total",
                    align: "right"
                },
                {
                    text: "Items",
                    value: "items.length",
                    align: "right",
                    sortable: this.$vuetify.breakpoint.smAndUp
                },
                {
                    text: "Payment Status",
                    value: "pStatus",
                    align: "right",
                    sortable: this.$vuetify.breakpoint.smAndUp
                },
                {
                    text: "Delivery Status",
                    value: "statusLog",
                    align: "right"
                }
            ],
            url:appConstants.GENERATE_WEBSTORE_INVOICE,
            sortBy: 'date',
            sortDesc: true,
            items: [],
            apiCall:appConstants.ORDERS_API,
            icon: {
                name: "mdi-eye",
                path: "/store/customerOrder/",
                permission: "viewOrder"
            },
            loading:{id:''}
        };
    },
    computed: {
        name() {
            return this.data
        }
    },
    methods: {
        async deleteOrder(id) {
            try {
                await this.deleteItem(
                    "Are you sure you want to delete from Order?",
                    appConstants.ORDERS_API + "/" + id
                );
                this.items.splice(this.items.indexOf(id), 1);
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data.errors;
            }
        },
        async downloadPdf(id){
            this.loading.id=id
            axios.get(appConstants.GENERATE_INVOICE+"/"+id,{responseType:'arraybuffer',headers:{'Accept':'application/octet-stream'}}).then(response=>{
                let blob=new Blob([response.data],{type:'application/pdf'})
                let link=document.createElement('a')
                this.loading.id=''
                link.href=window.URL.createObjectURL(blob)
                link.download="Order.pdf"
                link.click()
            })
        },
        rowClick(item){
             this.$router.push('/store/customerOrder/'+item._id)
        }
    }
};
</script>

<style scoped>
</style>
