export default{
    methods: {
        calculateTotal(order) {
            var amount = 0
            order.items.forEach(item => {
                amount += (item.price * item.quantity)
            });
            order.subTotal=amount
            if(order.redeemedReward>0)
                order.subTotal -= order.redeemedReward
            order.taxAmount=(order.tax*order.subTotal)/100
            if(order.discount.amount)
                order.total = order.subTotal-order.discount.amount+order.taxAmount
            else
                order.total = order.subTotal+order.taxAmount
            if(order.delivery.charge&&order.total<this.$store.getters.vendor.freeDeliveryAbove)
                order.total +=order.delivery.charge
            return order.total
        }
    },
}