<template>
<v-card>
    <v-flex text-right v-if="showClose">
        <v-btn small @click="$emit(appEvents.CLOSE)" icon text>
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-flex>
    <v-card-text>
        <v-flex class="text-center">
            <video controls muted :autoplay="false" :width="width || '100%'" :height="height || '145'">
                <source :src="host+src">
            </video>
        </v-flex>
    </v-card-text>
</v-card>
</template>

<script>
export default {
    props: ['src', 'width', 'height','showClose']
}
</script>
