<template>
<v-btn v-if="isCancelAllowed" :text="flat" @click="cancelOrder()" title="Cancel Order" class="mr-1" :style="themeInverted" outlined>CANCEL ORDER</v-btn>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        flat: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            isCancelAllowed: false
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.isCancelAllowed = await this.getItem(appConstants.IS_CANCEL_ALLOWED.replace('$id', this.id))
        },
        async cancelOrder(action) {
            if (confirm("Are you sure you want to cancel this order?")) {
                let order = await this.putItem(appConstants.ORDER_ACTION_CANCEL + "/" + this.id)
                this.isCancelAllowed = false
                this.$emit("updatedStatus", order.currentStatus)
            }
        }
    },
}
</script>

<style scoped>

</style>
