<template>
<v-flex>
    <v-app-bar :style="theme" dark app>
        <v-app-bar-nav-icon @click="drawer = !drawer" :style="theme" v-if="isLoggedIn"></v-app-bar-nav-icon>
        <v-toolbar-title class="title-padding" v-if="$vuetify.breakpoint.mdAndUp">
            <router-link class="d-flex align-center" to="/store">
                <img v-if="$store.getters.vendor.logo" class="logo" width="10%" :src="host+$store.getters.vendor.logo.path">
                <v-icon v-else x-large class="mr-2" :style="theme">mdi-handshake</v-icon>
                <label :style="theme">{{storeName}}</label>
            </router-link>
        </v-toolbar-title>
        <v-toolbar-title class="title-padding" v-if="$vuetify.breakpoint.smAndDown">
            <router-link class="d-flex align-center" to="/store">
                <v-img class="mr-2" v-if="$store.getters.vendor.logo" :src="host+$store.getters.vendor.logo.path" height="50px" width="60px"></v-img>
                <v-icon v-else large class="mr-2" :style="theme">mdi-handshake</v-icon>
                <label class="title-font wrap-word" :style="theme">{{storeName}}</label>
            </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div align="right" v-if="$vuetify.breakpoint.smAndUp">
            <v-layout align-content-end>
                <v-btn icon class="ma-2" to="/store/login">
                    <v-icon v-if="!isLoggedIn" large :style="theme">mdi-account</v-icon>
                </v-btn>
                <v-btn icon class="ma-2" to="/store/cart">
                    <v-badge :content="cartItem" :value="cartItem" color="pink" overlap>
                        <v-icon large :style="theme">mdi-cart-outline</v-icon>
                    </v-badge>
                </v-btn>
                <label v-if="isLoggedIn" class="mt-5 mr-2">Welcome {{getUserProfile().displayName}}</label>
                <v-tooltip v-if="isLoggedIn" left>
                    <template v-slot:activator="{ on }">
                        <v-icon medium v-if="isLoggedIn" class="secondary-color-inverted" v-on="on" @click="logout" :style="theme">mdi-logout</v-icon>
                    </template>
                    <span>Log Out</span>
                </v-tooltip>
            </v-layout>
        </div>
        <div align="right" v-if="$vuetify.breakpoint.xsOnly">
            <v-layout >
                <v-btn icon to="/store/login">
                    <v-icon v-if="!isLoggedIn" medium :style="theme">mdi-account</v-icon>
                </v-btn>
                <v-btn icon to="/store/cart">
                    <v-badge :content="cartItem" :value="cartItem" color="pink" overlap :style="theme">
                        <v-icon medium :style="theme">mdi-cart-outline</v-icon>
                    </v-badge>
                </v-btn>
                <v-menu v-if="isLoggedIn" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-icon medium v-if="isLoggedIn" class="secondary-color-inverted" v-on="on" :style="theme">mdi-account-circle</v-icon>
                    </template>
                    <v-list>
                        <v-list-item>
                            <label class="title-font">Welcome {{getUserProfile().displayName}}</label>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title v-on="on" @click="logout">
                                <label class="title-font">Logout </label>
                                <v-icon medium class="secondary-color-inverted">mdi-logout</v-icon>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-layout>
        </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary>
        <v-list nav dense>
            <v-list-item-group active-class="deep-purple--text text--accent-4">
                <v-list-item to="/store/customerOrder">
                    <v-list-item-icon :style="themeInverted">
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title :style="themeInverted">My Orders</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push('/store/editAccount/' + getUserProfile()._id)">
                    <v-list-item-icon :style="themeInverted">
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title :style="themeInverted">Account</v-list-item-title>
                </v-list-item>
                <v-list-item to="/app/dashboard" v-if="isAllowed('viewApp')">
                    <v-list-item-icon :style="themeInverted">
                        <v-icon>mdi-handshake</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title :style="themeInverted">Vendor Dashboard</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</v-flex>
</template>

<script>
import mixins from "@/mixins/commonFunctions.js";
export default {
    mixins: [mixins],
    data() {
        return {
            on:false,
            drawer: false,
        };
    },
    computed: {
        mobileOnly() {
            return this.isMobile();
        },
        laptopAndUp() {
            return this.isLaptopAndUp();
        },
    },
    mounted() {
        this.$store.subscribeAction((action, state) => {
            if(action.type == 'actionUpdateVendor'){
                document.title = action.payload ? action.payload.businessName : "Online Dukan"
                var favicon = document.querySelector("link[rel*='icon']")
                favicon.href = action.payload.logo ? this.host + action.payload.logo.path : require('@/assets/logo.png')
            }
        })
    }

};
</script>

<style scoped>
.title-font {
    font-size: 14px;
}
.title-padding {
    padding-left: 0% !important;
}
.wrap-word{
    white-space: normal;
}
</style>
