<template>
<v-layout wrap>
    <v-flex xs8 sm10>
        <h2>{{title}}</h2>
    </v-flex>
    <v-flex class="pa-4" xs4 sm2 v-if="isAllowed(permission)" text-right>
        <v-btn @click="$emit('click')" :style="theme" fab small>
            <v-icon>mdi-plus</v-icon>
        </v-btn> 
        <v-btn v-if="permission=='createProduct'" @click="$emit('bundleClick')" :style="theme" class="mx-2" fab small>
            <v-icon>mdi-package-variant-plus</v-icon>
        </v-btn>
    </v-flex>
    <v-flex xs12 sm12>
        <v-text-field class="search pa-4" v-model="computedSearch" label="Search here" append-icon="mdi-magnify"></v-text-field>
    </v-flex>
</v-layout>
</template>

<script>
export default {
    props: ['title', 'permission'],
    computed: {
        computedSearch: {
            get() {},
            set(newValue) {
                this.$emit("input", newValue);
            }
        }
    },
}
</script>
