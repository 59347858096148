<template>
<v-flex>
    <v-btn v-if="!alreadyInCart" block small :style="theme" @click="updateCart(1)">
        <v-icon class="mr-1">mdi-cart-outline</v-icon>Add to Cart
    </v-btn>
    <v-flex text-center v-if="alreadyInCart">
        <cart-quantity v-model="product.quantity" :min="1" :showDeleteIcon="true" @change="updateCart($event)" 
        @delete="removeFromCart(product)"></cart-quantity>
    </v-flex>
    <v-snackbar v-model="snackbar" :timeout="timeout" >
        <v-flex text-center>{{ text }}</v-flex>
        <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</v-flex>
</template>

<script>
import cartQuantity from '@/components/CartQuantity.vue';
export default {
    props: {
        value: {
            type: Object,
            required: true,
            default: () => { _id: 0 }
        },
    },
    components: {
        cartQuantity,
    },
    data() {
        return {
            product: this.value,
            snackbar: false,
            text: "Added to cart successfully",
            timeout: 2000
        }
    },
    computed: {
        alreadyInCart() {
            if (this.product) {
                return this.checkInCart(this.product._id) ? true : false
            }
            return false
        },
    },
    mounted() {
        let prod = this.checkInCart(this.value._id);
        this.product = prod ? prod : this.value
    },
    methods: {
        updateCart(qty) {
            this.addToCart(this.product)
            this.product.quantity = qty
            this.snackbar = true
        },
        removeFromCart(product){
            this.$store.getters.cart.splice(this.$store.getters.cart.findIndex(rec => rec._id == product._id),1)
            var cart=this.$store.getters.cart
            this.$store.dispatch('actionUpdateCart',cart)
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
