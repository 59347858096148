<template>
<div>
    <app-dialog :show="show" width='50%' @close="show=!show">
        <h2 class="align-center">Products in Bundle</h2>
        <v-card flat>
            <v-row>
                <v-col>
                    <v-list three-line class="ma-2" sm12 xs12>
                        <v-list-item>
                            <v-list-item-avatar rounded="0">
                                <v-img :src="defaultProductImage" height="32px" width="32px"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ products[0]?.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-list>
                </v-col>
            </v-row>
        </v-card>
        <h2 class="align-center">Select Alternate Items</h2>
        <v-card flat>
            <v-row>
                <v-col v-for="(product, index) in products.slice(1)" sm="4" :key="product._id">
                    <v-list three-line class="ma-2" sm12 xs12>
                        <v-list-item :key="product._id" @click="$emit('update',index)">
                            <v-list-item-avatar rounded="0">
                                <v-img :src="defaultProductImage" height="32px" width="32px"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ product.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-list>
                </v-col>
            </v-row>
        </v-card>
    </app-dialog>
</div>
</template>

<script>
export default {
    props: {
        products: {
            type: Array,
            default: () => []
        },
        showDialog: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        show: {
            get() {
                return this.showDialog
            },
            set(newValue) {
                this.$emit('close')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
