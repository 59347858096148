import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import commonFunctions from '@/mixins/commonFunctions.js'
import billingFunction from '@/mixins/billingFunction.js'
import AppPageLayout from '@/components/AppPageLayout.vue'
import DynamicForm from '@/components/DynamicForm.vue'
import AppDialog from '@/components/AppDialog.vue'
import ImageCard from '@/components/ImageCard.vue'
import FileUpload from '@/components/FileUpload.vue'
import WhatsappShare from '@/components/WhatsappShare.vue'
import '@/styles/style.css'
import './registerServiceWorker'
import DiscountCard from '@/components/DiscountCard.vue'
import RowItem from '@/components/RowItem.vue'
import VideoCard from '@/components/VideoCard.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import OrderAction from '@/components/OrderAction.vue'
import InputDate from '@/components/Date.vue'
import OrderStatus from '@/components/OrderStatus.vue'
import Qrcode, { data } from 'qrcode.vue'
import BackButton from '@/components/BackButton.vue'
import DeliveryType from '@/components/DeliveryType.vue'
import DeliveryAddress from '@/components/DeliveryAddress.vue'
import PageHeader from '@/components/PageHeader.vue'
import OrderCancel from '@/components/OrderCancel.vue'
import AutoComplete from '@/components/AutoComplete.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import ResetUserPassword from '@/components/ResetUserPassword.vue'
import CustomerOrderCard from '@/components/CustomerOrderResponsiveCard.vue'
import StatusTimeline from '@/components/StatusTimeline.vue'
import ActionButton from '@/components/ActionButton'
import CategoryCard from '@/components/CategoryCard'
import GoogleAuth from 'vue-google-oauth'
import AlertMessage from '@/components/AlertMessage'
import FontPicker from 'font-picker-vue';
import wysiwyg from "vue-wysiwyg";
import Pagination from "@/components/Pagination"
import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'
import Notifications from 'vue-notification'
import CartProductDetails from '@/components/CartProductDetails.vue';
import fcmPlugin from './plugins/fcm.plugin';
import { firebaseConfig } from './config/firebase.config';

Vue.mixin(commonFunctions)
Vue.mixin(billingFunction)

Vue.component('app-page-layout', AppPageLayout)
Vue.component('dynamic-form', DynamicForm)
Vue.component('app-dialog', AppDialog)
Vue.component('image-card', ImageCard)
Vue.component('file-upload', FileUpload)
Vue.component('whatsapp-share', WhatsappShare)
Vue.component('discount-card', DiscountCard)
Vue.component('row-item', RowItem)
Vue.component('video-card', VideoCard)
Vue.component('video-player', VideoPlayer)
Vue.component('order-action', OrderAction)
Vue.component('input-date', InputDate)
Vue.component('order-status', OrderStatus)
Vue.component('qrcode', Qrcode)
Vue.component('back-button', BackButton)
Vue.component('delivery-type', DeliveryType)
Vue.component('delivery-address', DeliveryAddress)
Vue.component('page-header', PageHeader)
Vue.component('order-cancel', OrderCancel)
Vue.component('auto-complete', AutoComplete)
Vue.component('progress-bar', ProgressBar)
Vue.component('reset-user-password', ResetUserPassword)
Vue.component('customer-order-card', CustomerOrderCard)
Vue.component('status-timeline', StatusTimeline)
Vue.component('action-button', ActionButton)
Vue.component('category-card', CategoryCard)
Vue.component('alert-message', AlertMessage)
Vue.component('font-picker', FontPicker)
Vue.component('pagination', Pagination)
Vue.component('cart-product-details', CartProductDetails)
Vue.use(GoogleAuth, { client_id: process.env.VUE_APP_GOOGLE_API_CLIENT_ID, scope: 'profile email', prompt: 'select_account' })
Vue.googleAuth().load()
Vue.use(wysiwyg, { maxHeight: "500px", });
Vue.use(Notifications)
Vue.use(fcmPlugin, firebaseConfig)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
