<template>
    <div>
        <app-dialog :show="show" width='40%' @close="show=!show">
            <h2 class="align-center">Products in selected Item</h2>
            <v-card flat>
                <v-row>
                    <v-col v-for="product in getProducts()" sm="6" :key="product._id">
                        <v-list three-line class="ma-2" sm12 xs12 >
                                <v-list-item :key="product._id">
                                    <v-list-item-avatar rounded="0">
                                        <v-img :src="defaultProductImage" height="32px" width="32px"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ product.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card>
        </app-dialog>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            product: {
                type: Object,
                default: () => {}
            },
            showDialog: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            show: {
                get() {
                    return this.showDialog
                },
                set(newValue) {
                    this.$emit('close')
                }
            }
        },
        methods: {
            getProducts() {
                if(this.product.products?.length>0 && (this.product.bType==this.packageModule.bType.ONE_TIME||this.product.bType==this.packageModule.bType.FUTURE_BUY))
                    return this.product.products
                else if(this.product.bType=='CUSTOMIZABLE')
                    return [this.product.swapOptions[0]]
                else
                    return this.product.swapOptions
            }
        },
    }
    </script>
    
    <style lang="scss" scoped>
    
    </style>
    