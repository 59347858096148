<template>
<div ref="orderDetails">
    <h2 v-if="show" data-html2canvas-ignore="true" class="text-center">Invoice Preview</h2>
    <v-layout  v-if="show" wrap>
        <v-flex xs8 sm8>
            <h2 class="text-color">INVOICE</h2>
        </v-flex>
        <v-flex class="top-padding" xs4 sm4>
            <img v-if="$store.getters.vendor.logo" class="logo" width="10%" :src="host+$store.getters.vendor.logo.path">
            <v-icon v-else x-large class="mr-2" :style="themeInverted">mdi-handshake</v-icon>
            <label :style="themeInverted" class="title">{{storeName}}</label><br>
            <label class="font-theme">{{$store.getters.vendor.mobileNo}}</label><br>
            <label class="font-theme" v-if="$store.getters.vendor.email">{{$store.getters.vendor.email}}</label><br>
            <label class="font-theme" v-if="$store.getters.vendor.address">{{$store.getters.vendor.address}}</label><br>
        </v-flex>
    </v-layout>
    <v-card v-if="show">
        <v-card-text>
            <v-layout wrap>
                <v-layout wrap>
                    <v-flex xs8 sm8>
                        <label>To :<span :style="themeInverted" class="title"> {{order.customer.displayName}} </span></label><br>
                        <label class="font-theme">{{order.customer.value}}</label><br>
                        <label class="font-theme" v-if="order.delivery.type">{{order.delivery.type.label}}</label><br>
                        <label class="font-theme" v-if="order.delivery.address">{{order.delivery.address}}</label><br>
                    </v-flex>
                    <v-flex xs4 sm4>
                        <label class="font-theme">Invoice No # : {{order.orderNo}}</label><br>
                        <label class="font-theme">Order date : {{order.date|dateTimeFormat}}</label><br>
                        <label class="font-theme">Delivery Date : {{order.delivery.date}}</label><br>
                        <label class="font-theme">Status : <b>{{order.currentStatus}}</b></label><br>
                    </v-flex>
                </v-layout>
                <v-flex xs12 sm12>
                    <br><br><label class="font-theme">Items</label><br>
                </v-flex>
                <v-flex xs12>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead :style="theme">
                                <th class="text-left">Code</th>
                                <th class="text-left">Product</th>
                                <th class="text-right">Quantity</th>
                                <th class="text-right">Unit Price(₹)</th>
                                <th class="text-right">Amount(₹)</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in order.items" :key="index">
                                    <td class="text-left font-theme">{{item.code}}</td>
                                    <td class="text-left font-theme">{{item.title}}</td>
                                    <td class="text-right font-theme">{{item.quantity}}</td>
                                    <td class="text-right font-theme">{{item.price}}</td>
                                    <td class="text-right font-theme">{{item.price*item.quantity|amountFormat}}</td>
                                </tr>
                                <tr class="border-top">
                                    <td colspan="4" class="text-right font-theme">Subtotal</td>
                                    <td class="text-right">{{order.subTotal|amountFormat}}</td>
                                </tr>
                                <tr v-if="order.discount.code">
                                    <td colspan="4" class="text-right font-theme">Coupon</td>
                                    <td class="text-right"><label>{{order.discount.code}}</label></td>
                                </tr>
                                <tr v-if="order.discount.amount>0">
                                    <td colspan="4" class="text-right font-theme">Discount</td>
                                    <td class="text-right">{{order.discount.amount|amountFormat}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-right font-theme">Tax</td>
                                    <td class="text-right">{{order.taxAmount|amountFormat}}</td>
                                </tr>
                                <tr v-if="order.delivery.charge>0">
                                    <td colspan="4" class="text-right font-theme">Delivery</td>
                                    <td class="text-right"><label>{{order.delivery.charge|amountFormat}}</label></td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-right"><b>
                                            <font class="total-font">Total</font>
                                        </b></td>
                                    <td class="text-right title">
                                        <font class="total-font">₹ {{total|amountFormat}}</font>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-flex>
                <v-flex class="mt-4">
                    <label>Thank you for your business</label>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import RowItem from '@/components/RowItem'
import html2pdf from 'html2pdf.js'
export default {
    components: {
        RowItem
    },
    props: {
        order: {
            type: Object
        },
        show: {
            type: Boolean
        }
    },
    computed: {
        total() {
            if (this.order.items && this.order.items.length > 0) {
                return this.calculateTotal(this.order);
            }
            return 0;
        }
    },
    methods: {
        async exportToPDF() {
            await html2pdf(this.$refs.orderDetails, {
                filename: `order-${this.order.orderNo}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 192 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            })
            this.$emit(this.appEvents.UPDATE)
        }
    }
}
</script>

<style scoped>
.border-top {
    border-top: 1px solid black;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.font-theme {
    font-size: 12px !important;
    font-family: Arial, Helvetica, sans-serif;
}

.title {
    font-size: 16px !important;
}

.total-font {
    font-size: 18px !important;
}

.text-color {
    color: #D3D3D3 !important;
}

.top-padding {
    padding-top: 3%;
}

.logo {
  vertical-align: middle
}
</style>
