<template>
<div class="display-block">
    <h3>Order Details :</h3>
    <v-flex text-right>
        <v-btn v-if="order.currentStatus=='DELIVERED'" class="ma-4" :style="theme" :loading="show" :disabled="show" @click="generatePdf()">Download Invoice</v-btn>
    </v-flex>
    <v-flex>
        <export-pdf @update="show=false" :show="show" :order="order" ref="exportInvoice"></export-pdf>
    </v-flex>
    <v-card v-if="!show">
        <v-card-text>
            <v-layout wrap>
                <v-layout wrap>
                    <v-flex xs12 sm4>
                        <row-item label="Customer" :value="order.customer.displayName"></row-item>
                        <row-item v-if="order.delivery.type" label="Delivery Type" :value="order.delivery.type.label"></row-item>
                        <row-item v-if="order.delivery.date" label="Delivery Date" :value="order.delivery.date"></row-item>
                    </v-flex>
                    <v-flex xs12 sm4>
                        <row-item label="Mobile Number" :value="`<a href=tel:+91${order.customer.value}>${order.customer.value}</a>`"></row-item>
                        <row-item v-if="order.delivery.address" label="Delivery Address" :value="order.delivery.address"></row-item>
                        <row-item v-if="order.delivery.pickupPoint" label="PickUp Address" :value="order.delivery.pickupPoint"></row-item>
                    </v-flex>
                    <v-flex xs12 sm4 class="text-right">
                        <row-item label="Date" :value="order.date|dateTimeFormat"></row-item>
                        <row-item label="Order No" :value="order.orderNo"></row-item>
                        <row-item label="Status" :value="`<b>${order.currentStatus}</b>`"></row-item>
                    </v-flex>
                </v-layout>
                <v-flex text-right xs12 sm12>
                    <v-btn data-html2canvas-ignore="true" fab small @click="readonly=!readonly" v-if="readonly&&order.currentStatus=='PENDING'" :style="theme">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-flex>
                <v-layout wrap v-if="!readonly">
                    <v-flex xs12 sm8 px-4>
                        <auto-complete v-model="product" :items="productList" title="title" subtitle="code" label="Product"></auto-complete>
                    </v-flex>
                    <v-flex xs9 sm3 px-4 text-right>
                        <v-text-field type="number" v-model.number="quantity" label="Quantity"></v-text-field>
                    </v-flex>
                    <v-flex xs1 sm1 text-right>
                        <v-btn data-html2canvas-ignore="true" :style="themeInverted" @click="addItemInOrder()" outlined>Add</v-btn>
                    </v-flex>
                </v-layout>
                <row-item label="Items"></row-item>
                <v-flex xs12 v-if="$vuetify.breakpoint.smAndUp">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Code</th>
                                    <th class="text-left">Product</th>
                                    <th class="text-right">Quantity</th>
                                    <th class="text-right">Unit Price</th>
                                    <th class="text-right">Amount</th>
                                    <th class="text-right" v-if="!readonly">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in order.items" :key="index">
                                    <td class="text-left">{{item.code}}</td>
                                    <td class="text-left">{{item.title}} <v-icon v-if="item.bType" @click="openDetail=true">mdi-information-slab-circle-outline</v-icon>
                                        <cart-product-details :showDialog="openDetail" @close="openDetail=false" :product="item"/></td>
                                    <td class="text-right">{{item.quantity}}</td>
                                    <td class="text-right">{{item.price}}</td>
                                    <td class="text-right">{{item.price*item.quantity|amountFormat}}</td>
                                    <td class="text-right" v-if="!readonly">
                                        <v-icon small @click="deleteItemFromOrder(item)">mdi-close</v-icon>
                                    </td>
                                </tr>
                                <tr class="border-top">
                                    <td :colspan="readonly?4:5" class="text-right"><b>Subtotal</b></td>
                                    <td class="text-right">{{order.subTotal|amountFormat}}</td>
                                </tr>
                                <tr v-if="order.redeemedReward" class="border-top">
                                    <td :colspan="readonly?4:5" class="text-right"><b>Reward</b></td>
                                    <td class="text-right">{{order.redeemedReward|amountFormat}}</td>
                                </tr>
                                <tr v-if="order.discount.code">
                                    <td :colspan="readonly?4:5" class="text-right"><b>Coupon</b></td>
                                    <td class="text-right"><label>{{order.discount.code}}</label></td>
                                </tr>
                                <tr v-if="order.discount.amount>0">
                                    <td :colspan="readonly?4:5" class="text-right"><b>Discount</b></td>
                                    <td class="text-right">{{order.discount.amount|amountFormat}}</td>
                                </tr>
                                <tr v-if="order.discount.error">
                                    <td :colspan="readonly?4:5" class="text-right"><b>Coupon Error</b></td>
                                    <td class="text-right" style="color:red">{{order.discount.error}}</td>
                                </tr>
                                <tr>
                                    <td :colspan="readonly?4:5" class="text-right"><b>Tax</b></td>
                                    <td class="text-right">{{order.taxAmount|amountFormat}}</td>
                                </tr>
                                <tr v-if="order.delivery.charge>0">
                                    <td :colspan="readonly?4:5" class="text-right"><b>Delivery</b></td>
                                    <td class="text-right"><label>{{order.delivery.charge|amountFormat}}</label></td>
                                </tr>
                                <tr>
                                    <td :colspan="readonly?4:5" class="text-right"><b>Total</b></td>
                                    <td class="text-right headline">{{total|amountFormat}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-flex>
                <v-layout v-if="$vuetify.breakpoint.xsOnly" column>
                    <v-flex v-for="item in order.items" :key="item.code">
                        <view-order-card :edit="!readonly?true:false" :item="item" @deleteItem="deleteItemFromOrder(item)"></view-order-card>
                    </v-flex>
                    <v-flex text-right>
                        <row-item v-if="order.subTotal" label="Subtotal" :value="'Rs '+order.subTotal"></row-item>
                        <row-item v-if="order.redeemedReward" label="Reward" :value="'Rs '+order.redeemedReward"></row-item>
                        <row-item v-if="order.discount.code" label="Coupon" :value="order.discount.code"></row-item>
                        <row-item v-if="order.discount.amount>0" label="Discount" :value="'Rs '+order.discount.amount"></row-item>
                        <row-item v-if="order.taxAmount>0" label="Tax" :value="'Rs '+order.taxAmount"></row-item>
                        <row-item v-if="order.delivery.charge>0" label="Delivery" :value="'Rs '+order.delivery.charge"></row-item>
                        <row-item label="Total" :value="'Rs '+total"></row-item>
                    </v-flex>
                </v-layout>
            </v-layout>
            <v-layout>
                <v-flex xs12 sm9></v-flex>
                <v-flex xs12 sm3 v-if="order.statusLog.length>0">
                    <status-timeline data-html2canvas-ignore="true" :orderStatusLog="order.statusLog" :currentStatus="order.currentStatus"></status-timeline>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-flex text-right>
            <v-btn v-if="order.currentStatus=='DELIVERED'" class="mx-2" :style="theme" @click="reOrder">Re-Order</v-btn>
            <v-btn v-if="!readonly" class="mx-1" :style="theme" @click="updateOrder()">Update Order</v-btn>
            <v-btn v-if="!readonly" class="ma-2" :style="theme" @click="readonly=!readonly">Cancel</v-btn>
            <order-cancel :id="$route.params.id" @updatedStatus='updateStatus($event)'></order-cancel>
            <v-btn data-html2canvas-ignore="true" v-if="readonly" class="ma-4" :style="theme" @click="$router.go(-1)">
                Back
            </v-btn>
        </v-flex>
    </v-card>
</div>
</template>

<script>
import ViewOrderCard from '@/components/ViewOrderCard'
import RowItem from '@/components/RowItem'
import appConstants from '@/utils/appConstants'
import ExportPdf from '@/components/ExportToPdf'
export default {
    components: {
        RowItem,
        ExportPdf,
        ViewOrderCard
    },
    data() {
        return {
            order: {
                id: "",
                date: "",
                customer: {
                    name: ""
                },
                items: [],
                discount: {
                    amount: 0,
                    percent: 0,
                    type: ''
                },
                tax: 0,
                taxAmount: 0,
                total: 300,
                orderNo: '',
                statusLog: [],
                delivery: {
                    date: '',
                    type: ''
                },
                editedFrom: ''
            },
            headers: [{
                    text: 'Product',
                    value: 'name',
                },
                {
                    text: 'Quantity',
                    value: 'quantity',
                    align: 'right'
                },
                {
                    text: 'Unit Price',
                    value: 'price',
                    align: 'right'
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    align: 'right'
                }
            ],
            id: 0,
            readonly: true,
            product: {},
            quantity: 1,
            productList: [],
            loading: false,
            show: false,
            openDetail:false
        }
    },
    computed: {
        total() {
            if (this.order.items && this.order.items.length > 0) {
                return this.calculateTotal(this.order);
            }
            return 0;
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.order = await this.getItem(appConstants.ORDERS_API + "/" + this.id)
            var products = await this.getItem(appConstants.WEBSTORE_PRODUCTS)
            this.productList=products.data
        },
        async updateOrder() {
            try {
                this.order.editedFrom = 'WEBSTORE'
                this.order.discount.error = ''
                this.order = await this.putItem(appConstants.ORDERS_API + "/" + this.order._id, this.order);
                this.readonly = !this.readonly
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data.errors;
            }
        },
        addItemInOrder() {
            let index = this.order.items.findIndex(rec => rec._id == this.product._id);
            if (index > -1) {
                let prod = this.order.items[index];
                prod.quantity += this.quantity;
                this.order.items.splice(index, 1, prod);
            } else {
                this.order.items.push(
                    this.convertProductToOrderItem(this.product, this.quantity)
                );
            }
            this.quantity = 1;
            this.product = undefined;
        },
        deleteItemFromOrder(item) {
            const index = this.order.items.indexOf(item);
            this.order.items.splice(index, 1);
            this.order.subTotal = 0;
        },
        generatePdf() {
            this.show = true
            this.$refs.exportInvoice.exportToPDF()
        },
        updateStatus(updatedStatus) {
            this.order.currentStatus = updatedStatus
        },
        reOrder(){
            this.order.items.forEach(rec=>{
                rec.quantity=parseInt(rec.quantity)
                rec['images']=[]
                rec['images'].push(rec.image)
                this.addToCart(rec)
            })
            this.$router.push({ name: "Cart" })
        }
    }
}
</script>

<style>
.border-top {
    border-top: 1px solid black;
}

.v-list-item__content {
    min-height: 50px !important;
    padding: 0 0 !important;
}
</style>
