<template>
<div>
    <app-dialog :show="show" width='50%' @close="show=!show">
        <h2 class="align-center">Select Delivery</h2>
        <v-card flat>
            <v-list three-line class="ma-2" sm12 xs12 v-if="productSubscription.length > 0">
                <template v-for="(product, index) in productSubscription">
                    <v-list-item :key="product._id" @click="$emit('update',index)">
                        <v-list-item-content>
                            <v-list-item-title style="white-space: normal">{{ product.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                <delivery-address @update="product.deliveryAddress=$event"></delivery-address>
                            </v-list-item-action-text>
                            <input-date label="Delivery Date" v-model="product.deliveryDate"></input-date>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                </template>
            </v-list>
            <v-col class="text-right">
                <v-btn :style="themeInverted" outlined class="mx-2" @click="$emit('close')">Cancel</v-btn>
                <v-btn :style="theme" @click="saveSubscription()">Checkout</v-btn>
            </v-col>
        </v-card>
    </app-dialog>
</div>
</template>

<script>
import CartSwapPopUp from '@/components/CartSwapPopUp.vue'
import appConstants from '@/utils/appConstants'
export default {
    components: {
        CartSwapPopUp,
    },
    props: {
        order: {
            type: Object,
            default: () => {}
        },
        showDialog: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        show: {
            get() {
                return this.showDialog
            },
            set(newValue) {
                this.$emit('close')
            }
        },
    },
    data() {
        return {
            productSubscription: [],
            selectedProducts:[]
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        initComponent() {
            this.productSubscription=[]
            let subscriptionPackage = this.order.items.filter(rec => rec.bType == this.packageModule.bType.FUTURE_BUY)
            subscriptionPackage.forEach(rec => {
                rec.products.forEach(item => {
                    this.productSubscription.push(item)
                })
            })
        },
        async saveSubscription() {
            await this.postItem(appConstants.ORDERS_API, this.order)
            alert("Congratulations!! Your Subscription has been created")
            this.$store.dispatch("actionUpdateCart", [])
            this.$router.push("/store")
        }
    },

}
</script>

<style lang="scss" scoped>

</style>
