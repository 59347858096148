<template>
    <v-btn v-if="$store.getters.vendor.whatsappNo" class="mr-8 btn-margin whatsapp-btn" :href="urlEncodedMsg"
        target="_blank" fixed bottom right>
        <v-icon>mdi-whatsapp</v-icon>Chat with Us
    </v-btn>
</template>

<script>
export default {
    props: ['whatasppMsg'],
    computed: {
        urlEncodedMsg() {
            return encodeURI("https://api.whatsapp.com/send?phone=91" + this.$store.getters.vendor.whatsappNo)
        }
    },
}
</script>

<style scoped>
.btn-margin {
    margin-bottom: 100px !important;
}
</style>
