<template>
<v-flex style="padding:2%">
    <v-card class="mx-auto my-12" max-width="374">
        <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
        </template>
        <v-img height="250" src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>
        <v-card-title>{{vendor.businessName}}</v-card-title>
        <v-card-text>
            <v-row align="center" class="mx-0">
                <v-rating :value="4.5" color="amber" dense half-increments readonly size="14"></v-rating>

                <div class="grey--text ml-4">
                    4.5 (413)
                </div>
            </v-row>

            <div class="my-4 subtitle-1">
                $ • Italian, Cafe
            </div>

            <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
            <v-flex text-right>
                <v-btn color="deep-purple lighten-2" title="Go to Store" text @click="navigateToWebstore(vendor)">
                    <v-icon>mdi-storefront</v-icon>
                </v-btn>
            </v-flex>
        </v-card-actions>
    </v-card>
</v-flex>
</template>

<script>
export default {
  props: ["item"],
  computed: {
    vendor() {
      if (this.item == undefined) {
        return this.defaultVendor;
      }
      return this.item;
    }
  },
  data() {
    return {
      defaultVendor: { id: "a", businessName: "Local Vendor" }
    };
  },
  methods: {
    navigateToWebstore(vendor) {
      this.$store.dispatch("actionUpdateVendor", vendor);
      this.$router.push(vendor.slug+"/store/");
    }
  }
};
</script>

<style scoped>
</style>
