<template>
<v-container>
    <v-card class="pa-2" flat>
        <v-flex text-center>
            <alert-message v-if="msg" :error="msg"></alert-message>
        </v-flex>
        <v-flex text-center>
            <h2>Reset Password</h2>
        </v-flex>
        <v-form ref="form" lazy-validation>
            <v-card-text class="col-md-6 offset-md-3">
                <div v-if="!validUser">
                    <v-text-field prepend-icon="mdi-account" v-model="username" label="Mobile Number" />
                    <v-btn class="mt-4" :style="theme" block @click="generateOTP()">Generate OTP</v-btn>
                </div>
                <div v-if="validUser">
                    <h3 v-if="!validOTP">The OTP has been sent to on {{maskedMail}}</h3>
                    <v-text-field v-if="!validOTP" type="password" prepend-icon="mdi-lock" v-model="otp" :rules="otpRule" label="Enter OTP(one time password)" required></v-text-field>
                    <v-btn v-if="otp && !validOTP" :style="theme" block @click="verifyOTP()">Submit</v-btn>
                </div>
                <div v-if="validOTP">
                    <v-text-field type="password" prepend-icon="mdi-lock" v-model="newPassword" :rules="newPasswordRule" label="Enter New password" required></v-text-field>
                    <v-text-field type="password" prepend-icon="mdi-lock" v-model="confirmPassword" :rules="passwordConfirmationRule" :error="passwordError" :error-messages="passwordErrorMsg" label="Confirm password"></v-text-field>
                    <v-btn :style="theme" block @click="submitPassword()">Save</v-btn>
                    <v-btn class="mt-4" :style="theme" block @click="$router.go(-1)">Cancel</v-btn>
                </div>
            </v-card-text>
        </v-form>
        <v-flex text-center>
            <alert-message v-if="msg" :error="msg"></alert-message>
        </v-flex>
    </v-card>
</v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
export default {
    data() {
        return {
            username: "",
            otp: "",
            validOTP: false,
            validUser: false,
            newPassword: "",
            confirmPassword: "",
            passwordError: null,
            passwordErrorMsg: "",
            otpRule: [v => !!v || "OTP required"],
            item: {},
            id: 0
        };
    },
    methods: {
        async generateOTP() {
            try {
                this.msg = "";
                var userDetails = {
                    username: this.username,
                    vendorId: this.$store.getters.vendor._id
                };
                var res = await this.putItem(appConstants.GENERATE_OTP, userDetails);
                this.maskedMail = `${res.mobileNo ? res.mobileNo : ""}${
          res.email ? "& " + res.email : ""
        }`;
                //this.maskMail(email)
                this.validUser = true;
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data
            }
        },
        async verifyOTP() {
            try {
                var userOTP = {
                    username: this.username,
                    otp: this.otp,
                    vendorId: this.$store.getters.vendor._id
                };
                await this.putItem(appConstants.VERIFY_OTP, userOTP);
                this.validOTP = true;
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data
            }
        },
        async submitPassword() {
            if (
                this.newPassword == this.confirmPassword &&
                this.$refs.form.validate()
            ) {
                this.msg = "";
                try {
                    var user = {
                        validOTP: this.otp,
                        username: this.username,
                        newPassword: this.newPassword,
                        vendorId: this.$store.getters.vendor._id
                    };
                    await this.putItem(appConstants.RESET_PASSWORD, user);
                    alert("Password updated successfully");
                    this.$router.go(-1);
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data
                }
            }
        },
        maskMail(email) {
            let str = email;
            str = str.split("");
            let finalArr = [];
            let len = str.indexOf("@");
            str.forEach((item, pos) => {
                pos >= 3 && pos <= len - 4 ?
                    finalArr.push("*") :
                    finalArr.push(str[pos]);
            });
            this.maskedMail = finalArr.join("");
        }
    }
};
</script>

<style scoped>
.format {
    padding-top: 5%;
    padding-left: 22%;
}
</style>
