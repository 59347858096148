<template>
<v-layout class="cart-bar" v-if="showCart" :style="theme">
    <v-flex>
        <h3>Cart Items: {{$store.getters.cart.length}}</h3>
    </v-flex>
    <v-flex text-right style="padding:1% 1%">
        <v-btn small outlined to="/store/cart">
            <v-icon class="mr-1">mdi-cart-outline</v-icon> Proceed to cart
        </v-btn>
    </v-flex>
</v-layout>
</template>

<script>
export default {
    computed: {
        showCart() {
            return this.$store.getters.cart && this.$store.getters.cart.length > 0;
        }
    }
};
</script>

<style scoped>
.cart-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0.2rem;
    z-index:1000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
