<template>
<vue-horizontal>
    <slot/>
</vue-horizontal>
</template>

<script>
import VueHorizontal from "vue-horizontal";

export default {
    components: {
        VueHorizontal,
    }
};
</script>

<style scoped>
</style>
