<template>
<v-card class="d-flex flex-column" v-if="product" height="98%">
    <v-flex @click="showProductDetail(product._id)" class="pa-2 link">
        <video-player  v-if="isContentPresent && product.images[0]?.mimetype.startsWith('video/')" :src="product.images[0]?.path" height="200"></video-player>
        <v-img v-if="isContentPresent && product.images[0]?.mimetype.startsWith('image/')" class="white--text align-end" height="200px" :src="imagePath(product.images[0]?.path)"></v-img>
        <v-img v-if="!isContentPresent" class="white--text align-end" height="200px" :src="defaultProductImage"></v-img>
    </v-flex>
    <label v-if="product.brand" class="subtitle">{{product.brand.displayName}}</label>
    <label class="title-font link" @click="showProductDetail(product._id)">{{product.title}}</label>
    <v-spacer></v-spacer>
    <v-card-text class="text--primary">
        <div>Price:
            <span v-if="product.offerprice>0" class="price-amount" :style="themeInverted">Rs.{{product.offerprice}}/-</span>
            <span :style="themeInverted" :class="product.offerprice==null||product.offerprice == 0 ? 'price-amount' : 'strike-through'">Rs.{{product.price}}/-</span>
        </div>
        <!-- <v-rating :value="0" color="pink" dense half-increments readonly size="14"></v-rating> -->
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
        <v-layout class="pb-2" column v-if="product.stock>0||$store.getters.vendor.negativeStock||(product.bType=='ONE_TIME'||product.bType=='FUTURE_BUY'||product.type=='BUNDLE')">
            <add-to-cart  v-model="product"></add-to-cart>
            <!-- <order-button v-model="product"></order-button> -->
            </v-layout>
        <v-layout class="pb-2" column v-else>
            <v-flex text-center class="stockOut">
                Out Of Stock
            </v-flex>
        </v-layout>
    </v-card-actions>
</v-card>
</template>

<script>
import OrderButton from '@/components/OrderButton.vue'
import addToCart from '@/components/AddToCart.vue';
export default {
    components: {
        addToCart,
        OrderButton
    },
    props: {
        item: Object
    },
    computed: {
        product() {
            return this.item;
        },
        isContentPresent(){
            return this.product.images && this.product.images.length>0 && this.product.images[0]!=null
        }
    },
    methods: {
        showProductDetail(id) {
            this.$router.push("/store/products/" + id);
        }
    }
};
</script>

<style scoped>
.title-font {
    font-size: 1rem !important;
    font-weight: 500 !important;
    padding: 2px 16px !important;
    display: flex !important;
    word-wrap: break-all !important;
    line-height: 1.25rem !important;
}

.subtitle {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 0 16px !important;
    padding-top: 10px !important;
    padding-bottom: 2px;
    display: flex !important;
    word-wrap: breadk-all !important;
    line-height: 1.25rem !important;
    color: gray;
}

.link:hover {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .subtitle {
        font-size: 10px !important;
        font-weight: 400 !important;
        padding: 0 16px !important;
        padding-top: 10px !important;
        padding-bottom: 2px;
        display: flex !important;
        word-wrap: breadk-all !important;
        line-height: 1.25rem !important;
        color: gray;
    }
}

.strike-through {
    text-decoration: line-through;
}

.price-amount {
    font-size: 1rem;
}
</style>
