<template>
    <v-progress-circular v-if="show" :size="size?size:50" class="fetchingData" :color="color?color:'amber'" indeterminate></v-progress-circular>
</template>

<script>
    export default {
        props: ['size','color','show']
    }
</script>

<style lang="scss" scoped>
.fetchingData{
    margin-top: 5rem;
}
</style>