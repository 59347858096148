<template>
<v-container>
    <h1>Dashboard</h1>
    <v-layout row wrap>
        <v-flex xs12>
            <expiring-vendor></expiring-vendor>
        </v-flex>
         <v-flex style="margin:1%" xs12 v-if="isAllowed('viewSalesReport')">
             <v-card class="elevation-8"><v-card-text>
            <chart-widget></chart-widget>
            </v-card-text></v-card>
        </v-flex>
        <v-flex xs6 sm4 md3 v-for="i in dashboardItems" :key="i.title">
            <dashboard-widget class="elevation-8" :value="i.value" :title="i.title" :icon="i.icon" :url="i.path"></dashboard-widget>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import DashboardWidget from '@/components/DashboardWidget'
import appConstants from '@/utils/appConstants'
import ChartWidget from '@/components/ChartWidget'
import ExpiringVendor from '@/components/ExpiringVendor'
export default {
    components: {
        DashboardWidget,
        ChartWidget,
        ExpiringVendor
    },
    data() {
        return {
            dashboardItems: [{
                    value: 3,
                    title: "Brands",
                    icon: "mdi-braille",
                    path: "/app/brands"
                },
                {
                    value: 6,
                    title: "Categories",
                    icon: "mdi-group",
                    path: "/app/categories"
                },
                {
                    value: 10,
                    title: "Products",
                    icon: "mdi-cube",
                    path: "/app/products"
                },
                {
                    value: 25,
                    title: "Customers",
                    icon: "mdi-account-multiple",
                    path: "/app/customers"
                },
                {
                    value: 4,
                    title: "Staff",
                    icon: "mdi-account",
                    path: "/app/staffs"
                },
                {
                    value: 12,
                    title: "Orders",
                    icon: "mdi-cart",
                    path: "/app/orders"
                }
            ],
            brand: 0
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            await this.getItem(appConstants.BRANDS_API).then(data => {
                this.dashboardItems[0].value = data.meta.totalRecords
            })
            await this.getItem(appConstants.CATEGORIES_API).then(data => {
                this.dashboardItems[1].value = data.meta.totalRecords
            })
            await this.getItem(appConstants.PRODUCTS_API).then(data => {
                this.dashboardItems[2].value = data.meta.totalRecords
            })
            await this.getItem(appConstants.CUSTOMERS_API+'?userType=CUSTOMER').then(data => {
                this.dashboardItems[3].value = data.meta.totalRecords
            })
            await this.getItem(appConstants.STAFFS_API+'?userType=STAFF').then(data => {
                this.dashboardItems[4].value = data.meta.totalRecords
            })
            await this.getItem(appConstants.ORDERS_API).then(data => {
                this.dashboardItems[5].value = data.meta.totalRecords
            })
        }
    },
}
</script>

<style scoped>

</style>
