<template>
<v-container>
    <v-layout column>
        <label class="text-h6">Select Delivery Address</label>
        <v-flex class="ma-2" v-if="addresses.length>0">
            <label>Current Address</label>
            <v-radio-group @change="submitAddress()" v-model="selectedAddress">
                <v-radio v-for="address in addresses" :key="address" :label="address" :value="address"></v-radio>
            </v-radio-group>
        </v-flex>
        <label class="ma-2">Enter new Address</label>
        <v-layout class="ma-2" wrap>
            <v-textarea @input="submitAddress()" label="Enter Address" v-model="newAddress"></v-textarea>
        </v-layout>
    </v-layout>
</v-container>
</template>

<script>
import appConstants from '@/utils/appConstants'
import axios from 'axios'
export default {
    data() {
        return {
            addresses: [],
            selectedAddress: '',
            newAddress: '',
            saveUserAddress: false
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            if (this.getUserProfile()._id)
                await this.getItem(appConstants.WEBSTORE_CUSTOMER_ADDRESSES.replace('$id', this.getUserProfile()._id)).then(data=>{
                    this.addresses=data[0].addresses
                })
        },
        submitAddress() {
            if (!this.selectedAddress) {
                this.saveUserAddress = true
                this.$emit(this.appEvents.UPDATE, this.newAddress, this.saveUserAddress)
            } else
                this.$emit(this.appEvents.UPDATE, this.selectedAddress, this.saveUserAddress)
        }
    }
}
</script>
