<template>
<v-layout :class="bottomMargin?'cart-bar bottom-margin':'cart-bar'" v-if="showCart" :style="theme">
    <v-flex mx-4>
        <h3>{{$store.getters.cart.length}} Items</h3>
    </v-flex>
    <v-flex text-right style="padding:1% 1%">
        <v-btn small text :style="theme" to="/store/cart">
             Proceed to cart<v-icon class="ml-1">mdi-arrow-right</v-icon>
        </v-btn>
    </v-flex>
</v-layout>
</template>

<script>
export default {
    props: {
        bottomMargin: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        showCart() {
            return this.$store.getters.cart && this.$store.getters.cart.length > 0;
        }
    }
};
</script>

<style scoped>
.cart-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0.2rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);;
}
.bottom-margin{
    margin-bottom: 70px;
}
</style>
