<template>
<v-layout>
    <v-col v-if="$store.getters.vendor.rewardSetting&&!rewardRedeemed">
        <v-flex v-if="!showBtn">
            <v-btn block @click="checkReward()" :style="theme">Check Rewards</v-btn>
        </v-flex>
        <v-flex text-center v-if="customer.reward>0">
            <h3>You can use {{customer.reward}} reward points, worth Rs. {{customer.reward*$store.getters.vendor.rewardSetting.singlePointCost}}</h3>
        </v-flex>
        <v-flex v-if="customer.reward>0&&showBtn">
            <v-btn block @click="redeemReward()" :style="theme">Use now</v-btn>
        </v-flex>
    </v-col>
    <v-row v-if="!redeemAble&&showBtn">
        <v-flex mr-12>
            <h3>Insufficient Redeem Points</h3>
        </v-flex>
    </v-row>
    <v-col v-if="rewardRedeemed">
        <v-row>
            <v-flex>
                Rewards
            </v-flex>
            <v-flex text-right>
                ₹{{customer.reward*$store.getters.vendor.rewardSetting.singlePointCost}}/-
            </v-flex>
        </v-row>
    </v-col>
</v-layout>
</template>

<script>
import appConstants from "@/utils/appConstants";
export default {
    props: {
        orderTotal: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            showBtn: false,
            redeemAble: false,
            rewardRedeemed: false,
            customer: {
                orderTotal: 0,
                totalRewardPoint: 0,
                reward: 0
            }
        }
    },
    methods: {
        /**
         * check rewards for current user and show redeemable points 
         */
        async checkReward() {
            await this.checkUserRewardBalance();
            if (this.customer.totalRewardPoint > 0) {
                this.customer.orderTotal = this.orderTotal
                this.customer.vendorId = this.$store.getters.vendor._id
                let reward = await this.postItem(appConstants.REWARD_API + "/checkReward", this.customer)
                this.redeemAble = true
                this.customer.reward = reward
                if (this.customer.totalRewardPoint < reward)
                    this.customer.reward = this.customer.totalRewardPoint
            }
            this.showBtn = true
        },
        /**
         * check user reward balance before rewards are redeemed
         */
        async checkUserRewardBalance() {
            let user = this.getUserProfile()
            let rewards = await this.getItem(appConstants.REWARD_API + "/user/" + user._id)
            if (rewards.length == 0 || rewards[0].totalRewardPoint == 0)
                this.redeemAble = false
            else{
                this.customer.totalRewardPoint = rewards[0].totalRewardPoint
                this.redeemAble=true
            }
        },
        /**
         * emit redeemable reward points
         */
        redeemReward() {
            this.rewardRedeemed = true
            this.$emit('redeemReward', this.customer.reward)
        }
    },
}
</script>
