<template>
<v-layout wrap>
    <v-flex sm6>
        <div align="center">
            <v-img src="https://www.delmonteeurope.com/media/europe/products/food-world/prepared%20canned%20vegetables/delmonte-cannellini-all-uccelletto.png" width="30%"></v-img>
        </div>
    </v-flex>
    <v-flex sm6>
        <v-card flat>
            <v-card-title class="title">Product Name</v-card-title>
            <v-card-text>
                <v-layout column>
                    <label>Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum
                        Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum
                        Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum Loream Ipsum
                    </label>
                    <br>
                    <v-layout>
                        <v-flex><b>Net Wt: </b>100gm</v-flex>
                        <v-flex><b>MRP: </b>Rs. 200/-</v-flex>
                    </v-layout>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-flex>
</v-layout>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
