<template>
<div>
    <v-card v-if="isAllowed('viewBillingAccountsReport')" class="elevation-8 widget">
        <v-card-text>
            <v-layout wrap>
                <v-flex xs12 sm6>
                    <h2>Expiring Vendor Accounts</h2>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-layout>
                        <v-flex mr-5 md3>
                            <v-select @change="expiringAccount()" :items="spanCount" label="Span" v-model="span" clearable></v-select>
                        </v-flex>
                        <v-flex md9>
                            <v-select @change="expiringAccount()" :items="statuses" label="Status" v-model="status" multiple></v-select>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-simple-table height="200px">
                <th class="fontSize">Vendor</th>
                <th class="text-right fontSize">Credit Balance</th>
                <th class="text-right fontSize">Expiry Date</th>
                <th class="text-right fontSize">Status</th>
                <tbody>
                    <tr v-if="expiringAccounts.length==0">
                        <td colspan="4">
                            <v-alert outlined color="grey lighten-1" icon="mdi-receipt" prominent>
                                <h2>No Vendor account expiring in {{span}} days</h2>
                            </v-alert>
                        </td>
                    </tr>
                    <tr v-for="(item,index) in expiringAccounts" :key="index">
                        <td class="fontSize">{{item.vendor.displayName}}</td>
                        <td class="text-right fontSize">{{item.creditBalance}}</td>
                        <td class="text-right fontSize">{{item.expiryDate|dateFormat}}</td>
                        <td :style="item.accountStatus=='EXPIRED'?'color: orange':'color:green'" class="text-right fontSize">{{item.accountStatus}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-flex>

            </v-flex>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    data() {
        return {
            expiringAccounts: [],
            expiredAccounts: [],
            title: "",
            span: 5,
            status: ["ACTIVE"],
            statuses: ["ACTIVE", "EXPIRED", "BLOCKED"],
            spanCount: [5, 10, 15]
        }
    },
    mounted() {
        this.expiringAccount();
        this.expiredAccount()
    },
    methods: {
        async expiringAccount() {
            let query = this.status.length > 0 ? `?status=${this.status.join(",")}` : ""
            query += this.span ? `&span=${this.span}` : ""
            this.expiringAccounts = (await this.getItem(appConstants.VENDOR_ACCOUNTS_REPORT + query + "&sort=expiryDate")).data
        },
        async expiredAccount() {
            this.expiredAccounts = (await this.getItem(appConstants.VENDOR_ACCOUNTS_REPORT + "?status=EXPIRED")).data
        }
    }
}
</script>

<style>
.widget {
    margin: 1%;
}

@media screen and (max-width: 600px) {
    .fontSize {
        font-size: 11px !important;
    }
}
</style>
