<template>
<div class="text-center">
    <v-container>
        <v-row justify="center">
            <v-col :cols="$vuetify.breakpoint.smAndUp?'10':'12'" sm="8">
                <v-container class="max-width">
                    <v-pagination v-model="pageNo" :length="pageCount"></v-pagination>
                </v-container>
            </v-col>
            <v-col v-if="changeRows" justify="right" :cols="$vuetify.breakpoint.smAndUp?'2':'6'" sm="2">
                <v-text-field v-model="size" @blur="init()" type="number" label="Rows per page"></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    props: {
        value: {
            type: Array,
            default: Array,
        },
        api: {
            type: String,
            default: String,
        },
        sort: {
            type:String,
            default:String
        },
        apiParams:{
            type:String,
            default:String
        },
        changeRows:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            page: 1,
            size: appConstants.PAGE_SIZE,
            pageCount: 0,
        };
    },
    mounted() {
        this.init(this.api);
    },
    methods: {
        async init(apiCall) {
            this.page = 1;
            let response=await this.getData(apiCall)
            this.data = response.data;
            this.pageCount = response.meta.totalPages;
        },
        async getData(apiCall){
            this.checkPageState(apiCall)
            if(apiCall.includes('?'))
                return await this.getItem(apiCall + `&pageNo=${this.page}&size=${this.size}&sort=${this.sort}&${this.apiParams}`);
            else
                return await this.getItem(apiCall + `?pageNo=${this.page}&size=${this.size}&sort=${this.sort}&${this.apiParams}`);
        },
        updatePageState(apiCall,newValue){
            let pageState=this.$store.getters.pageState
            let pageData={
                api:apiCall.split("?")[0],
                pageNo:newValue
            }
            if(!pageState)
                this.$store.dispatch("actionUpdatePageState", [pageData])
            else{
                let index=pageState.findIndex(rec => rec.api == apiCall.split("?")[0])
                if(index>-1){
                    pageState[index].pageNo=newValue
                }
                else
                    pageState.push(pageData)
                this.$store.dispatch("actionUpdatePageState", pageState)
            }
            this.page=newValue
        },
        checkPageState(apiCall){
            if(this.$store.getters.pageState){
                let index=this.$store.getters.pageState.findIndex(rec => rec.api == apiCall.split("?")[0])
                this.page=index>-1?this.$store.getters.pageState[index].pageNo:1
            }
            else
                this.page=1
        }
    },
    computed: {
        data: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
        pageNo: {
            get() {
                return this.page;
            },
            async set(newValue) {
                this.updatePageState(this.api,newValue)
                try {
                    let response = await this.getData(this.api);
                    this.data = response.data;
                    this.pageCount = response.meta.totalPages;
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response;
                }
            },
        }
    },
};
</script>
