<template>
<v-layout wrap>
    <v-container>
        <whatsapp-chat></whatsapp-chat>
        <v-card flat>
            <v-card-text>
                <v-flex>
                    <v-autocomplete label="Filter by Category" @change="getProducts()" clearable chips v-model="category" :items="categoryList" item-value="_id" item-text="title" return-object></v-autocomplete>
                </v-flex>
                <v-layout wrap v-if="category && category.title">
                    <v-flex xs12 text-right v-if="category">
                        <whatsapp-share :whatasppMsg="buildCatalogryMsg()"></whatsapp-share>
                    </v-flex>
                </v-layout>
                <br>
                <v-layout wrap>
                    <v-flex xs12 text-right v-if="!fetchingData">
                        Found {{products.length}} products
                    </v-flex>
                    <v-layout wrap>
                        <v-flex text-center v-if="fetchingData">
                            <progress-bar :show="fetchingData" size="70"></progress-bar>
                        </v-flex>
                        <v-flex :class="mobileView" sm4 md3 v-for="(item,index) in products" :key="index">
                            <product-card :item="item" class="ma-1"></product-card>
                        </v-flex>
                    </v-layout>
                </v-layout>
                <intersection-observer sentinal-name="products" @onIntersection="fetchData"></intersection-observer>
                <v-flex text-center>
                    <progress-bar :show="loading" size="30"></progress-bar>
                </v-flex>
            </v-card-text>
        </v-card>
    </v-container>
    <cart-bar></cart-bar>
    <v-dialog v-model="show">
        <v-card flat>
            <v-card-text>
                <v-flex text-right>
                    <v-icon @click="toggleDialog()" class="ma-5">mdi-close</v-icon>
                </v-flex>
                <product-details></product-details>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
import IntersectionObserver from '@/components/IntersectionObserver'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductCard.vue'
import ProductDetails from '@/components/ProductDetails.vue'
import FilterItem from '@/components/FilterItem.vue'
import CartBar from '@/components/CartBar.vue';
import WhatsappChat from '../../components/WhatsappChat.vue'
export default {
    components: {
        ProductCard,
        FilterItem,
        ProductDetails,
        CartBar,
        IntersectionObserver,
        WhatsappChat
    },
    data() {
        return {
            show: false,
            sortOptions: ["By Price", "By Relevance"],
            products: [],
            category: undefined,
            categoryList: [],
            vendorId: '',
            search: '',
            fetchingData: false,
            mobileView: '',
            pageInfo: {
                link: {},
                meta: {}
            },
            pageInfoForProducts: {
                link: {},
                meta: {}
            },
            loading: false,
            size: 20
        }
    },
    created() {
        if (performance.navigation.type == 1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        toggleDialog() {
            this.show = !this.show
        },
        getProducts() {
            this.products = []
            if (this.category != undefined) {
                this.getCategory(this.category._id, 1)
            } else {
                this.getAllProducts(1)
            }
        },
        async initComponent() {
            if (this.$store.getters.vendor.imageInRow > 1)
                this.mobileView = "xs6"
            else
                this.mobileView = "xs12"
            this.fetchingData = true
            this.vendorId = this.$store.getters.vendor._id
            this.categoryList = await this.getItem(appConstants.WEBSTORE_CATEGORIES)
            this.getCategory(this.$route.query.category, 1)
            this.fetchingData = false
        },
        async getCategory(id, pageNo) {
            this.category = await this.categoryList.find(rec => rec._id == id)
            var productsMeta = await this.getItem(appConstants.WEBSTORE_CATEGORYWISE_PRODUCTS + '/' + this.category._id + '?pageNo=' + pageNo)
            this.products = this.products.concat(productsMeta.data)
            this.pageInfo['link'] = productsMeta.links
            this.pageInfo['meta'] = productsMeta.meta
        },
        removeCategory() {
            this.$router.replace({
                'query': null
            });
            this.category = undefined
        },
        buildCatalogryMsg() {
            return "Hi,\nHave a look at our *" + this.category.title + "* products.\n" +
                window.location.href
        },
        async getAllProducts(pageNo) {
            var productsMeta = await this.getItem(appConstants.WEBSTORE_PRODUCTS + '?pageNo=' + pageNo + '&size=' + this.size)
            this.products = this.products.concat(productsMeta.data)
            this.pageInfoForProducts['link'] = productsMeta.links
            this.pageInfoForProducts['meta'] = productsMeta.meta
        },
        async fetchData() {
            console.log();
            this.loading = true
            if (this.pageInfoForProducts && this.pageInfoForProducts.link.next != undefined && this.category == undefined) {
                await this.getAllProducts(this.pageInfoForProducts.link.next)
            } else if (this.pageInfo && this.pageInfo.link.next != undefined && this.pageInfo.link.last != 0 && this.category) {
                await this.getCategory(this.category._id, this.pageInfo.link.next)
            }
            this.loading = false
        }
    },
}
</script>

<style scoped>
.no-space {
    margin-bottom: 0px !important;
}
</style>
