<template>
    <v-row align="center" style="padding:1%">
        <v-col cols="12" sm="6" class="text-center">
            <label class="text-h3 font-weight-bold">Sign Up</label><br><br>
            <img src="@/assets/signup-page-image.png" height="500"><br>
            <v-row :class="$vuetify.breakpoint.xsOnly ? 'mt-8 mx-6' : 'mt-12 pt-12 mx-6'">
                <v-col cols="12" sm="4"><a href="https://onlinedukanhub.in/terms_and_conditions">Terms &
                        Conditions</a></v-col>
                <v-col cols="12" sm="4"><a href="https://onlinedukanhub.in/privacy_policy">Privacy Policy</a></v-col>
                <v-col cols="12" sm="4"><a href="https://onlinedukanhub.in/refund_and_cancellation">Refund &
                        Cancellation Policy</a></v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="6">
            <v-row>
                <v-col cols="12" sm="10" offset-sm="1">
                    <v-stepper v-model="step" elevation="15">
                        <v-stepper-header v-show="false">
                            <v-stepper-step :complete="step > 1" step="1" editable color="primary-inverted">
                                Business Details
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step >= 2" step="2" editable color="primary-inverted">
                                Choose Plan
                            </v-stepper-step>
                            <v-stepper-step :complete="step >= 2" step="3" editable color="primary-inverted">
                                Completed
                            </v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <vendor-registration-form
                                    @success="vendorRegistrationSuccess($event)"></vendor-registration-form>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <vendor-plans :vendorDetails="vendor"
                                    @success="subscriptionSuccessful($event)"></vendor-plans>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <div class="text-center pa-10">
                                    <h2>Congratulations, Your subscription is successful.</h2>
                                    <h4>Your can click on the following link to navigate to your store.</h4>
                                    <a :href="url" target="_blank">{{ url }}</a><br>
                                    <label>Please check your {{ vendor?.email }} for login details.</label>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import AlertMessage from "@/components/AlertMessage.vue";
import ToolTip from '@/components/ToolTipComponent'
import VendorRegistrationForm from '@/components/VendorRegistrationForm.vue';

import VendorPlans from '../../components/VendorPlans.vue';
//import Razorpay from 'razorpay'
export default {
    components: {
        AlertMessage,
        ToolTip,
        VendorRegistrationForm,
        VendorPlans,
    },
    data() {
        return {
            alert: false,
            slug: "",
            result: "",
            step: "1",
            vendor: undefined
        }
    },
    computed: {
        url() {
            return `${location.protocol}//${location.host}${this.slug}/store`;
        }
    },
    methods: {
        vendorRegistrationSuccess(details) {
            this.vendor = details
            this.slug = `${this.vendor.slug}`
            this.step = "2"
        },
        subscriptionSuccessful(details) {
            this.step = "3"
        }
    },
};
</script>

<style lang="scss" scoped>
body {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.points {
    padding: 2% 15% !important;
    text-align: left;
}

.center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

li {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
}

ul {
    list-style: none;
}

ul li:before {
    content: '✓';
    font-size: 2rem;
    color: greenyellow;
    padding-right: 10px;
    ;
}
</style>
