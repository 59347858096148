import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart:JSON.parse(localStorage.getItem('cart'))||'',
    token: localStorage.getItem('userToken') || '',
    vendor: JSON.parse(localStorage.getItem('vendor')) || undefined,
    user: JSON.parse(localStorage.getItem('userProfile')) || undefined,
    orderCount:JSON.parse(localStorage.getItem('orderCount'))||'',
    notification:JSON.parse(localStorage.getItem('notification'))||undefined,
    pageState:JSON.parse(localStorage.getItem('pageState'))||undefined,
  },
  getters:{
    cart:state=>state.cart,
    vendor: state => state.vendor,
    user:state=>state.user,
    orderCount:state=>state.orderCount,
    notification:state=>state.notification,
    pageState:state=>state.pageState
  },
  mutations: {
    updateCart:(state,cart)=>{
      state.cart=cart
      if(cart == null){
        localStorage.removeItem("cart")
      }else{
        localStorage.setItem("cart",JSON.stringify(cart))
      }
    },
    updateVendor: (state, vendor) =>{
      state.vendor = vendor
      localStorage.setItem("vendor", JSON.stringify(vendor))
      localStorage.removeItem("userProfile");
      localStorage.removeItem("userToken");
      state.token = "";
      state.cart = []
      console.log("Vendor updated")
    },
    updateVendorSetting: (state, vendor) =>{
      state.vendor = vendor
      localStorage.setItem("vendor", JSON.stringify(vendor))
    },
    updateUser: (state, user) =>{
      state.user = user
      localStorage.setItem("userProfile", JSON.stringify(user))
    },
    updateOrderCount:(state,orderCount)=>{
      state.orderCount=orderCount
      if(orderCount == null){
        localStorage.removeItem("orderCount")
      }else{
        localStorage.setItem("orderCount",orderCount)
      }
    },
    updateNotification: (state, notification) =>{
      state.notification = notification
      localStorage.setItem("notification", JSON.stringify(notification))
    },
    updatePageState: (state, pageState) =>{
      state.pageState = pageState
      localStorage.setItem("pageState", JSON.stringify(pageState))
    }
  },
  actions: {
    actionUpdateCart:({commit},data)=>{
      commit('updateCart',data)
    },
    actionUpdateVendor: ({commit}, data)=>{
      commit('updateVendor', data)
    },
    actionUpdateVendorSetting: ({commit}, data)=>{
      commit('updateVendorSetting', data)
    },
    actionUpdateUser: ({commit}, data)=>{
      commit('updateUser', data)
    },
    actionUpdateOrderCount:({commit},data)=>{
      commit('updateOrderCount',data)
    },
    actionUpdateNotification:({commit},data)=>{
      commit('updateNotification',data)
    },
    actionUpdatePageState:({commit},data)=>{
      commit('updatePageState',data)
    },
  },
  modules: {
  }
})
