<template>
<v-card height="600" flat>
    <v-toolbar flat>
        <v-tabs v-model="model" centered :slider-color="$store.getters.vendor.themeUpdated?themeInverted.color:'orange'"><label></label>
            <v-tab href="#login" :style="themeInverted">
                Login
            </v-tab>
            <v-tab href="#signup" :style="themeInverted">
                Sign Up
            </v-tab>
        </v-tabs>
    </v-toolbar>
    <v-card-text>
        <v-tabs-items v-model="model">
            <v-tab-item value="login">
                <v-flex offset-sm-4 sm4>
                    <login-card userType='customer' />
                    <v-btn outlined block :style="theme" @click="model='signup'">Sign Up</v-btn>
                </v-flex>
            </v-tab-item>
            <v-tab-item value="signup">
                <v-flex offset-sm-3 sm6>
                    <registration-card/>
                </v-flex>
            </v-tab-item>
        </v-tabs-items>
    </v-card-text>
</v-card>
</template>

<script>
import LoginCard from '@/components/LoginCard.vue'
import RegistrationCard from '@/components/RegistrationCard.vue'
export default {
    components: {
        LoginCard,
        RegistrationCard
    },
    data() {
        return {
            model: 'login',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        }
    },
}
</script>

<style scoped>

</style>
