<template>
<v-card flat>
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
    <v-flex text-center>
        <h2>Reset Password</h2>
    </v-flex>
    <v-card-text>
        <v-form ref="form" lazy-validation>
            <label>{{item.email}}</label>
            <v-text-field type="password" v-model="oldPassword" :rules="oldPasswordRule" label="Enter old password" required></v-text-field>
            <v-text-field type="password" v-model="newPassword" :rules="newPasswordRule" label="Enter New password" required></v-text-field>
            <v-text-field type="password" v-model="confirmPassword" :rules="passwordConfirmationRule" :error="passwordError" :error-messages="passwordErrorMsg" label="Confirm password"></v-text-field>
            <v-btn :style="theme" block @click="submit()">Save</v-btn>
            <v-btn class="mt-4" :style="theme" block @click="$emit('update')">Cancel</v-btn>
        </v-form>
    </v-card-text>
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
</v-card>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import axios from "axios";
export default {
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            passwordError: null,
            passwordErrorMsg: "",
            item: {},
            id: 0,
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.item = await this.getItem(appConstants.CUSTOMERS_API + "/" + this.id)
        },
        submit() {
            if (this.newPassword == this.confirmPassword && this.$refs.form.validate()) {
                this.resetPassword()
            }
        },
        async resetPassword() {
            try {
                var user = { _id: this.item._id, mobileNo: this.item.mobileNo, oldPassword: this.oldPassword, newPassword: this.newPassword }
                await this.putItem(appConstants.CUSTOMERS_API + "/resetUserPassword", user)
                alert("Password updated successfully")
                this.$emit("update")
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data
            }
        },
    }
}
</script>

<style scoped>
.format {
    padding-top: 5%;
    padding-left: 22%
}
</style>
