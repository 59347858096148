<template>
<div class="home">
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
    <!-- <push-notification v-if="!$store.getters.notification||$store.getters.vendor.slug!=$store.getters.notification.slug||($store.getters.user&&$store.getters.user._id!=$store.getters.notification.userId)"></push-notification> -->
    <v-flex v-if="$store.getters.vendor.showBanner">
        <v-carousel :height="$vuetify.breakpoint.mdAndUp?500:'auto'" cycle hide-delimiter-background show-arrows-on-hover v-if="$store.getters.vendor.bannerImages && $store.getters.vendor.bannerImages.length>0">
            <v-carousel-item v-for="(image,i) in $store.getters.vendor.bannerImages" :key="i">
                <v-img :contain="$vuetify.breakpoint.mdAndUp" :height="$vuetify.breakpoint.mdAndUp?500:'auto'" :src="host+image.path"></v-img>
            </v-carousel-item>
        </v-carousel>
        <v-carousel :height="$vuetify.breakpoint.mdAndUp?500:'250'" cycle hide-delimiter-background show-arrows-on-hover v-else>
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-sheet :color="colors[i]" height="100%">
                    <v-row class="fill-height" align="center" justify="center">
                        <div class="display-3">{{ slide }} Slide</div>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </v-flex>
    <v-container>
        <v-flex text-center>
            <progress-bar :show="fetchingData" size="70"></progress-bar>
        </v-flex>
        <v-flex v-if="!fetchingData&&items.length==0" class="noContent" text-center>
            <h1>No Products found</h1>
        </v-flex>
    </v-container>
    <v-container>
        <h2 v-if="coupons.length>0" :style="themeInverted">Offers</h2>
        <slider :couponArray="coupons"></slider>
    </v-container>
    <v-container>
        <whatsapp-chat></whatsapp-chat>
    </v-container>
    <v-container>
        <v-flex xs12 text-right>
            <whatsapp-share :whatasppMsg="buildProductCatalogMsg()"></whatsapp-share>
        </v-flex>
        <h2 v-if="category.length>0" :style="themeInverted">Categories</h2>
        <v-layout wrap v-if="category">
            <v-flex :class="mobileView" sm4 md3 v-for="item in category" :key="item._id">
                <category-card :item="item"></category-card>
            </v-flex>
        </v-layout>
        <h2 v-if="items.data!=undefined" :style="themeInverted">Products</h2>
        <v-layout v-if="currentProducts&&currentProducts.length>0||searchText">
            <v-flex xs12 sm12>
                <v-text-field placeholder="Search Products by name" v-model="searchText" class="no-space" solo @click:clear="searchProduct(false)" clearable></v-text-field>
            </v-flex>
            <v-flex mx-2>
                <v-btn :style="theme" @click="searchProduct(true)"><v-icon>mdi-magnify</v-icon></v-btn>
            </v-flex>
        </v-layout>
        <v-layout wrap v-if="items">
            <v-flex v-if="!fetchingData&&currentProducts.length==0" class="noContent" text-center>
                <h1>No Product found</h1>
            </v-flex>
            <v-flex :class="mobileView" sm4 md3 v-for="(item,index) in currentProducts" :key="index">
                <product-card :item="item"></product-card>
            </v-flex>
        </v-layout>
        <intersection-observer sentinal-name="home" @onIntersection="fetchData"></intersection-observer>
        <v-flex text-center>
            <progress-bar :show="loading" size="30"></progress-bar>
        </v-flex>
    </v-container>
    {{latestData}}
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
    <cart-bar></cart-bar>
</div>
</template>

<script>
import IntersectionObserver from '@/components/IntersectionObserver'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductCard.vue'
import VendorCard from '@/components/VendorCard.vue'
import CartBar from '@/components/CartBar.vue';
import WhatsappChat from '@/components/WhatsappChat.vue'
import Slider from '@/components/Slider.vue'
import PushNotification from '@/components/PushNotification.vue'
export default {
    name: 'Home',
    components: {
        ProductCard,
        VendorCard,
        CartBar,
        IntersectionObserver,
        WhatsappChat,
        Slider,
        PushNotification
    },
    data() {
        return {
            loading: false,
            colors: [
                'indigo',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
            ],
            items: [],
            meta: {},
            category: [],
            vendor: [],
            slug: '',
            fetchingData: false,
            mobileView: '',
            searchText: '',
            currentProducts: [],
            pageInfo: { link: {}, meta: {} },
            refresh: false,
            search: false,
            coupons:[]
        }
    },
    created(){
        if(performance.navigation.type==1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        //this logic will activated if user switch between stores
        this.$store.subscribeAction((action, state) => {
            this.refresh = action.type == 'actionUpdateVendor'
        })
        if (!this.refresh)
            this.initComponent();
    },
    computed: {
        latestData() {
            if (this.refresh)
                this.$router.go(this.$router.currentRoute)
        }
    },
    methods: {
        async initComponent() {
            if (this.$store.getters.vendor.imageInRow > 1)
                this.mobileView = "xs6"
            else
                this.mobileView = "xs12"
            try {
                this.slug = this.$route.params.slug
                if (this.slug) {
                    await this.getDetails()
                }
            } catch (error) {
                this.handleError(error)
            }
        },
        async getDetails() {
            this.fetchingData = true
            try {
                this.coupons = await this.getItem(appConstants.WEBSTORE_COUPONS)
                this.category = await this.getItem(appConstants.WEBSTORE_CATEGORIES + "/parentCategories")
                await this.getProducts(1)
            } catch (error) {
                this.handleError(error)
            }
            this.fetchingData = false
        },
        showCategory() {
            this.show = !this.show
            this.getDetails()
        },
        async getProducts(pageNo) {
            let packages
            if (this.search)
                this.items = await this.getItem(appConstants.WEBSTORE_PRODUCTS + '?pageNo=' + pageNo + '&searchText=' + this.searchText)
            else{
                this.items = await this.getItem(appConstants.WEBSTORE_PRODUCTS + '?pageNo=' + pageNo)
                packages=(await this.getItem(appConstants.WEBSTORE_PACKAGES+'?pageNo='+pageNo)).data
                for(let i=0;i<packages.length;i++){
                    packages[i]=this.convertToProductRef(packages[i])
                }
            }
            this.currentProducts = this.currentProducts.concat(this.items.data)
            if(packages.length>0)
                this.currentProducts = this.currentProducts.concat(packages)
            this.pageInfo['link'] = this.items.links
            this.pageInfo['meta'] = this.items.meta
        },
        async fetchData(value) {
            this.loading = true
            if (value && this.pageInfo && this.pageInfo.link.next != undefined && this.pageInfo.link.last != 0) {
                await this.getProducts(this.pageInfo.link.next)
            }
            this.loading = false
        },
        searchProduct(search) {
            if(!search)
                this.searchText=''
            this.search = search
            this.currentProducts = []
            this.getProducts(1)
        }
    },
}
</script>

<style scoped>
.noContent {
    margin-top: 5rem;
}
</style>
