<template>
    <div>
        <v-row class="pa-1">
            <v-col cols="6" sm="2" md="4" v-for="plan in plans" :key="plan.id">
                <v-card elevation="15">
                    <v-card-text>
                        <label class="text-h6 primary-color" :title="plan.planId">{{ plan.title }}</label><br>
                        <label class="text-h6">Rs. {{ plan.amount }}/-</label>
                        <p> Billed every {{ plan?.interval }} {{ plan?.frequency.toLowerCase().replace('ly', '') }}</p>
                        <modules-list class="mt-4" :allowedModules="plan.allowedModules"></modules-list>
                        <div class="text-right">
                            <v-btn class="primary-inverted" :loading="collectingPayment" @click="subscribe(plan)"
                                v-if="vendorDetails?.accountStatus !== 'ACTIVE'">Subscribe</v-btn>
                            <UpgradeSubscription :disabled="vendorDetails?.licensingDetails?.planId == plan.planId"
                                :planDetails="plan" v-if="vendorDetails?.accountStatus == 'ACTIVE'">
                            </UpgradeSubscription>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import appConstants from '../utils/appConstants.js'
import ModulesList from './ModulesList.vue'
import RazorpayPaymentBtn from './RazorpayPaymentBtn.vue'
import UpgradeSubscription from './UpgradeSubscription.vue';
export default {
    components: { ModulesList, RazorpayPaymentBtn, UpgradeSubscription },
    props: {
        vendorDetails: {
            type: Object,
            //required: true,
        },
    },
    data() {
        return {
            plans: [],
            collectingPayment: false,
            selectedPlan: undefined,
            currentSubscription: undefined,
            loading: false
        }
    },
    async mounted() {
        this.plans = (await this.getItem(`${appConstants.WEBSTORE_PLANS}?conditions=active=true`))
    },
    methods: {
        async subscribe(plan) {
            try {
                this.collectingPayment = true
                let payload = {
                    planId: plan.planId,
                    trialDays: plan.trialDays,
                    customerNotify: 1,
                    quantity: 1,
                    totalCount: this.calculateIntervals(),
                    addons: [],
                    notes: {
                        vendorId: this.vendorDetails._id.toString(),
                        vendorName: this.vendorDetails.businessName
                    }
                }
                let subscriptionId = this.vendorDetails?.licensingDetails?.subscriptionId
                if (!subscriptionId) {
                    console.log("Creating new subscription");
                    subscriptionId = (await this.postItem(`${appConstants.BILLINGS_API}/subscriptions`, payload)).id;
                } else {
                    console.log("using existing subscription");
                }
                this.collectPayment(subscriptionId)
            } catch (error) {
                console.error(error)
            } finally {
                this.collectingPayment = false
            }
        },
        collectPayment(subscriptionId) {
            let options = {
                key: process.env.VUE_APP_RAZORPAY_KEY,
                subscription_id: subscriptionId,
                name: "Online Dukan",
                recurring: true,
                handler: (response) => {
                    this.handlePaymentSuccess(response)
                },
                prefill: {
                    name: this.vendorDetails.businessName,
                    email: this.vendorDetails.email,
                    contact: this.vendorDetails.mobileNo
                },
                notes: {
                    vendorId: this.vendorDetails._id.toString(),
                    vendorName: this.vendorDetails.businessName
                }
            };
            var razorPay = new window.Razorpay(options);
            razorPay.open();
            razorPay.on('payment.failed', async function (response) {
                alert(response.error.description);
            })
        },
        async handlePaymentSuccess(response) {
            this.refreshSubscription()
            this.$emit('success')
        },
        unixTimestampToDate(unixTimestamp) {
            return new Date(unixTimestamp * 1000);
        },
        dateToUnixTimestamp(date) {
            return Math.floor(date.getTime() / 1000);
        },
        calculateIntervals(interval = appConstants.BILLING_FREQUENCY.MONTHLY.toLowerCase()) {
            const intervalsPerYear = {
                daily: 365,
                weekly: 52,
                monthly: 12,
                yearly: 1
            };
            if (!intervalsPerYear[interval.toLowerCase()]) {
                throw new Error('Invalid interval. Please use daily, weekly, monthly, or yearly.');
            }
            return intervalsPerYear[interval.toLowerCase()] * 2;
        }
    },
}
</script>

<style lang="scss" scoped></style>