<template>
<v-card flat>
    <v-card-text>
        <!-- 
            @slot Use this slot when you have to define complet new form
        -->
        <slot name="form">
            <v-form ref="dynamicForm" lazy-validation v-model="valid">
                <v-layout wrap v-if="fields">
                    <template v-for="field in fields">
                        <!-- 
                            @slot Use this slot when you want to replace any form field with custom control
                        -->
                        <slot :name="field.attr" :item="field">
                            <v-flex v-if="field.type !=='textarea' && formData != null" xs12 v-show="field.attr !=='_id'">
                                <v-text-field class="pa-2" :type="field.type == 'number' ? 'number' : 'text'" :label="field.label" v-model="formData[field.attr]" v-if="isDefaultControl(field)" :disabled="isReadOnly(field)" :rules="isRequired(field) ? fieldRequired : []"></v-text-field>
                                <v-combobox class="pa-2" :placeholder="'Select '+field.label" :items="field.dataset" :label="field.title" v-if="field.type=='dropDown'" v-model="formData[field.attr]" :disabled="isReadOnly(field)" :rules="isRequired(field) ? fieldRequired : []" :item-text="field.dropDownLabel"></v-combobox>
                                <v-file-input label="Image" v-if="field.type=='file'" @input="formData[field.attr]" :disabled="isReadOnly(field)" :rules="isRequired(field) ? fieldRequired : []"></v-file-input>
                            </v-flex>
                            <v-flex v-if="field.type=='textarea' && formData != null" xs12>
                                <v-textarea class="pa-2" :label="field.label" v-model="formData[field.attr]" :readonly="isReadOnly(field)" :rules="isRequired(field) ? fieldRequired : []"></v-textarea>
                            </v-flex>
                        </slot>
                    </template>
                </v-layout>
                <!-- @slot Use this slot when you want to define new actions(buttons) for the dynamic form -->
                <slot name="actions">
                    <v-flex text-right>
                        <v-btn class="primary-inverted mr-1" @click="onSubmit()">Save</v-btn>
                        <v-btn class="primary-color" outlined @click="onClose()">Cancel</v-btn>
                    </v-flex>
                </slot>
            </v-form>
        </slot>
    </v-card-text>
</v-card>
</template>

<script>
/**
 * This component design form dyanamically based on the fields provided. `<dynamic-form></dynamic-form>`
 */
export default {
  props: {
    /**
     * Accepts the list of fields which needs be rendered in form
     * @values { attr: "attribute name", label: "Name to be displayed on form", value: "default value", type: "number", required: true}
     */
    fields: {
      type: Array,
      default: () => []
    },
    /**
     * Forms can be opened in 2 modes ADD & EDIT
     * <br>ADD mode all the fields accepts values
     * <br>EDIT mode fields defined `readonly` true are readonly
     * @values ADD/EDIT
     */
    mode: {
      type: String,
      default: () => this.formMode.ADD
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      valid: true,
      data: this.value
    };
  },
  computed: {
    formData: {
      get() {
        return this.data;
      },
      set(newValue) {
        this.data = newValue;
      }
    }
  },
  beforeUpdate() {
    this.valid = true;
    this.data = this.value
  },
  methods: {
    onSubmit() {
      if (this.$refs.dynamicForm.validate()) {
        this.$emit(this.appEvents.INPUT, this.formData);
        this.$emit(this.appEvents.SUBMIT);
      } else {
        this.valid = false;
      }
    },
    onClose() {
      this.$emit(this.appEvents.CLOSE);
    },
    isDefaultControl(field) {
      if (
        field.type == undefined ||
        field.type == "text" ||
        field.type == "number"
      )
        return true;
      else return false;
    },
    isReadOnly(field) {
      return field.readonly ? true : false;
    },
    isRequired(field) {
      return field.required ? true : false;
    }
  }
};
</script>

<style scoped>
</style>
